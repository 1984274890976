import { connect } from 'react-redux'
import { Menu } from 'antd'
import pick from 'lodash/pick'
import { compose, withHandlers, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import * as globalCompanyActions from 'App/states/reducers/globalCompany'

const openKeysState = withState('openKeys', 'setOpenKeys', [])

const handlers = withHandlers({
  handleOpenChange: props => openKeys => {
    const { setOpenKeys } = props
    setOpenKeys(openKeys)
  },
  handleClick:
    props =>
    ({ item }) => {
      const { setOpenKeys, changeGlobalCompany, selectedGlobalCompany } = props
      const { globalCompany } = item.props

      if (globalCompany && selectedGlobalCompany.uuid !== globalCompany.uuid) {
        const baseCompany = pick(globalCompany, ['uuid', 'name', 'slug', 'types'])

        changeGlobalCompany(baseCompany)

        window.location.reload()
      } else {
        setOpenKeys([])
      }
    }
})

const enhance = compose(
  connect(
    state => ({
      globalCompanies: state.globalCompany.globalCompanies,
      selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...globalCompanyActions
        },
        dispatch
      )
    })
  ),
  openKeysState,
  handlers
)

const SubMenu = Menu.SubMenu

const StyledMenu = styled(Menu)`
  .ant-menu-submenu-title {
    padding-left: 16px;
    font-weight: normal !important;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding-left: 16px !important;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    font-weight: normal;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    font-weight: normal;
  }
`

const getTitle = props => {
  const { selectedGlobalCompany } = props

  return (
    <span>
      <img
        alt="companylogo"
        width="30px"
        height="30px"
        style={{ marginRight: 8 }}
        src={selectedGlobalCompany.logo || 'https://shipx.cc/static/swiftlogo.png'}
      />
      <span>{selectedGlobalCompany && selectedGlobalCompany.name}</span>
    </span>
  )
}

const MainDrawerMenuCompany = props => {
  const { selectedGlobalCompany, globalCompanies, openKeys, handleOpenChange, handleClick } = props

  return (
    <StyledMenu
      mode="inline"
      inlineIndent={16}
      openKeys={openKeys}
      selectedKeys={[selectedGlobalCompany.uuid]}
      onClick={handleClick}
      onOpenChange={handleOpenChange}
    >
      <SubMenu key="company" title={getTitle(props)}>
        {globalCompanies?.map(gc => {
          return (
            <Menu.Item key={gc.uuid} globalCompany={gc}>
              <span>
                <img
                  alt="swiftlogo"
                  width="30px"
                  height="30px"
                  style={{ marginRight: 8 }}
                  src={gc.logo || 'https://shipx.cc/static/swiftlogo.png'}
                />
                <span>{gc.name}</span>
              </span>
            </Menu.Item>
          )
        })}
      </SubMenu>
    </StyledMenu>
  )
}

export default enhance(MainDrawerMenuCompany)
