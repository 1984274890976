import { Col, Row } from 'antd'
import styled from 'styled-components'

export const StyledHeader = styled(Row)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  background: white;
  line-height: normal;
  border: 1px solid rgb(230, 230, 230);
  font-size: 14.222px;
  color: #151b26;
  z-index: 99;

  .anticon-close,
  .anticon-ellipsis {
    font-size: 20px;
    vertical-align: -0.125em;
    color: rgb(150, 150, 150);
    padding-left: 10px;
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }

  a {
    text-decoration: none;
  }

  .fa,
  .far,
  .fas {
    font-size: 18px;
    color: rgb(170, 175, 180);
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      color: #1890ff;
    }
  }
`

export const DocumentDetails = styled(Col)`
  font-size: 15.222px;
  font-weight: 400;
  color: #1b2733;
`

export const DocumentName = styled.div`
  font-size: 15.222px;
  font-weight: 400;
  color: #1b2733;
  letter-spacing: 0.1px;
`

export const DocumentType = styled.div`
  font-size: 11.222px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgb(165, 167, 169);
  padding-top: 4px;
`

export const Actions = styled(Col)`
  text-align: right;
`

export const StyledMenu = styled.div`
  padding: 5px 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06) !important;
  box-sizing: border-box;
  border: 1px solid #d5dce0;

  .ant-menu-item-selected {
    background: none;
  }
`

export const StyledMenuItem = styled.a`
  font-size: 13.222px;
  color: #151b26;
  display: block;
  padding: 8px 0;

  &:hover {
    background: none;
  }
`

export const StyledOptions = styled.div`
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
`
