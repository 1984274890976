import { Button, Col } from 'antd'
import styled from 'styled-components'

export const Selector = styled(Button)`
  width: 300px;
  max-width: 450px;
  height: 30px;
  padding: 0 12px;
  font-size: 13.5px;
  color: #151b26;

  .anticon {
    font-size: 0.9rem;
    padding-right: 5px;
    font-size: 14px;
    vertical-align: -0.2em;
  }

  .anticon-exclamation-circle-o {
    color: #faad14;
  }

  .anticon-check-circle-o {
    color: #52c41a;
  }
`

export const Actions = styled.div`
  .ant-btn {
    font-size: 13.5px;
    margin-left: 10px;
  }

  .ant-btn-primary {
    font-weight: 500;
  }
`

export const ApprovalTitle = styled.div`
  font-size: 13.5px;
  color: #151b26;
  padding: 5px; 0;
`

export const SelectorText = styled(Col)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ApprovedMessage = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: rgb(140, 141, 142);
  letter-spacing: 0.1px;

  .highlight {
    color: black;
    padding: 0 5px;
  }
`
