import type { ApolloClient } from '@apollo/client'

import { useQuery } from '@apollo/client'
import { Col, Divider, Form, Row } from 'antd'
import { get, isArray, startCase } from 'lodash'
import { useState } from 'react'
import { withApollo } from 'react-apollo'

import { rootKeys } from 'App/components/Booking/DynamicField/helper'
import Place from 'App/components/Booking/NewBookingForm/ConfirmationSubForm/Place'
import Port from 'App/components/Booking/NewBookingForm/ConfirmationSubForm/Port'
import { DynamicField, DynamicFieldQuery, DynamicFieldType } from 'App/types/graphql'
import { VIEW_BOOKING_TYPE_GQL } from '../../General'
import Company from './CompanyInfo/Company'
import JobsTable from './JobsTable'

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
}

function formatArrayString(value: any, field: DynamicField, client: ApolloClient<any>) {
  let v = value

  if (isArray(v) || (field.multiple && field.type === DynamicFieldType.Selector)) {
    v = value.join(', ')
  }

  if (field.type === DynamicFieldType.Date) {
    v = value && value.format('LLL')
  }

  if (field.query === DynamicFieldQuery.Company) {
    v = value && <Company companyUuid={value.uuid || value} />
  }

  if (field.query === DynamicFieldQuery.Place) {
    v = value && <Place placeId={value} client={client} />
  }

  if (field.query === DynamicFieldQuery.Port) {
    v = value && <Port uuid={value} client={client} />
  }

  return v || ''
}

const BookingType = ({ value }: any) => {
  return (
    <Form.Item label="Booking Type" {...formItemLayout}>
      {value}
    </Form.Item>
  )
}

const BillingCustomerFormItem = ({ value }: any) => {
  return (
    <Form.Item label="Billing Customer" {...formItemLayout}>
      <Company companyUuid={value} />
    </Form.Item>
  )
}

const getDynamicFieldKey = (key: string) => {
  return rootKeys.includes(key) ? key : `details.${key}`
}

const ConfirmationSubForm = ({ form, step, isMultiPoint, client }: any) => {
  const { getFieldValue } = form
  const [dynamicFields, setDynamicFields] = useState<DynamicField[]>([])
  const dynamicFieldJobs = getFieldValue('jobs')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    variables: { code: getFieldValue('type') },
    client,
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const fields = get(data, 'bookingType.dynamicFields', [])

      const parsedFields = fields.map(({ key, type, ...res }: any) => {
        return {
          ...res,
          label: startCase(key),
          key: getDynamicFieldKey(key),
          type
        }
      })

      setDynamicFields(parsedFields)
    }
  })

  return (
    <>
      <Divider>Details</Divider>

      <Row>
        <Col span={12}>
          <BillingCustomerFormItem value={getFieldValue('billToUuid')} />
          <BookingType value={getFieldValue('type')} />
        </Col>

        <Col span={12}>
          {dynamicFields.map(field => {
            return (
              <Form.Item key={field.key} label={startCase(field.key ?? '')} {...formItemLayout}>
                {formatArrayString(getFieldValue(field.key), field, client)}
              </Form.Item>
            )
          })}
          <Divider>Job Details</Divider>
          {dynamicFieldJobs?.[0]?.details &&
            Object.entries(dynamicFieldJobs[0].details).map(([key, value]) => {
              return (
                <Form.Item key={key} label={startCase(key)} {...formItemLayout}>
                  {value}
                </Form.Item>
              )
            })}
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ paddingTop: 40 }}>
          <JobsTable form={form} step={step} isMultiPoint={isMultiPoint} />
        </Col>
      </Row>
    </>
  )
}

export default withApollo(ConfirmationSubForm)
