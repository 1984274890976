import { Component } from 'react'
import { Button, Modal } from 'antd'
import filter from 'lodash/filter'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { documentType } from 'App/utils/labelMap'
import { EmptySection, SectionTitle, UploadSection } from './Styled'
import Uploader from './Uploader'

const StyledFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

class UploadZone extends Component {
  render() {
    const { visible, toggleUploadZone, requiredDocs, uploadActions } = this.props

    const mandatoryDocs = filter(requiredDocs, doc => doc.required) || []
    const nonMandatoryDocs = filter(requiredDocs, doc => !doc.required) || []

    return (
      <Modal
        width="700px"
        title="Upload Documents"
        okText="Done"
        visible={visible}
        footer={
          <StyledFooterContainer>
            <Button href="/bookings/new/upload-document">Bulk Upload</Button>
            <Button type="primary" onClick={() => toggleUploadZone(false)}>
              Done
            </Button>
          </StyledFooterContainer>
        }
        onCancel={() => toggleUploadZone(false)}
      >
        <SectionTitle>Required Documents</SectionTitle>
        <UploadSection>
          {mandatoryDocs.length === 0 && <EmptySection>No documents available.</EmptySection>}
          {mandatoryDocs.map((doc, index) => {
            const uploadLabel = documentType[doc.type]

            return (
              <Uploader
                key={index}
                type={doc.type}
                label={uploadLabel || doc.type}
                handleDocumentUpload={uploadActions.handleDocumentUpload}
              />
            )
          })}
        </UploadSection>

        <SectionTitle>Optional Documents</SectionTitle>
        <UploadSection>
          {nonMandatoryDocs.length === 0 && <EmptySection>No documents available.</EmptySection>}
          {nonMandatoryDocs.map((doc, index) => {
            const uploadLabel = documentType[doc.type]

            return (
              <Uploader
                key={index}
                type={doc.type}
                label={uploadLabel || doc.type}
                handleDocumentUpload={uploadActions.handleDocumentUpload}
              />
            )
          })}
        </UploadSection>
      </Modal>
    )
  }
}

UploadZone.propTypes = {
  visible: PropTypes.bool,
  requiredDocs: PropTypes.array,
  uploadActions: PropTypes.object,
  toggleUploadZone: PropTypes.func
}

export default UploadZone
