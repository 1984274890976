import React, { Component } from 'react'
import { Anchor, Col, Row } from 'antd'
import PropTypes from 'prop-types'

import { filterBookingState, getDisplayState } from 'App/utils/booking'
import { AnchorIcon, AnchorState, AnchorTitle, LinkWrapper } from './Container'

const Link = Anchor.Link

const iconSpan = 5
const titleSpan = 19

class Status extends Component {
  renderDefault() {
    return (
      <Row type="flex" align="middle">
        <Col span={iconSpan}>
          <AnchorIcon type="align-left" />
        </Col>
        <Col span={titleSpan}>
          <AnchorTitle>Overview</AnchorTitle>
        </Col>
      </Row>
    )
  }

  renderTitle(stage) {
    return (
      <Row type="flex" align="middle">
        <Col span={iconSpan}>
          <AnchorIcon fa={stage.fa} size={stage.size} type={stage.stageIcon} />
        </Col>
        <Col span={titleSpan}>
          <AnchorTitle>
            {stage.stageName}
            <AnchorState>{stage.state.stateDisplayName}</AnchorState>
          </AnchorTitle>
        </Col>
      </Row>
    )
  }

  findSection(stateName, processFlowType, processFlow) {
    const pf = processFlow.find(p => p.type === processFlowType)

    let section
    if (pf) {
      section = pf.sections.find(section => {
        return section.steps.find(step => step.key === stateName)
      })
    }

    return section
  }

  render() {
    const { bookingState, booking } = this.props

    const stages = bookingState.reduce((arr, state) => {
      const found = arr.find(s => s.sectionKey === state.key)

      if (found) {
        return arr
      }

      const section = this.findSection(state.value, state.key, booking.processFlow)
      const filteredStateInfo = getDisplayState(state.key)

      const obj = {
        fa: filteredStateInfo.fa,
        size: filteredStateInfo.size,
        stageName: filteredStateInfo.defaultDisplayName,
        stageIcon: filteredStateInfo.iconName,
        stageHref: section ? section.name : state.key,
        sectionKey: state.key,
        state: filterBookingState(state.value)
      }

      arr.push(obj)

      return arr
    }, [])

    return (
      <Anchor offsetTop={56} style={{ background: 'none' }}>
        <LinkWrapper>
          <Link title={this.renderDefault()} href="#bookingOverview" />
        </LinkWrapper>
        <div style={{ height: 10 }} />
        {stages.map((stage, index) => {
          return (
            <LinkWrapper key={index}>
              <Link title={this.renderTitle(stage)} href={`#${stage.stageHref}`} />
            </LinkWrapper>
          )
        })}
      </Anchor>
    )
  }
}

Status.propTypes = {
  bookingState: PropTypes.array,
  booking: PropTypes.object
}

export default Status
