import { forwardRef } from 'react'
import { Select } from 'antd'

const SizeSelect = forwardRef((props, ref) => {
  const { value, onChange = () => {}, style } = props
  const sizes = [20, 30, 40, 42, 45]

  return (
    <Select
      ref={ref}
      allowClear
      style={style}
      value={value}
      onChange={onChange}
      placeholder="Select Size"
    >
      {sizes.map(s => {
        return (
          <Select.Option key={s} value={s}>
            {s}
          </Select.Option>
        )
      })}
    </Select>
  )
})

export default SizeSelect
