const _groupByFields = ['departments', 'billTo', 'billToSource', 'origin', 'destination']

export const groupByFieldsMapped = _groupByFields.map(key => ({ value: key, label: key }))

export enum GroupingMethod {
  COUNT = 'COUNT',
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
  LATEST_VALUE = 'LATEST_VALUE'
}
