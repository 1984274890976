import { Component } from 'react'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'

import setBookingAssigneeMutation from 'App/containers/booking/mutation/setBookingAssignee'
import unsetBookingAssigneeMutation from 'App/containers/booking/mutation/unsetBookingAssignee'
import usersQuery from 'App/containers/user/query/users'

export default WrappedComponent => {
  class WithAssignee extends Component {
    componentDidMount() {
      const { booking, updateSelectedUser } = this.props

      if (booking?.assignees?.length) {
        updateSelectedUser(booking.assignees[0] && booking.assignees[0].user)
      }
    }

    focusToField = () => {
      this.props.toggleEdit(true)
    }

    focusOutField = () => {
      this.props.toggleEdit(false)
    }

    handleSelect = async (value, option) => {
      const { updateSelectedUser, setBookingAssignee, booking } = this.props
      try {
        await setBookingAssignee({
          bookingUuid: booking.uuid,
          userUuid: option.props.user.uuid
        })

        updateSelectedUser(option.props.user)
      } catch (error) {
        console.log('WithAssignee error:', error)
      }
    }

    handleDeselect = async value => {
      const { updateSelectedUser, booking, unsetBookingAssignee } = this.props

      const selectedAssignee = find(booking.assignees, assignee => assignee.user.uuid === value)

      await unsetBookingAssignee({
        uuid: selectedAssignee.uuid
      })

      updateSelectedUser(selectedAssignee && selectedAssignee.user)
    }

    render() {
      return (
        <WrappedComponent
          handleSelect={this.handleSelect}
          handleDeselect={this.handleDeselect}
          focusToField={this.focusToField}
          focusOutField={this.focusOutField}
          {...this.props}
        />
      )
    }
  }

  WithAssignee.propTypes = {
    booking: PropTypes.object,
    toggleEdit: PropTypes.func,
    setBookingAssignee: PropTypes.func,
    updateSelectedUser: PropTypes.func,
    unsetBookingAssignee: PropTypes.func
  }

  return compose(
    usersQuery,
    setBookingAssigneeMutation,
    unsetBookingAssigneeMutation,
    withState('editMode', 'toggleEdit', false),
    withState('selectedUser', 'updateSelectedUser', {})
  )(WithAssignee)
}
