import * as Sentry from '@sentry/react'

import packageJson from '../../../package.json'
import { hasPermissionError } from './u'

let sentryInitalized = false

const parseArgs = (args: any[]) => {
  const msg = args[0]
  const obj = args[1] || {}

  // User MUST have Email.
  const context = args[2] || args[1] || {}

  return { obj, msg, context }
}

const capture = (
  { obj, msg, context }: { obj: any; msg: string; context: any },
  level: 'log' | 'info' | 'warning' | 'error' = 'log'
) => {
  if (hasPermissionError(obj)) return

  if (sentryInitalized) {
    Sentry.withScope(scope => {
      scope.setExtras({ ...context, msg })
      scope.setLevel(level)

      if (level === 'error') {
        Sentry.captureException(obj)
      } else {
        Sentry.captureMessage(msg)
      }
    })
  } else {
    if (level === 'error') {
      console.error(msg, obj, context)
    } else {
      // console[level](msg, obj)
      console.log(msg, obj)
    }
  }
}

export const init = (config: any) => {
  if (config.enable && config.dsn) {
    Sentry.init(config)
    Sentry.setTag('version', packageJson.version)
    sentryInitalized = true
  }
}

export const logger = {
  log: (...args: any[]) => {
    capture(parseArgs(args), 'log')
  },
  info: (...args: any[]) => {
    capture(parseArgs(args), 'info')
  },
  warn: (...args: any[]) => {
    capture(parseArgs(args), 'warning')
  },
  error: (...args: any[]) => {
    capture(parseArgs(args), 'error')
  }
}
