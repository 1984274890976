import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from 'antd'

import { useBookingStore } from 'App/store/booking'
import DocumentCreatorModal from './TableView/EditAction/DocumentCreatorModal'
import TemplateSelectionList from './TemplateSelectionList'

const AddAction = () => {
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const [generatedDocumentUuid, setGeneratedDocumentUuid] = useState(undefined)

  const documentGeneratorTableRefetch = useBookingStore.use.documentGeneratorTableRefetch()

  const handleInitialDocumentGenerated = (generatedDocument: any) => {
    setVisible(false)
    setGeneratedDocumentUuid(generatedDocument.uuid)
  }

  const handleEditAfterClose = () => {
    setGeneratedDocumentUuid(undefined)
    documentGeneratorTableRefetch()
  }

  return (
    <>
      <Button icon="plus" shape="circle" onClick={() => setVisible(true)} />
      <Modal
        destroyOnClose
        width={'80%'}
        title={t('booking.documentGenerator.selectDocumentGeneratorTemplate')}
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <TemplateSelectionList handleInitialDocumentGenerated={handleInitialDocumentGenerated} />
      </Modal>
      {generatedDocumentUuid && (
        <DocumentCreatorModal
          generatedDocumentUuid={generatedDocumentUuid}
          afterClose={handleEditAfterClose}
        />
      )}
    </>
  )
}

export default AddAction
