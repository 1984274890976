import { Component } from 'react'
import PropTypes from 'prop-types'

import withAssignee from './Container'
import SingleSelect from './SingleSelect'
import { Wrapper } from './Styled'

class Assignee extends Component {
  render() {
    const { usersQuery, selectedUser } = this.props
    const options = (usersQuery && usersQuery.users && usersQuery.users.rows) || []

    return (
      <Wrapper>
        <SingleSelect
          options={options}
          handleSelect={this.props.handleSelect}
          selectedUser={selectedUser}
        />
      </Wrapper>
    )
  }
}

Assignee.propTypes = {
  usersQuery: PropTypes.object,
  handleSelect: PropTypes.func,
  selectedUser: PropTypes.object
}

export default withAssignee(Assignee)
