import { useState } from 'react'
import { Icon } from 'antd'

import { StyledButton } from 'App/components/ButtonWrapper'
import DocumentCreatorModal from './DocumentCreatorModal'

interface EditActionProps {
  generatedDocumentUuid: string
  refetch: (args?: any) => void
}

const EditAction = (props: EditActionProps) => {
  const { generatedDocumentUuid, refetch } = props

  const [visible, setVisible] = useState(false)

  const handleAfterClose = () => {
    setVisible(false)
    refetch()
  }

  return (
    <>
      {/* @ts-ignore */}
      <StyledButton onClick={() => setVisible(true)}>
        <Icon type="edit" />
      </StyledButton>
      {visible && (
        <DocumentCreatorModal
          generatedDocumentUuid={generatedDocumentUuid}
          afterClose={handleAfterClose}
        />
      )}
    </>
  )
}

export default EditAction
