import React, { Component } from 'react'
import { Button, Col, Form, Modal, Row } from 'antd'
import omit from 'lodash/omit'
import { compose } from 'recompose'

import ROTCancelAction from 'App/components/Booking/General/ROTCancelAction'
import ROTDeleteAction from 'App/components/Booking/General/ROTDeleteAction'
import changeDocumentCreatorStatusMutation from 'App/containers/documentCreator/mutation/changeDocumentCreatorStatus'
import createDocumentCreatorMutation from 'App/containers/documentCreator/mutation/createDocumentCreator'
import updateDocumentCreatorMutation from 'App/containers/documentCreator/mutation/updateDocumentCreator'
import respHandler from 'App/utils/responseHandler'
import TransportSubmitForm from './TransportSubmitForm'

const enhance = compose(
  createDocumentCreatorMutation,
  updateDocumentCreatorMutation,
  changeDocumentCreatorStatusMutation,
  Form.create()
)

class TransportSubmitModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  handleClose = () => {
    const { onClose = () => {} } = this.props
    onClose()
  }

  handleCreate = () => {
    const { form, createDocumentCreator, onChange } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            ...this.state,
            loading: true
          })

          const result = await createDocumentCreator(omit(values, 'status'))

          if (onChange) {
            onChange(result.data && result.data.createDocumentCreator)
          }

          respHandler('ROT is created successfully.', 'success')
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        } finally {
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }
    })
  }

  handleDelete = () => {
    const { onChange = () => {}, onClose = () => {} } = this.props

    onChange()
    onClose()
  }

  handleSave = () => {
    const { form, updateDocumentCreator, onChange } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            ...this.state,
            loading: true
          })

          const result = await updateDocumentCreator(values)

          if (onChange) {
            onChange(result.data && result.data.updateDocumentCreator)
          }

          respHandler('ROT is saved successfully.', 'success')
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        } finally {
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }
    })
  }

  handleSubmitApproval = () => {
    const { form, updateDocumentCreator, changeDocumentCreatorStatus, onChange } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            ...this.state,
            loading: true
          })

          await updateDocumentCreator(values)
          const result = await changeDocumentCreatorStatus(values.uuid, 'SUBMITTED')

          if (onChange) {
            onChange(result.data && result.data.changeDocumentCreatorStatus)
          }

          respHandler(
            `ROT is submitted successfully. RequestNo: ${result.data.changeDocumentCreatorStatus.data.requestNo}.`,
            'success'
          )
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        } finally {
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }
    })
  }

  handleApprove = () => {
    const { form, changeDocumentCreatorStatus, onChange } = this.props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            ...this.state,
            loading: true
          })

          const result = await changeDocumentCreatorStatus(values.uuid, 'APPROVED')

          if (onChange) {
            onChange(result.data && result.data.changeDocumentCreatorStatus)
          }

          respHandler('ROT is approved successfully.', 'success')
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        } finally {
          this.setState({
            ...this.state,
            loading: false
          })
        }
      }
    })
  }

  renderModalFooterNew = () => {
    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleClose}>Close</Button>
          <Button type="primary" loading={this.state.loading} onClick={this.handleCreate}>
            Create
          </Button>
        </Col>
      </Row>
    )
  }

  renderModalFooterDraft = () => {
    const { documentCreator } = this.props

    return (
      <Row>
        <Col span={6} style={{ textAlign: 'left' }}>
          <ROTDeleteAction documentCreator={documentCreator} onDelete={this.handleDelete} />
        </Col>
        <Col span={18}>
          <Button onClick={this.handleClose}>Close</Button>
          <Button loading={this.state.loading} onClick={this.handleSave}>
            Save
          </Button>
          <Button type="primary" loading={this.state.loading} onClick={this.handleSubmitApproval}>
            Send
          </Button>
        </Col>
      </Row>
    )
  }

  renderModalFooterSubmitted = () => {
    const { documentCreator } = this.props

    return (
      <Row>
        <Col span={6} style={{ textAlign: 'left' }}>
          <ROTCancelAction documentCreator={documentCreator} onCancelROT={this.handleDelete} />
        </Col>
        <Col span={18}>
          <Button onClick={this.handleClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  renderModalFooterApproved = () => {
    const { documentCreator } = this.props

    return (
      <Row>
        <Col span={6} style={{ textAlign: 'left' }}>
          <ROTCancelAction documentCreator={documentCreator} onCancelROT={this.handleDelete} />
        </Col>
        <Col span={18}>
          <Button onClick={this.handleClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  renderModalFooterVoid = () => {
    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  renderModalFooterCancelled = () => {
    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  statusModalFooterRender = {
    NEW: this.renderModalFooterNew,
    DRAFT: this.renderModalFooterDraft,
    SUBMITTED: this.renderModalFooterSubmitted,
    APPROVED: this.renderModalFooterApproved,
    VOID: this.renderModalFooterVoid,
    CANCELLED: this.renderModalFooterCancelled
  }

  renderModalFooter = () => {
    const { documentCreator } = this.props
    const render = this.statusModalFooterRender[documentCreator.status]

    if (!render) {
      return null
    }

    return render()
  }

  render() {
    const { form, documentCreator, visible } = this.props

    return (
      <Modal
        destroyOnClose
        title={'Request Of Transport'}
        visible={visible}
        width={'90%'}
        footer={this.renderModalFooter()}
        onCancel={this.handleClose}
      >
        <TransportSubmitForm documentCreator={documentCreator} form={form} />
      </Modal>
    )
  }
}

export default enhance(TransportSubmitModal)
