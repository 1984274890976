import Label from './Label'

const ContainerNos = props => {
  const { booking } = props
  const containerNos = booking.jobs
    .map(j => j.no)
    .filter(Boolean)
    .join(', ')

  return (
    <span>
      <Label>CNTNOS: </Label>
      {containerNos}
    </span>
  )
}

export default ContainerNos
