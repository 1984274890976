import { Button } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div``

export const Actions = styled.span`
  margin-left: 10px;
`

export const Selector = styled(Button)`
  width: 175px;
  border-radius: 0px;
  height: 32px;
  text-align: left;
  font-size: 13px;

  .anticon {
    font-size: 0.85rem;
  }
`
