import React, { Component } from 'react'
import { Icon } from 'antd'
import PropTypes from 'prop-types'

import Dropzone from './Dropzone'
import Label from './Label'

class UploadButton extends Component {
  render() {
    const { label, handleUpload } = this.props

    return (
      <Dropzone onDrop={handleUpload}>
        <Icon style={{ color: '#1890ff', fontSize: '1.5rem' }} type="inbox" />
        <Label>{label}</Label>
      </Dropzone>
    )
  }
}

UploadButton.propTypes = {
  label: PropTypes.string,
  handleUpload: PropTypes.func
}

export default UploadButton
