import { useMemo, useState } from 'react'
import { startCase } from 'lodash'
import styled from 'styled-components'

import PortSelector from 'App/components/Booking/DynamicField/PortSelector'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'

interface Props {
  value?: any
  onChange?: (value: any) => void
  mode?: FormMode
}

const PortPlaceSelectorWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
`

const PortPlaceSelector = (props: Props) => {
  const { value, onChange, mode } = props
  const [countryAlpha3, setCountryAlpha3] = useState<string>(props?.value?.countryAlpha3 ?? '')

  const derivedValue = useMemo(() => {
    if (mode === FormMode.Edit && value?.meta) {
      const parsedMeta = JSON.parse(value?.meta)
      return startCase(parsedMeta.portsDbId)
    }

    return value.uuid
  }, [mode, value])

  return (
    <PortPlaceSelectorWrapper>
      <CountrySelect value={countryAlpha3} onChange={setCountryAlpha3} useAlpha3={true} />

      {/* @ts-ignore */}
      <PortSelector
        disabled={!countryAlpha3}
        countryAlpha3={countryAlpha3}
        setCountryAlpha3={setCountryAlpha3}
        value={derivedValue}
        onChange={onChange}
      />
    </PortPlaceSelectorWrapper>
  )
}

export default PortPlaceSelector
