import { memo, useMemo } from 'react'
import { Col, Form, Row, Typography } from 'antd'
import { cloneDeep } from 'lodash'

import './StatusForm.css'

import { isActiveLeg, mergeAddressFull } from 'App/components/Transport/Utils/jobHelper'
import { TransportJob } from 'App/types/graphql'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 }
  }
}

type UpdateJobFormPropsType = {
  form?: any
  handleModalOk?: any
  trip?: any
  leg?: any
  updateJobLoading?: boolean
}

const UpdateJobForm = (props: UpdateJobFormPropsType) => {
  const { form, handleModalOk, trip, leg, updateJobLoading } = props

  const { getFieldDecorator, getFieldValue } = form

  const jobNo: string = useMemo(
    () => (getFieldValue('no') === undefined ? leg?.no : getFieldValue('no')),
    [getFieldValue, leg]
  )
  const transportTypeSize = useMemo(
    () => [leg?.transportType, leg?.size].filter((a: string) => a),
    [leg]
  )

  const clonedLegs = useMemo(() => cloneDeep(trip), [trip])
  const firstActiveLeg = useMemo(
    () => clonedLegs?.find((leg: TransportJob) => isActiveLeg(leg)),
    [clonedLegs]
  )
  const fromNameZone = useMemo(
    () =>
      `${firstActiveLeg?.from?.name}${
        firstActiveLeg?.from?.zone ? ' (' + firstActiveLeg?.from?.zone + ')' : ''
      }`,
    [firstActiveLeg]
  )
  const fromAddress = useMemo(() => mergeAddressFull(firstActiveLeg?.from), [firstActiveLeg])

  const legsFromLatest = useMemo(
    () => clonedLegs?.sort((a: any, b: any) => b.sequence - a.sequence),
    [clonedLegs]
  )
  const lastActiveLeg = useMemo(
    () => legsFromLatest?.find((leg: TransportJob) => isActiveLeg(leg)),
    [legsFromLatest]
  )
  const toNameZone = useMemo(
    () =>
      `${lastActiveLeg?.to?.name}${
        lastActiveLeg?.to?.zone ? ' (' + lastActiveLeg?.to?.zone + ')' : ''
      }`,
    [lastActiveLeg]
  )
  const toAddress = useMemo(() => mergeAddressFull(lastActiveLeg?.to), [lastActiveLeg])

  return (
    <Form>
      <Row type="flex" align="middle" gutter={24}>
        <FieldName name="Job No :" value={leg?.jobNo} copyable />
        <FieldName name="Bill To :" value={leg?.billToName} copyable />
      </Row>

      <Row type="flex" justify="space-between" align="middle" gutter={24}>
        <FieldName
          name="Shipper Ref :"
          value={leg?.shipperRef || '-'}
          copyable={!!leg?.shipperRef}
        />
        <FieldName
          name="Type :"
          value={transportTypeSize?.join('/') || '-'}
          copyable={!!leg?.transportType}
        />
      </Row>

      <Row type="flex" justify="space-between" align="middle" gutter={24}>
        <FieldName name="From :" value={fromNameZone} copyable />
        <FieldName name="To :" value={toNameZone} copyable />
      </Row>

      <Row type="flex" justify="space-between" align="middle" gutter={24}>
        <FieldName name="" value={fromAddress} copyable />
        <FieldName name="" value={toAddress} copyable />
      </Row>
    </Form>
  )
}

export default UpdateJobForm

export const FieldName = memo((props: any) => {
  const { name, value, copyable } = props
  return (
    <Col span={12}>
      <Col span={5}>
        <Typography.Text strong>{name}</Typography.Text>
      </Col>
      <Col span={19}>
        <Typography.Text type="secondary" copyable={value && !!copyable}>
          {value}
        </Typography.Text>
      </Col>
    </Col>
  )
})
