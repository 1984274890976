import { useEffect } from 'react'
import { compose, graphql } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { Timeline } from 'antd'
import { uniq } from 'lodash'
import find from 'lodash/find'

import Chronology from 'App/components/Workflow/Chronology'
import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import useJobTypes from 'App/hooks/useJobTypes'
import RenderJobSchedules from './RenderSchedule'
import { ScheduleWrapper } from './Styled'

const bookingForSchedulesQuery = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  name: 'bookingForSchedulesQuery',
  options: props => ({
    variables: {
      uuid: props.booking.uuid
    },
    fetchPolicy: 'cache-and-network'
  })
})

const enhance = compose(bookingForSchedulesQuery)

const renderSchedules = booking => {
  const { chronologies, processFlow } = booking
  const bookingSchedules = processFlow.reduce((acc, item) => {
    const sectionTypes = item.sections.reduce((sectionAcc, section) => {
      if (section.chronologies) {
        const chronologiesTypes = section.chronologies.map(chrono => chrono.type)
        return [...sectionAcc, ...chronologiesTypes]
      }
      return sectionAcc
    }, [])
    return [...acc, ...sectionTypes]
  }, [])

  return bookingSchedules?.map(schedule => {
    const findChrono = find(chronologies, c => c.type === schedule)
    const chrono = findChrono
      ? { data: findChrono, type: findChrono.type, title: schedule }
      : { data: {}, type: schedule, title: schedule }

    return (
      <Timeline.Item key={chrono.type}>
        <Chronology chrono={chrono} booking={booking} />
      </Timeline.Item>
    )
  })
}

const Schedules = props => {
  const { bookingForSchedulesQuery, client, refetchBooking } = props
  const { requestJobTypes, jobTypes } = useJobTypes(client)

  const { data } = useQuery(JOBS_FROM_BOOKING_SLIM_QUERY, {
    client,
    variables: { uuid: bookingForSchedulesQuery?.booking?.uuid },
    skip: !bookingForSchedulesQuery?.booking,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  })
  useEffect(() => {
    const jobTypesKeys = uniq(data?.booking?.jobs?.map(job => job.type)).filter(Boolean)

    if (!jobTypesKeys.length) return
    requestJobTypes(jobTypesKeys)
  }, [data?.booking])

  const jobs = bookingForSchedulesQuery?.booking?.jobs?.map(i => {
    return {
      job: i,
      jobNo: i.jobNo,
      details: i.details,
      trip: i.trips,
      remarks: data?.booking?.remarks
    }
  })
  const { booking } = bookingForSchedulesQuery
  if (!booking) {
    return null
  }
  return (
    <ScheduleWrapper>
      <Timeline>{renderSchedules(booking)}</Timeline>
      <Timeline>
        <RenderJobSchedules
          jobs={jobs}
          client={client}
          refetch={refetchBooking}
          jobTypes={jobTypes}
        />
      </Timeline>
    </ScheduleWrapper>
  )
}

export default enhance(Schedules)
