import { Component } from 'react'
import { Modal } from 'antd'

import config from 'App/config'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import pJson from '../../../../package.json'

const clearCacheModal = (latestVersion, currentVersion) => {
  console.table({ latestVersion, currentVersion, packageVersion: pJson.version })
  return Modal.info({
    title: `Update Version ${latestVersion}`,
    content: `We will refresh this browser to update the application from v${currentVersion} to the latest version.`,
    onOk: function () {
      if (caches) {
        caches.keys().then(function (names) {
          for (const name of names) caches.delete(name)
        })
      }
      window.location.reload(true)
    }
  })
}

const errorModal = error => {
  Modal.info({
    title: 'An error Occured',
    content: `${error}`
  })
}

const semverGreaterThan = (latestVersion, currentVersion, noUpdates) => {
  if (!latestVersion || !currentVersion) return

  const latestVArr = latestVersion.replace('-rc', '').split('.')
  const currentVArr = currentVersion?.replace('-rc', '').split('.')
  if (!latestVArr || !currentVArr) {
    return
  }

  for (let i = 0; i < latestVArr.length; i++) {
    if (!!currentVArr[i] && latestVArr[i] > currentVArr[i]) {
      return clearCacheModal(latestVersion, currentVersion)
    }
  }
  noUpdates && noUpdates()
  console.log('App is up-to-date')
}

export const handleCheckUpdate = (initState, noUpdates, revertState) => {
  initState && initState()
  fetch('/meta.json?foo=bar')
    .then(response => response.json())
    .then(meta => {
      const latestVersion = meta.version
      const currentVersion = config.version
      semverGreaterThan(latestVersion, currentVersion, noUpdates)
    })
    .then(() => {
      revertState &&
        setTimeout(() => {
          revertState()
        }, 5000)
    })
    .catch(error => {
      errorModal(error.message)
      revertState && revertState()
    })
}

export default class CacheBuster extends Component {
  componentDidMount() {
    fetch('/meta.json?foo=bar')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version
        const currentVersion = config.version
        semverGreaterThan(latestVersion, currentVersion)
      })
      .catch(error => {
        respHandler(error, 'error')
        logger.error('Error occured while checking for updates', error)
      })
  }

  render() {
    return this.props.children
  }
}
