import React, { forwardRef } from 'react'
import { Col, Form } from 'antd'

import { FieldColRow, FieldsWrapper } from './Styled'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 }
}

const InputForm = forwardRef((props, ref) => {
  const { fields, editable, getFieldDecorator } = props

  return (
    <FieldsWrapper ref={ref}>
      <FieldColRow gutter={16}>
        {fields.map((field, fieldIndex) => {
          return (
            <Col key={fieldIndex} span={12}>
              {field.cols.map((col, colIndex) => (
                <React.Fragment key={colIndex}>
                  {col.type &&
                    col.type === 'hidden' &&
                    (editable
                      ? getFieldDecorator(col.value, col.attributes)(col.input)
                      : col.displayOnly)}
                  {!col.type && (
                    <FormItem label={col.label} {...formItemLayout}>
                      {editable
                        ? getFieldDecorator(col.value, col.attributes)(col.input)
                        : col.displayOnly}
                    </FormItem>
                  )}
                </React.Fragment>
              ))}
            </Col>
          )
        })}
      </FieldColRow>
    </FieldsWrapper>
  )
})

export default InputForm
