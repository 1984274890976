import React, { Component } from 'react'
import { Checkbox } from 'antd'
import filter from 'lodash/filter'

import withOverrides from './Container'
import Wrapper from './Styled'

const CheckboxGroup = Checkbox.Group

class VoucherOverrides extends Component {
  render() {
    const { selectedVoucher, options, handleChange } = this.props
    const checkedOptions = filter(options, option => selectedVoucher[option.value])

    return (
      <Wrapper>
        <CheckboxGroup
          options={options}
          onChange={handleChange}
          value={checkedOptions.map(option => option.value)}
        />
      </Wrapper>
    )
  }
}

export default withOverrides(VoucherOverrides)
