import React, { Component } from 'react'
import { Select } from 'antd'

const Option = Select.Option

const tags = [
  {
    label: 'Default',
    value: 'isDefault'
  }
]

class AddressTagsSelect extends Component {
  handleChange = value => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const { value } = this.props

    return (
      <Select
        mode="tags"
        showSearch
        showArrow
        value={value}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {tags.map((tag, index) => (
          <Option key={index} value={tag.value}>
            {tag.label}
          </Option>
        ))}
      </Select>
    )
  }
}

export default AddressTagsSelect
