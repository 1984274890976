import { Col, Menu, Row } from 'antd'
import styled from 'styled-components'

export const RightNav = styled(Row)`
  .anticon-setting {
    font-size: 19px;
    cursor: pointer;
    vertical-align: -0.225em;
  }
`

export const NavItem = styled(Col)`
  a {
    font-size: 13.222px;
    font-weight: ${props => (props.selected ? 'bold' : '500')};
    text-decoration: none;
    color: ${props => (props.selected ? '#151b26' : 'rgb(140, 140, 140)')};

    &:hover {
      color: black;
    }
  }

  .ant-popover-inner-content {
    padding: 0px;
  }

  .ant-popover-arrow {
    display: none;
  }
`

export const SubNavItem = styled(Menu)`
  min-width: 130px;
  max-width: 200px;
`

export const SettingsView = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border: 1px solid #d5dce0;
  font-size: 13px;
  color: #151b26;
  min-width: 200px;
  padding: 0px;
`

export const SettingItemLanguage = styled.div`
  display: flex;
  padding: 8px 15px;

  span {
    color: rgb(120, 120, 120);
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0.3px;
    &:hover {
      color: black;
    }
  }
`

export const SettingItem = styled.div`
  a {
    padding: 8px 15px;
    color: rgb(120, 120, 120);
    width: 100%;
    display: block;
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0.3px;
  }

  span {
    padding: 8px 15px;
    color: rgb(120, 120, 120);
    width: 100%;
    display: block;
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0.3px;
    &:hover {
      color:black;
    }
  }

  .inline-icon {
    padding: 8px 15px;
    color: rgb(120, 120, 120);
    width: 100%;
    display: inline-block
    font-size: 13.5px;
    font-weight: 400;
    letter-spacing: 0.3px;
    &:hover {
      color:black;
    }
  }

  .inline-icon a {
    width: 50px;
    display: inline;
  }
`

export const UserProfile = styled.div`
  padding: 15px;
  padding-bottom: 0;
  text-align: center;

  img {
    width: 32px;
    border-radius: 50%;
  }
`

export const UserEmail = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: #151b26;
  margin: 10px 0 0;
`

export const Separator = styled.div`
  height: 1px;
  background-color: rgb(230, 230, 230);
`

export const AppVersion = styled.div`
  margin: 5px 0 10px;
  font-size: 11.5px;
  font-weight: bold;
  color: rgb(160, 160, 160);
  cursor: pointer;
`
