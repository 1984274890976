import DynamicField from 'App/components/Shared/DynamicFieldComponent'
import type { Maybe, DynamicField as TypedDynamicField } from 'App/types/graphql'
import { useCustomOnChange } from './utils'

type DynamicFieldWrapperProps = {
  onChange?: (value: any) => void
  value?: any
  disabled: boolean
  field: Maybe<TypedDynamicField>
}

const DynamicFieldWrapper = ({
  disabled,
  field,
  onChange = () => {},
  value
}: DynamicFieldWrapperProps) => {
  const wrappedOnChange = useCustomOnChange(onChange)

  return <DynamicField onChange={wrappedOnChange} disabled={disabled} value={value} field={field} />
}

export default DynamicFieldWrapper
