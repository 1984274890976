import { compose } from 'recompose'

import WithHeader from 'App/components/Layouts/WithHeader'
import bootstrap from 'App/hocs/bootstrap'
import NewBookingPage from 'App/pages/NewBooking'

const MainPage = props => {
  return (
    <WithHeader {...props}>
      <NewBookingPage />
    </WithHeader>
  )
}

export default compose(bootstrap)(MainPage)
