import { memo, useEffect, useMemo } from 'react'
import { Query, withApollo } from 'react-apollo'
import { gql, useLazyQuery } from '@apollo/client'
import { Icon } from 'antd'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import numeral from 'numeral'

import { getRef } from 'App/utils/booking'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import { isUuid } from 'App/utils/u'
import { sortVoucherItems } from 'App/utils/voucher'
import CustomText from '../CustomText'
import { DeletedItem, MultiRow } from '../Styled'
import UnitSizeContainer from '../UnitSizeContainer'
import Actions from './Actions'
import withVoucherItems from './Container'
import TableView from './TableView'
import WarningTips from './WarningTips'

const INVOICE_ITEMS_JOBS_QUERY = gql`
  query jobsForInvoiceItems($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        uuid
        jobNo
      }
    }
  }
`

const TAX_VIEW = gql`
  query tax($uuid: UUID!) {
    tax(uuid: $uuid) {
      uuid
      name
      code
      exemptionDocumentUuid
      percentage
    }
  }
`

const voucherBookingsCache = {}

const VoucherItems = memo(props => {
  const {
    form,
    client,
    booking,
    loadingShift,
    handleShiftUp,
    handleShiftDown,
    selectedVoucher,
    voucherBookings,
    onRemoveVoucherItem,
    selectedGlobalCompany,
    handleUpdateVoucherItem,
    showDeletedVoucherItems,
  } = props

  const voucherItems = useMemo(() => selectedVoucher?.voucherItems || [], [selectedVoucher])

  const [getJobs, { data: jobsData, error: jobsErr }] = useLazyQuery(INVOICE_ITEMS_JOBS_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  if (jobsErr) {
    handleResponse(jobsErr, 'error')
    logger.error('Invoice Items Table INVOICE_ITEMS_JOBS_QUERY error', jobsErr)
  }

  useEffect(() => {
    voucherItems?.length &&
      voucherItems.forEach(vi => {
        if (vi.jobUuid && !vi.job?.jobNo && isUuid(vi.bookingUuid)) {
          getJobs({ variables: { bookingUuid: vi.bookingUuid } })
        }
      })
  }, [voucherItems, getJobs])

  if (booking?.uuid && !voucherBookingsCache[booking?.uuid]) {
    voucherBookingsCache[booking?.uuid] = booking
  }

  voucherBookings?.length &&
    voucherBookings.forEach(booking => {
      if (booking?.uuid && !voucherBookingsCache[booking?.uuid]) {
        voucherBookingsCache[booking?.uuid] = booking
      }
    })

  let sortedVoucherItems = sortVoucherItems(voucherItems)

  if (!showDeletedVoucherItems) {
    sortedVoucherItems = filter(sortedVoucherItems, vi => !vi.isDeleted)
  }

  const dataSource = sortedVoucherItems.map(item => ({
    key: item.uuid,
    details: {
      ...item,
      taxPercentage: item.taxPercentage || 0
    }
  }))

  const columns = []

  if (includes(['DRAFT', 'NEW'], selectedVoucher?.status)) {
    columns.push({
      title: '',
      key: 'action',
      render: (text, record) => {
        if (record.details.isDeleted) {
          return (
            <DeletedItem>
              <Icon type="minus-circle" />
            </DeletedItem>
          )
        }

        return (
          <Actions
            form={form}
            booking={booking}
            loadingShift={loadingShift}
            voucherItem={record.details}
            handleShiftUp={handleShiftUp}
            handleShiftDown={handleShiftDown}
            selectedVoucher={selectedVoucher}
            onRemoveVoucherItem={onRemoveVoucherItem}
            handleUpdateVoucherItem={handleUpdateVoucherItem}
          />
        )
      }
    })
  }

  columns.push({
    title: 'Booking',
    dataIndex: 'bookingUuid',
    key: 'bookingUuid',
    width: 150,
    render: (text, record) => {
      const voucherItem = record?.details
      const bookingUuid = voucherItem?.bookingUuid || voucherItem?.costItem?.bookingUuid

      const thisBooking = voucherBookingsCache[bookingUuid]
      const type = selectedGlobalCompany?.types?.[0]
      const ref = getRef(type, thisBooking)

      return (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {`${ref || '-'} ${voucherItem?.job?.no ? `(${voucherItem.job.no})` : ''}`}
        </span>
      )
    }
  })

  columns.push({
    title: 'Job',
    dataIndex: 'jobUuid',
    key: 'jobUuid',
    render: (text, record) => {
      let jobNo = record.details?.job?.jobNo
      if (!jobNo) {
        jobNo = jobsData?.jobs?.rows?.find(j => j.uuid === record.details?.jobUuid)?.jobNo
      }

      return <span style={{ whiteSpace: 'pre' }}>{jobNo || '-'}</span>
    }
  })

  // if (selectedVoucher?.isCreditNote) {
  //   columns.push(
  //     {
  //       title: 'Voucher No.',
  //       key: 'voucherNumber',
  //       render: (text, record) => (
  //         <CustomText
  //           text={record.details.voucherCn && record.details.voucherCn.voucherNumber}
  //           noPadding
  //         />
  //       )
  //     },
  //     {
  //       title: 'Job No.',
  //       key: 'jobNo',
  //       render: (text, record) => (
  //         <CustomText text={record.details.job && record.details.job.no} noPadding />
  //       )
  //     }
  //   )
  // }

  columns.push(
    {
      title: 'Item',
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => {
        const desc = record.details?.description

        return (
          <MultiRow>
            <CustomText noPadding text={record.details?.costItem?.chargeItem?.code} />
            {' - '}
            <CustomText text={record.details?.costItem?.chargeItem?.name} />
            <br />
            {desc && <CustomText noPadding text={desc} muted multiline />}
          </MultiRow>
        )
      }
    },
    {
      title: 'Unit/Size',
      key: 'unitSizeType',
      render: (text, record) => (
        <UnitSizeContainer size={record.details.size} unit={record.details.unit} />
      )
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.quantity} />
    },
    {
      title: 'Base rate',
      dataIndex: 'currencyBaseRateExRate',
      key: 'currencyBaseRateExRate',
      align: 'right',
      render: (text, record) => {
        const currency = (record.details.currency && record.details.currency.code) || 'N/A'
        const baseRate = record.details.baseRate
        const exchangeRate = numeral(record.details.exchangeRate).value()

        return (
          <Query
            query={exchangeRateQuery}
            variables={{
              fromUuid: record.details.currency?.uuid,
              toUuid: selectedVoucher?.currency && selectedVoucher?.currency?.uuid
            }}
            skip={record?.details?.currency?.uuid === selectedVoucher?.currency?.uuid}
          >
            {({ loading, data, error }) => {
              if (loading) {
                return <CustomText text="Hang on..." muted />
              }

              const voucherExchangeRate = (data &&
                data.exchangeRates &&
                data.exchangeRates.rows[0]) || { rate: 1 }

              const isDiffExchangeRate =
                numeral(voucherExchangeRate && voucherExchangeRate.rate).value() !== exchangeRate
              const isMoreThanCostItem =
                baseRate >
                (selectedVoucher?.transactionType === 'ACCREC'
                  ? record.details.costItem.sellRate
                  : record.details.costItem.costRate)

              return (
                <>
                  {isMoreThanCostItem && (
                    <WarningTips message="Value is more than CostItem value." />
                  )}
                  <CustomText text={currency} muted noPadding />
                  <CustomText text={baseRate} isNumeric />
                  {exchangeRate !== 1 && (
                    <>
                      <CustomText text="@" muted />
                      <CustomText
                        text={exchangeRate}
                        format="(0,0.0000)"
                        isNumeric
                        muted
                        noPadding
                      />
                      {isDiffExchangeRate && (
                        <WarningTips message="This item has different currency exchange rate." />
                      )}
                    </>
                  )}
                </>
              )
            }}
          </Query>
        )
      }
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.rate} isNumeric />
    }
  )

  if (selectedVoucher?.currency?.uuid !== selectedGlobalCompany?.company?.currency?.uuid) {
    columns.push({
      title: 'Ex. Rate',
      dataIndex: 'currencyLocalExRate',
      key: 'currencyLocalExRate',
      align: 'right',
      render: (text, record) => record.details.localExchangeRate
    })
  }

  columns.push(
    {
      title: 'Tax',
      dataIndex: 'taxPercentage',
      key: 'taxPercentage',
      align: 'right',
      render: (text, record) => {
        const taxCode = record.details.tax && record.details.tax.code
        const taxPercentage = record.details.taxPercentage

        return (
          <Query
            query={TAX_VIEW}
            variables={{
              uuid: record.details.taxUuid
            }}
            skip={record?.details?.taxUuid === record.details.tax.uuid || !record?.details?.taxUuid}
          >
            {({ loading, data, error }) => {
              const updatedTax = data?.tax

              if (updatedTax) {
                record.details.tax = updatedTax
              }

              return (
                <>
                  <CustomText text={`${updatedTax?.code || taxCode}`} muted />
                  <CustomText text={taxPercentage} isNumeric noPadding />
                </>
              )
            }}
          </Query>
        )
      }
    },
    {
      title: 'Tax Total',
      dataIndex: 'taxTotal',
      key: 'taxTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.taxTotal} isNumeric />
    },
    {
      title: 'Sub Total',
      dataIndex: 'subTotal',
      key: 'subTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.subTotal} isNumeric />
    },
    {
      title: 'Profit',
      key: 'profit',
      align: 'right',
      render: (text, record) => {
        const quantity = record.details.quantity
        const sellRate = record.details.costItem.sellRate
        const costRate = record.details.costItem.costRate

        const profit = quantity * sellRate - quantity * costRate

        return <CustomText text={profit} isNumeric />
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.total} isNumeric />
    }
  )

  return <TableView columns={columns} dataSource={dataSource} selectedVoucher={selectedVoucher} />
})

const exchangeRateQuery = gql`
  query exchangeRates3($fromUuid: UUID, $toUuid: UUID) {
    exchangeRates(fromUuid: $fromUuid, toUuid: $toUuid) {
      rows {
        uuid
        from {
          uuid
          code
          name
        }
        to {
          uuid
          code
          name
        }
        company {
          uuid
          name
        }
        start
        end
        rate
      }
    }
  }
`

export default withApollo(withVoucherItems(VoucherItems))
