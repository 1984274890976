import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Button, Form, Popover, Table } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { ColumnProps } from 'antd/lib/table'

import { TableWrapper } from 'App/components/Voucher/Styled'
import { BOOKINGS_SEARCH_QUERY } from 'App/graphql/booking'
import { useClient } from 'App/hooks/useClient'
import { useBookingStore } from 'App/store/booking'
import { Booking, QMethods } from 'App/types/graphql'
import BookingSelectForm from './BookingSelectForm'

interface AssociatedBookingsTableProps {
  form: WrappedFormUtils
  generatedDocument: any
}

const AssociatedBookingsTable = (props: AssociatedBookingsTableProps) => {
  const { form, generatedDocument } = props

  const [visible, setVisible] = useState(false)

  const client = useClient()
  const { t } = useTranslation()

  const { data, loading } = useQuery(BOOKINGS_SEARCH_QUERY, {
    variables: {
      input: {
        q: generatedDocument.groups.join(' '),
        qMethod: QMethods.Or
      }
    },
    client
  })

  const columns: ColumnProps<Booking>[] = [
    {
      key: 'no',
      title: 'No',
      dataIndex: 'no'
    }
  ]

  const setForm = useBookingStore.use.setDocumentGeneratorAssociatedBookingsForm()
  const resetForm = useBookingStore.use.resetDocumentGeneratorAssociatedBookingsForm()

  useEffect(() => {
    setForm(form)

    return () => {
      resetForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Popover
        content={<BookingSelectForm form={form} generatedDocument={generatedDocument} />}
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
      >
        {t('common.bookings')} <Button size="small" icon="plus" shape="circle" />
      </Popover>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={data?.bookingsSearchJson?.rows || []}
          rowKey={'uuid'}
          pagination={false}
          loading={loading}
        />
      </TableWrapper>
    </>
  )
}

export default Form.create<AssociatedBookingsTableProps>()(AssociatedBookingsTable)
