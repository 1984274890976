import { gql } from '@/types'

export const CREATE_PLUGIN = gql(`
  mutation createPlugin($input: PluginInput!) {
    createPlugin(input: $input) {
      id
    }
  }
`)

export const GET_PLUGIN_LIST_TEST = gql(`
  query pluginForComponent($componentLocation: [String]) {
    plugins(componentLocation: $componentLocation) {
      rows {
        id
        config {
          componentName
        }
      }
    }
  }
`)

export const GET_PLUGIN = gql(`
  query plugin($pluginId: ID!) {
    plugin(id: $pluginId) {
      id
      ownerUuid
      name
      status
      config {
        codes
        type
        url
        javascriptFunction
        operationType
        method
        headers
        componentName
        componentLocation
        method
        snippet
      }
    }
  }
`)

export const GET_PLUGINS_TABLE = gql(`
  query pluginsTable($limit: Int, $offset: Int) {
    plugins(limit: $limit, offset: $offset) {
      rows {
        name
        status
        id
      }
    }
  }
`)

export const UPDATE_PLUGIN = gql(`
  mutation updatePlugin($id: ID!, $input: UpdatePluginInput!) {
    updatePlugin(id: $id, input: $input) {
      id
    }
  }
`)

export const DELETE_PLUGIN = gql(`
  mutation deletePlugin($id: ID!) {
    deletePlugin(id: $id) {
      success
      message
    }
  }
`)

export const UNDELETE_PLUGIN = gql(`
  mutation undeletePlugin($id: ID!) {
    undeletePlugin(id: $id) {
      success
      message
    }
  }
`)
