import { graphql } from 'react-apollo'

import schema from '../schema'

export default graphql(schema.UNLINK_DOCUMENT_FROM_VOUCHER, {
  props: ({ mutate, ownProps }) => ({
    unlinkDocumentFromVoucher: params =>
      mutate({
        variables: params
      })
  })
})
