import { gql } from '@apollo/client'

import { fragments as chargeItemFragment } from 'App/containers/chargeItem/fragment'
import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import { fragments as taxFragment } from 'App/containers/tax/fragment'

export default gql`
  fragment QuotationItemDetails on QuotationItem {
    uuid
    chargeItem {
      ...ChargeItemDetails
    }
    unit
    size
    containerType
    rateType
    sellCurrency {
      ...CurrencyDetails
    }
    sellRate
    sellTax {
      ...TaxDetails2
    }
    costRate
    costTax {
      ...TaxDetails2
    }
    costCurrency {
      ...CurrencyDetails
    }
    minChargeAmount
    maxChargeAmount
    tags
    description
  }

  ${taxFragment.taxDetails}
  ${currencyFragment.currencyDetails}
  ${chargeItemFragment.chargeItemDetails}
`
