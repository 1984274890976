import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import ViewerContent from './Content'
import ViewerHeader from './Header'
import { ModalViewer } from './Styled'

class DocumentViewer extends Component {
  render() {
    const { showDocumentViewer } = this.props

    if (!showDocumentViewer) {
      return null
    }

    const { selectedBookingDocument, toggleDocumentViewer, bookingQuery } = this.props

    return (
      <ModalViewer
        title={null}
        footer={null}
        closable={false}
        visible={showDocumentViewer}
        onCancel={toggleDocumentViewer}
      >
        <ViewerHeader
          onBack={toggleDocumentViewer}
          bookingDocument={selectedBookingDocument}
          bookingQuery={bookingQuery}
        />
        <ViewerContent
          bookingQuery={bookingQuery}
          bookingDocument={selectedBookingDocument}
          toggleDocumentViewer={toggleDocumentViewer}
          refreshBooking={this.props.refreshBooking}
        />
      </ModalViewer>
    )
  }
}

export default compose(
  connect(
    (state, props) => ({
      showDocumentViewer: state.bookingDocument.showDocumentViewer,
      selectedBookingDocument: state.bookingDocument.selectedBookingDocument
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...bookingDocumentActions
        },
        dispatch
      )
    })
  )
)(DocumentViewer)
