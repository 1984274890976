import { Menu } from 'antd'
import styled from 'styled-components'

export default styled.span`
  margin-left: 10px;
  margin-bottom: 30px;
`
export const MenuIcon = styled(Menu.Item)`
  margin-right: 5px;
`
export const MenuText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
`
