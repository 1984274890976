export const actions = {
  UPDATE_CURRENCIES: 'UPDATE_CURRENCIES'
}

export const updateCurrencies = currencies => ({
  type: actions.UPDATE_CURRENCIES,
  currencies
})

const initialState = {
  currencies: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENCIES:
      return { ...state, currencies: action.currencies }
    default:
      return state
  }
}
