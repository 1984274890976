import type { DocumentNode } from 'graphql'
import {
  BillingUnitStatus,
  BookingTypeStatus,
  ContainerGradeStatus,
  ContainerTypeStatus,
  JobOptionStatus,
  JobTypeStatus,
  TransportJobTypeStatus,
  VolumeUnitStatus,
  WeightUnitStatus
} from 'App/types/graphql'

import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'
import { identity, isEqual } from 'lodash'
import debounce from 'lodash/debounce'

import { logger } from 'App/utils/logger'

const defaultDisplayOptions = {
  label: 'name',
  key: 'code',
  value: 'code'
}

type DisplayOption = {
  label: string
  key: string
  value: string
  statues: ['ACTIVE']
}

export enum Mode {
  DEFAULT = 'default',
  MULTIPLE = 'multiple',
  TAGS = 'tags'
}

const lookup: any = {
  billingUnits: {
    statuses: [BillingUnitStatus.Activated]
  },
  bookingTypes: {
    statuses: [BookingTypeStatus.Active]
  },
  jobTypes: {
    statuses: [JobTypeStatus.Active]
  },
  transportJobTypes: {
    statuses: [TransportJobTypeStatus.Active]
  },
  containerTypes: {
    statuses: [ContainerTypeStatus.Active]
  },
  containerGrades: {
    statuses: [ContainerGradeStatus.Active]
  },
  weightUnits: {
    statuses: [WeightUnitStatus.Active]
  },
  volumeUnits: {
    statuses: [VolumeUnitStatus.Active]
  },
  jobOptions: {
    statuses: [JobOptionStatus.Active]
  }
}

const getDisplayOptions = (options: any, type: string): any => {
  return {
    ...defaultDisplayOptions,
    ...lookup[type],
    ...options
  }
}

export interface DynamicTransportSelectProps {
  client?: any
  value?: any
  onChange?: any
  disabled?: boolean
  style?: any
  query: DocumentNode
  type: string
  mode?: Mode
  displayOptions?: DisplayOption
  selectOptions?: string[] | undefined
  searchText?: string
  queryOnMount?: boolean
  defaultActiveFirstOption?: boolean
  queryVariables?: any
  filterFunc?: any
}

const DynamicTransportSelect = forwardRef((props: DynamicTransportSelectProps, ref: any) => {
  const {
    client,
    value,
    onChange,
    disabled = false,
    style,
    query,
    type,
    mode = 'default',
    searchText = '',
    displayOptions,
    queryOnMount = false,
    defaultActiveFirstOption = false,
    selectOptions,
    queryVariables,
    filterFunc = identity
  } = props
  // @ts-ignore
  const { label, value: dataValue, key, statuses } = { ...getDisplayOptions(displayOptions, type) }

  const [types, setTypes] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const prevQueryParams = useRef()

  const params = useMemo(
    () => ({
      q: searchInput || '',
      limit: 20,
      statuses
    }),
    [value, searchInput]
  )

  const [getData, { data, error, loading }] = useLazyQuery(query, {
    client,
    fetchPolicy: 'cache-first'
  })

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getData({ variables: { ...params, q: value } })
      } catch (error) {
        logger.error(`Dynamic Transport Select ${type} error when searching`, error)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount || value) {
      getData({ ...params, ...queryVariables })
    }
  }, [])

  useEffect(() => {
    if (!defaultActiveFirstOption) return

    const firstValue = types[0]?.[dataValue]

    if (types.length === 1 && value !== firstValue) {
      onChange(firstValue)
    } else if (types.length > 1 && !value) {
      onChange(firstValue)
    }
  }, [value, JSON.stringify(types), defaultActiveFirstOption])

  useEffect(() => {
    if (isEqual(prevQueryParams.current, queryVariables)) return

    prevQueryParams.current = queryVariables

    getData({ variables: { ...params, ...queryVariables } })
  }, [queryVariables])

  useEffect(() => {
    let rows = data?.[type]?.rows || []
    if (!rows?.length) return

    rows = selectOptions?.length
      ? data?.[type].rows.filter((row: any) => selectOptions.includes(row[key]))
      : data?.[type].rows

    rows = filterFunc ? rows.filter(filterFunc) : rows

    setTypes(rows)
  }, [data, filterFunc, selectOptions])

  if (error) {
    //@ts-ignore
    if (error.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 404) {
      logger.error(`Dynamic Transport Select ${type} error`, error)
    }
  }

  const fetchOnFocus = useCallback(() => {
    if (queryOnMount) return

    getData()
  }, [queryOnMount])

  return (
    <Select
      id={`form-${type}-selector`}
      mode={mode}
      ref={ref}
      allowClear
      showSearch
      value={value}
      loading={loading}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      onChange={onChange}
      disabled={disabled}
      style={{ width: '100%', ...style }}
      filterOption={false}
      placeholder={searchText}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {types.map((type: any) => (
        <Select.Option key={type[key]} value={type[dataValue]}>
          {type[label]}
        </Select.Option>
      ))}
    </Select>
  )
})

export default withApollo(memo(DynamicTransportSelect))
