import { Form, Modal } from 'antd'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  width: 100%;

  .ant-input-number {
    width: 100%;
  }
`

export const StyledLegend = styled.legend`
  font-size: 15px !important;
  font-weight: 400;
  padding-bottom: 5px !important;
`

export const CostItemModal = styled(Modal)`
  top: 20px;

  .ant-modal-close-x {
    width: 47px;
    height: 47px;
    line-height: 47px;
  }

  .ant-modal-body {
  }
`

export const StyledRadio = {
  Wrapper: styled.div`
    text-align: right;

    .ant-radio-button-wrapper {
      font-size: 12.5px !important;
    }

    .fa-external-link-alt {
      cursor: pointer;
      color: rgb(150, 150, 150);
      font-size: 16px;
      margin-left: 10px;

      &:hover {
        color: #1890ff;
      }
    }
  `,
  Title: styled.span`
    font-size: 11px;
    font-weight: 600;
    padding-right: 3px;
    color: rgb(140, 140, 140);
    text-transform: uppercase;
  `
}
