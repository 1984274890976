import { EventEmitter } from 'events'
import createAuth0Client from '@auth0/auth0-spa-js'
import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import debounce from 'lodash/debounce'
import uuid from 'uuid'

import config from 'App/config'
import { logger } from './logger'
import { BASE_URL, IS_BROWSER } from './website'
import webStorage from './webStorage'
import useAuthStore from '@/store/auth'

export const LOCAL_STORAGE_KEYS = {
  // JWT related
  JWT: 'jwt',
  USER_TIMEOUT: 'userTimeout',
  SECRET: 'secret',
  PROFILE: 'profile',
  REFRESH_TOKEN_EXPIRY: 'refreshTokenExpiry',
  VIEWER_DATA: 'viewerData',
  REFRESH_TOKEN: 'refreshToken',

  LOGOUT: 'logout',

  // BaseCompany Related
  GLOBAL_COMPANIES: 'globalCompanies',
  BASE_COMPANY_TYPES: 'baseCompanyTypes',
  JOB_TYPES: 'jobTypes',
  BOOKING_TYPES: 'bookingTypes',
  SELECTED_GLOBAL_COMPANY: 'selectedGlobalCompany',

  // Booking
  BOOKING_OVERVIEW_EXPAND_ALL_TRIPS: 'expandAllTrips',
  ASSIGNEES: 'assignees',

  // Timeline
  TIMELINE_VEHICLE_DEPARTMENTS: 'TIMELINE_VEHICLE_DEPARTMENTS',

  // Planning
  SELECTED_DEPARTMENTS_PLANNING_TABLE: 'SELECTED_DEPARTMENTS_PLANNING_TABLE',
  SELECTED_DEPARTMENTS_PLANNING_VEHICLES_TABLE: 'SELECTED_DEPARTMENTS_PLANNING_VEHICLES_TABLE',
  PLANNING_JOBS_TABLE_PAGE_SIZE: 'PLANNING_JOBS_TABLE_PAGE_SIZE',
  PLANNING_SUMMARY_TABLE_TRANSPORT_SOURCES: 'PLANNING_SUMMARY_TABLE_TRANSPORT_SOURCES',
  PLANNING_SUMMARY_TABLE_COLLAPSIBLE_PANEL: 'PLANNING_SUMMARY_TABLE_COLLAPSIBLE_PANEL',
  PLANNING_VEHICLES_TABLE_PAGE_SIZE: 'PLANNING_VEHICLES_TABLE_PAGE_SIZE',
  UPDATE_COST_ITEMS_VIEWS: 'UPDATE_COST_ITEMS_VIEWS',
  EMPTY_TRIPS_TABLE_DEPARTMENTS: 'EMPTY_TRIPS_TABLE_DEPARTMENTS',
  UNPLANNED_SELECTED_VEHICLE_DEPARTMENTS: 'UNPLANNED_SELECTED_VEHICLE_DEPARTMENTS',
  UNPLANNED_LEGS_SELECTED_BOOKING_DEPARTMENTS: 'UNPLANNED_LEGS_SELECTED_BOOKING_DEPARTMENTS',

  // Empty Trip
  MT_MONITORING_DATE_SCALE_FILTER: 'MT_MONITORING_DATE_SCALE_FILTER',
  MT_MONITORING_TRIP_TYPE_FILTER: 'MT_MONITORING_TRIP_TYPE_FILTER',
  MT_MONITORING_ZONES: 'MT_MONITORING_ZONES',
  MT_MONITORING_GRAPH_TYPE: 'MT_MONITORING_GRAPH_TYPE',
  MT_MONITORING_H_GUTTER: 'MT_MONITORING_H_GUTTER',
  MT_MONITORING_V_GUTTER: 'MT_MONITORING_V_GUTTER',
  MT_MONITORING_COL_COUNT: 'MT_MONITORING_COL_COUNT',

  DOCUMENT_CREATOR_PDF_SERVICE: 'documentCreatorPdfService'
}

export const namespace = 'https://shipx.cc/user'

// Should be exactly the same as in api
const convertJwtData = jwtPayload => {
  let profile = { ...jwtPayload }
  const namespace = 'https://shipx.cc/user'

  if (profile[namespace]) {
    profile = { ...profile[namespace], ...profile }
    delete profile[namespace]
  }

  if (profile.app_metadata) {
    profile = { ...profile.app_metadata, ...profile }
    delete profile.app_metadata
  }

  if (profile.user) {
    profile = { ...profile.user, ...profile }
  }

  profile.userId = profile.user_id || profile.sub

  if (profile.isSA) {
    profile.isSuperAdmin = true
  }

  return profile
}

const getAuth0Client = async options => {
  const auth0 = await createAuth0Client({
    domain: config.auth0.domain,
    client_id: config.auth0.clientId,
    ...options
  })

  return auth0
}

class AuthService extends EventEmitter {
  constructor(clientId, domain) {
    super()
    this.domain = domain

    if (IS_BROWSER) {
      this.auth = new auth0.WebAuth({
        domain,
        clientID: clientId,
        scope: 'openid profile email offline_access',
        responseType: 'token id_token',
        redirectUri: `${BASE_URL}/auth/check-in`
      })
    }
  }

  login = async ({ email, password }) => {
    const secret = uuid.v4()
    this.setSecret(secret)

    const loginRequest = {
      realm: 'Username-Password-Authentication',
      email,
      password,
      state: secret
    }

    try {
      const result = await new Promise((resolve, reject) => {
        this.auth.login(loginRequest, (error, result) => {
          if (error) return reject(error)
          resolve(result)
        })
      })
      return result
    } catch (error) {
      return error
    }
  }

  shouldLogOut = () => {
    const refreshTokenExpiry = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY)
    const hasExpired = Date.now() > new Date(refreshTokenExpiry).getTime()
    return hasExpired
  }

  useRefreshToken = debounce(async options => {
    if (window.location.href.includes('/activate/account')) return

    const refreshToken = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
    if (!refreshToken || this.shouldLogOut()) {
      this.logout()
      // redirect to home instead of window.location.reload(), otherwise infinite reload onActivate
      window.location.href = '/'
      return
    }

    const input = {
      grant_type: 'refresh_token',
      client_id: config.auth0.clientId,
      scope: 'openid profile email offline_access',
      refresh_token: refreshToken,
      response_type: 'token id_token',
      detailedResponse: true
    }

    try {
      const auth0Client = await getAuth0Client({
        scope: 'openid profile email offline_access',
        responseType: 'token id_token',
        useRefreshTokens: true
      })
      const newTokens = await auth0Client.getTokenSilently(input)

      if (!newTokens?.id_token) {
        this.logout()
        window.location.href = '/'
        return
      }

      this.setToken(newTokens.id_token)

      if (options?.shouldReload) {
        const currentPage = window.location.href
        window.location.href = currentPage
      }

      return newTokens.id_token
    } catch (error) {
      logger.error('useRefreshToken Error: ', error, input)
      this.logout()
      window.location.href = '/'
    }
  }, 500)

  logout = () => {
    localStorage.clear()
    webStorage.setItem(LOCAL_STORAGE_KEYS.LOGOUT, Date.now())
  }

  sendChangePasswordRequest = email => {
    return new Promise((resolve, reject) => {
      const requestBody = {
        email,
        connection: 'Username-Password-Authentication'
      }
      this.auth.changePassword(requestBody, (error, result) => {
        if (error) return reject(error)
        resolve(result)
      })
    })
  }

  digestHashes = (hashedProps, history) => {
    try {
      if (this.hasLoginError(hashedProps)) {
        history.push('/')
      }

      this.setToken(hashedProps.id_token)
      if (hashedProps.refresh_token) {
        this.setRefreshToken(hashedProps.refresh_token)
      }

      this.auth.client.userInfo(hashedProps.access_token, error => {
        if (error) return

        // this.setProfile(user) // this one has less info than jwtDecode(idToken) in this.setToken(), e.g. exp

        // if (user && user.companies) {
        //   webStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_GLOBAL_COMPANY, user.companies[0])
        // }

        // TODO: redirect base on previous url, retreive from url
        history.push('/')
      })
    } catch (error) {
      logger.error('auth.digestHashes Error: ', error, hashedProps)
    }
  }

  // JWT
  setToken = idToken => {
    webStorage.setItem(LOCAL_STORAGE_KEYS.JWT, idToken)
    this.setProfile(jwtDecode(idToken))
  }

  // Refresh Token
  setRefreshToken = refreshToken => {
    webStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)
    webStorage.setItem(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY,
      new Date(Date.now() + config.auth0.logOutAfterDays * 24 * 60 * 60 * 1000)
    )
  }

  getToken(headers) {
    return webStorage.getItem(LOCAL_STORAGE_KEYS.JWT, headers)
  }

  // Profile
  setProfile = profile => {
    webStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, profile)
    // Saves profile data to localStorage
    this.emit('profile_updated', profile)
  }

  getProfile(headers) {
    const profile = webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE, headers)

    if (!profile) {
      return {}
    }

    return convertJwtData(profile)
  }

  // Secret
  checkSecret(secret) {
    return webStorage.getItem(LOCAL_STORAGE_KEYS.SECRET) === secret
  }

  setSecret(secret) {
    return webStorage.setItem(LOCAL_STORAGE_KEYS.SECRET, secret)
  }

  hasLoginError = hashedProps => {
    if (!hashedProps) {
      return false
    }

    const errorMessages = [
      'invalid_request',
      'The generated token is too large. Try with more specific scopes'
    ]

    for (const key in hashedProps) {
      const foundErr = errorMessages.find(err =>
        hashedProps[key]?.toString()?.toLowerCase()?.includes(err.toLowerCase())
      )
      if (foundErr) {
        return true
      }
    }

    return false
  }

  hasLoggedOutError = errorObj => {
    if (!errorObj) {
      return false
    }

    const errorMessages = ['Token has expired', 'No User found to check isAuthenticated']

    for (let i = 0; i < errorMessages.length; i++) {
      const foundErr = errorObj.graphQLErrors?.find(err =>
        err.message?.toLowerCase()?.includes(errorMessages[i].toLowerCase())
      )
      if (foundErr) {
        return true
      }
    }

    return false
  }

  jwtIsExpiring = () => {
    const decodedJWT = webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)
    if (!decodedJWT?.exp) {
      return true
    }
    const expiryMs = decodedJWT.exp * 1000
    const isExpiringIn10Mins = expiryMs && expiryMs - Date.now() <= 600000
    return isExpiringIn10Mins
  }

  handleLoggedOutError = errorObj => {
    if (!errorObj) {
      return
    }

    // if check hasLoggedOutError() too, then this may trigger an infinite loop of refreshingToken
    if (this.jwtIsExpiring()) {
      return useAuthStore.getState().triggerRefreshToken()
    }
  }
}

export default new AuthService(config.auth0.clientId, config.auth0.domain)
