import { memo } from 'react'
import { Spin } from 'antd'

const Fallback = () => {
  return (
    <div
      style={{
        flex: 1,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin />
    </div>
  )
}

export default memo(Fallback)
