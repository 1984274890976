import { withRouter } from 'react-router-dom'
import { Button } from 'antd'
import { compose, withHandlers } from 'recompose'

const handlers = withHandlers({
  handleClick: props => () => {
    const { history } = props

    if (history.length > 1) {
      history.go(-1)
    } else {
      history.push('/')
    }
  }
})

const enhance = compose(withRouter, handlers)

const style = {
  border: 'none'
}

const BackButton = props => {
  const { handleClick } = props

  return <Button icon="arrow-left" style={style} onClick={handleClick} />
}

export default enhance(BackButton)
