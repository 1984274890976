import { useCallback, useMemo } from 'react'
import { Button } from 'antd'

interface Props {
  isPortalUser?: any
}
export const getColumns = ({ isPortalUser = false }: Props = {}) => {
  const columns = [
    {
      Header: 'Job No',
      accessor: 'jobNo'
    },
    {
      Header: 'Job Type',
      accessor: 'jobType'
    },
    {
      Header: 'Trip Index',
      accessor: 'tripSequence'
    },
    {
      Header: 'Leg Index',
      accessor: 'sequence'
    },
    {
      Header: 'Trip Type',
      accessor: 'tripType'
    },
    {
      Header: 'Trip Status',
      accessor: 'tripStatus'
    },
    {
      Header: 'Leg Status',
      accessor: 'legStatus'
    },
    {
      Header: 'PM',
      accessor: 'PM',
      ...(isPortalUser
        ? {
            style: { maxWidth: 400 },
            Cell: (cellValue: any) => {
              const leg = useMemo(
                () => cellValue.data[parseInt(cellValue.row.id)],
                [cellValue.data, cellValue.row.id]
              )
              const hasDriverVehicle =
                leg?.driverUuid && leg?.driverCode && leg?.vehicleUuid && leg?.vehicleCode

              const openVDJModal = useCallback(() => {
                window.open(
                  `${window.location.origin}/transport/operation?dr=${leg?.driverCode}&vh=${leg?.vehicleCode}&activityStartDate=${leg?.VDJMStartDate}&activityEndDate=${leg?.VDJMEndDate}`,
                  'vehicleDriverJobModal'
                )
              }, [leg])

              return (
                <Button
                  disabled={!hasDriverVehicle}
                  type="link"
                  style={{
                    padding: 0,
                    width: '100%',
                    display: 'block',
                    fontSize: '10px',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                  onClick={openVDJModal}
                >
                  {cellValue.value}
                </Button>
              )
            }
          }
        : {})
    },
    {
      Header: 'DR',
      accessor: 'DR',
      ...(isPortalUser
        ? {
            style: { maxWidth: 400 },
            Cell: (cellValue: any) => {
              const leg = useMemo(
                () => cellValue.data[parseInt(cellValue.row.id)],
                [cellValue.data, cellValue.row.id]
              )
              const hasDriverVehicle =
                leg?.driverUuid && leg?.driverCode && leg?.vehicleUuid && leg?.vehicleCode

              const openVDJModal = useCallback(() => {
                window.open(
                  `${window.location.origin}/transport/operation?dr=${leg?.driverCode}&vh=${leg?.vehicleCode}&activityStartDate=${leg?.VDJMStartDate}&activityEndDate=${leg?.VDJMEndDate}`,
                  'vehicleDriverJobModal'
                )
              }, [leg])

              return (
                <Button
                  disabled={!hasDriverVehicle}
                  type="link"
                  style={{
                    padding: 0,
                    display: 'block',
                    width: '100%',
                    fontSize: '10px',
                    textAlign: 'left',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  onClick={openVDJModal}
                >
                  {cellValue.value}
                </Button>
              )
            }
          }
        : {})
    },
    {
      Header: 'Ship Req',
      accessor: 'shipperRequiredDate',
      disableFilters: true
    },
    {
      Header: 'Plan Start',
      accessor: 'planStart'
    },
    {
      Header: 'Start',
      accessor: 'start'
    },
    {
      Header: 'Start Out',
      accessor: 'startOut'
    },
    {
      Header: 'Plan End',
      accessor: 'planEnd'
    },
    {
      Header: 'End',
      accessor: 'end'
    },
    {
      Header: 'End Out',
      accessor: 'endOut'
    },
    {
      Header: 'Transport Source',
      accessor: 'transportSource'
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      style: { maxWidth: 300 }
    }
  ]

  return columns
}
