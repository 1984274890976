import { graphql } from 'react-apollo'
import { Checkbox, Col, Form, Input, Row, Select } from 'antd'
import flatten from 'lodash/flatten'
import uniq from 'lodash/uniq'
import moment from 'moment'
import { compose } from 'recompose'

import CompanyLabel from 'App/components/Company/CompanyLabel'
import DateTimePicker from 'App/components/Form/DateTimePicker'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import useGlobalCompanyStore from 'App/store/globalCompany'
import AttachmentsSection from './AttachmentsSection'
import { SectionHeader } from './Container'
import DeliveryCollection from './DeliveryCollection'
import JobsSelectTable from './JobsSelectTable'

const bookingQuery = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  name: 'bookingQuery',
  options: props => ({
    variables: {
      uuid: props.documentCreator.bookingUuid
    }
  })
})

const enhance = compose(bookingQuery)

const FormItem = Form.Item
const Option = Select.Option
const CheckboxGroup = Checkbox.Group

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const TransportSubmitForm = props => {
  const {
    bookingQuery: { booking },
    documentCreator,
    form
  } = props
  const { getFieldDecorator, getFieldValue } = form

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  if (!booking) {
    return null
  }

  const forwarder = booking.forwarderOri || booking.forwarderDest || {}
  const forwarderRef =
    booking.type === 'IMPORT' ? booking.details.forwarderDestRef : booking.details.forwarderOriRef
  const bookingRef = booking.type === 'IMPORT' ? booking.details.bl : booking.details.shippingRef
  const liner = booking.linerOri || booking.linerDest || {}
  const eta = booking.chronologies.find(c => c.type === 'linerEstimatedTimeOfArrival') || {}
  const etd = booking.chronologies.find(c => c.type === 'linerEstimatedTimeOfDeparture') || {}
  const storageDate = booking.chronologies.find(c => c.type === 'impLastPortStorage') || {}
  const selectableDocuments = booking.bookingDocuments.filter(d => {
    const documentTypes =
      booking.type === 'IMPORT'
        ? ['K1Draft', 'finalCustomsDocument', 'impCustCustomsDocumentDraft', 'ImpGatepassDoc']
        : ['ExpLinerBookingConfirmation', 'ExpContainerMovementOrder']

    return d.status === 'VERIFIED' && documentTypes.includes(d.type)
  })

  const initialAutoSelectedDocuments = selectableDocuments.filter(d => {
    const documentTypes =
      booking.type === 'IMPORT'
        ? ['K1Draft', 'finalCustomsDocument', 'ImpGatepassDoc']
        : ['ExpLinerBookingConfirmation', 'ExpContainerMovementOrder']

    return documentTypes.includes(d.type)
  })

  const selectedJobUuids = getFieldValue('data.jobs') || []
  const selectedJobOptions = booking.jobs
    .filter(j => selectedJobUuids.includes(j.uuid))
    .map(j => j.details.options || [])
  const rotOptions = uniq(flatten(selectedJobOptions))

  return (
    <Form>
      {getFieldDecorator('uuid', {
        initialValue: documentCreator.uuid
      })(<Input type="hidden" />)}
      {getFieldDecorator('bookingUuid', {
        initialValue: documentCreator.bookingUuid
      })(<Input type="hidden" />)}
      {getFieldDecorator('status', {
        initialValue: documentCreator.status
      })(<Input type="hidden" />)}
      {getFieldDecorator('type', {
        initialValue: 'ROT'
      })(<Input type="hidden" />)}
      <div>
        <SectionHeader>Details</SectionHeader>
        <Row>
          <Col span={12}>
            <FormItem label="ROT No." {...formItemLayout}>
              {documentCreator.data.requestNo || '-'}
            </FormItem>
            <FormItem label="Booking Ref" {...formItemLayout}>
              {bookingRef || '-'}
            </FormItem>
            <FormItem label="Type" {...formItemLayout}>
              {booking.type === 'IMPORT' ? 'Import' : 'Export'}
            </FormItem>
            <FormItem label="Shipment" {...formItemLayout}>
              {booking.details.shipmentType}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Required Date" {...formItemLayout}>
              {getFieldDecorator('data.requiredDate', {
                initialValue: moment(documentCreator.data.requiredDate),
                rules: [
                  {
                    required: true,
                    message: 'Required Date is required.'
                  }
                ]
              })(<DateTimePicker />)}
            </FormItem>
            {booking.details.shipmentType === 'FCL' && (
              <FormItem label="Delivery Hour" {...formItemLayout}>
                {getFieldDecorator('data.deliveryHour', {
                  initialValue: documentCreator.data.deliveryHour || '24Hour',
                  rules: [
                    {
                      required: true,
                      message: 'Delivery Hour is required.'
                    }
                  ]
                })(
                  <Select>
                    <Option value="officeHour">Office hour</Option>
                    <Option value="24Hour">24 hour</Option>
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem label="Customer Ref" {...formItemLayout}>
              {getFieldDecorator('data.customerRef', {
                initialValue: documentCreator.data.customerRef || forwarderRef
              })(<Input />)}
            </FormItem>
            <FormItem label="Cust. Ref 2/SO #" {...formItemLayout}>
              {getFieldDecorator('data.customerRef2', {
                initialValue: documentCreator.data.customerRef2
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
      </div>
      <div>
        <SectionHeader>Ownership</SectionHeader>
        <Row>
          <Col span={12}>
            {booking.type === 'EXPORT' && (
              <FormItem label="Shipper" {...formItemLayout}>
                <CompanyLabel company={booking.shipper} />
              </FormItem>
            )}
            {booking.type === 'IMPORT' && (
              <FormItem label="Consignee" {...formItemLayout}>
                <CompanyLabel company={booking.consignee} />
              </FormItem>
            )}
            <FormItem label="Forwarder" {...formItemLayout}>
              <CompanyLabel company={forwarder} />
            </FormItem>
            <FormItem label="Liner" {...formItemLayout}>
              <CompanyLabel company={liner} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label="Haulier" {...formItemLayout}>
              {getFieldDecorator('data.haulierUuid', {
                initialValue: documentCreator.data.haulier && documentCreator.data.haulier.uuid,
                rules: [
                  {
                    required: true,
                    message: 'Haulier is required.'
                  }
                ]
              })(
                <CompanySelect
                  types={['haulier']}
                  countryAlpha3="MYS"
                  name={documentCreator.data.haulier && documentCreator.data.haulier.name}
                />
              )}
            </FormItem>
            <FormItem label="Billing customer" {...formItemLayout}>
              {getFieldDecorator('data.billToUuid', {
                initialValue:
                  documentCreator.data.billToCompany && documentCreator.data.billToCompany.uuid,
                rules: [
                  {
                    required: true,
                    message: 'Billing customer is required.'
                  }
                ]
              })(<Input type="hidden" />)}
              <CompanyLabel company={selectedGlobalCompany.company} />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div>
        <SectionHeader>Delivery / Collection</SectionHeader>
        <DeliveryCollection booking={booking} documentCreator={documentCreator} form={form} />
      </div>
      <div>
        <SectionHeader>Vessel Information</SectionHeader>
        <Row>
          <Col span={12}>
            <FormItem label="Vessel Name" {...formItemLayout}>
              {booking.details.vesselName}
            </FormItem>
            <FormItem label="Voyage No" {...formItemLayout}>
              {booking.details.voyageNo}
            </FormItem>
            <FormItem label="Operator Code" {...formItemLayout}>
              {booking.type === 'EXPORT'
                ? booking.details.operatorOri
                : booking.details.operatorDest}
            </FormItem>
            <FormItem label="Commodity" {...formItemLayout}>
              {booking.commodities}
            </FormItem>
          </Col>
          <Col span={12}>
            {booking.type === 'IMPORT' && (
              <FormItem label="ETA" {...formItemLayout}>
                {moment(eta.date).format('DD/MM/YYYY HH:mm')}
              </FormItem>
            )}
            {booking.type === 'EXPORT' && (
              <FormItem label="ETD" {...formItemLayout}>
                {moment(etd.date).format('DD/MM/YYYY HH:mm')}
              </FormItem>
            )}
            <FormItem label="Last Port Storage Date" {...formItemLayout}>
              {moment(storageDate.date).format('DD/MM/YYYY HH:mm')}
            </FormItem>
            <FormItem label="Discharge Port" {...formItemLayout}>
              {booking.portDest && booking.portDest.name}
            </FormItem>
          </Col>
        </Row>
      </div>
      <div>
        <SectionHeader>Jobs</SectionHeader>
        <FormItem>
          {getFieldDecorator('data.jobs', {
            initialValue:
              (documentCreator.data.jobs && documentCreator.data.jobs.map(j => j.uuid)) || [],
            rules: [
              {
                required: true,
                message: 'At least one job is required.'
              }
            ]
          })(<JobsSelectTable jobs={booking.jobs} />)}
        </FormItem>
      </div>
      <div>
        {getFieldDecorator('data.documents', {
          initialValue:
            documentCreator.data.documents || initialAutoSelectedDocuments.map(d => d.uuid)
        })(
          <AttachmentsSection
            selectable={['NEW', 'DRAFT'].includes(documentCreator.status)}
            removable={['NEW', 'DRAFT'].includes(documentCreator.status)}
            selectableDocuments={selectableDocuments}
          />
        )}
      </div>
      <div>
        <SectionHeader>Additional Info</SectionHeader>
        <FormItem label="Options" labelCol={{ span: 3 }} wrapperCol={{ span: 6 }}>
          {getFieldDecorator('data.options', {
            initialValue: documentCreator.data.options || rotOptions
          })(
            <CheckboxGroup
              options={[
                'BACK_TO_BACK',
                'DIRECT_LOADING',
                'DIRECT_DELIVERY',
                'CUSTOMS_SEAL',
                'UNCOUPLE_TRAILER'
              ]}
            />
          )}
        </FormItem>
        <FormItem label="Remarks" labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          {getFieldDecorator('data.remarks', {
            initialValue: documentCreator.data.remarks
          })(<Input />)}
        </FormItem>
      </div>
    </Form>
  )
}

export default enhance(TransportSubmitForm)
