import * as download from 'downloadjs'
import { json2csv } from 'json-2-csv'
import { cloneDeep, groupBy, intersection, isEmpty, uniq } from 'lodash'
import moment from 'moment'

import { formatJob, parseTableCellValue } from '@/components/Transport/Components/Monitoring/JobStatus/getTableAccessors'
import { GroupingMethod } from 'App/components/Transport/Components/Monitoring/JobStatus/maps'
import { TRANSPORT_REPORT_JOBS_JSON } from 'App/components/Transport/Schemas/schema'
import { json2xlsx } from 'App/components/Transport/Shift/Actions/Export/exportHelper'
import respHandler from 'App/utils/responseHandler'
import { logger } from 'App/utils/logger'
import {
  getIncentiveTypes
} from 'App/components/Transport/Utils/jobHelper'
import { TransportReportJob } from 'App/types/graphql'
import { Incentive } from 'App/types/graphql'
import { getGqlResponse } from 'App/utils/importExport/helpers'


type FileDateType = {
  start: string
  end: string
}

let fileDate: FileDateType
const exportType: string = 'All'

const csv2jsonDownload = (err: any, csv: string) => {
  if (err) {
    logger.error('Export IncentiveVouchers csv2jsoDownload err', err)
    return respHandler(err, 'error')
  }

  try {
    const blob = new Blob([csv], { type: 'text/csv' })
    const fileName = `Jobs_Status_${exportType}_from_${fileDate.start}_to_${fileDate.end}.csv`
    // @ts-ignore
    download(blob, fileName)
    respHandler('Successfully exported file.', 'success')
  } catch (error) {
    logger.error('Export IncentiveVouchers csv2jsonDownload error', error)
    return respHandler(error, 'error')
  }
}

export const replaceNullUndefinedWithEmptyString = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      obj[key] = ''
    } else if (typeof obj[key] === 'object') {
      replaceNullUndefinedWithEmptyString(obj[key])
    }
  }
}

const formatJobs = (
  selectedGlobalCompany: any,
  jobsData: TransportReportJob[],
  jobsIncrement: number,
  includeIncentives: boolean = false,
  isFiltered: boolean = false,
  columnsToHide: string[] = [],
  columnsOrder: any[] = []
) => {
  const countryCode = selectedGlobalCompany?.country?.alpha2
  const currencyCode = selectedGlobalCompany?.company?.currency?.code

  const formattedJobs: any = []

  for (let i = 0; i < jobsData?.length; i++) {
    const job = jobsData[i]

    let formatted = {
      ...formatJob(job, countryCode, currencyCode, true, true),
      ...(includeIncentives ? { ...getIncentiveTypes(job) } : {}),
      index: i + 1 + jobsIncrement,
    }

    const o = {}
    const cols = columnsOrder.filter((co) => isFiltered ? !columnsToHide.includes(co.id || co.accessor) : true)

    cols.forEach(({ Header, accessor, id, fnc, options }) => {
      o[Header] = parseTableCellValue(formatted, id || accessor, fnc, options)
    })
    formatted = o

    replaceNullUndefinedWithEmptyString(formatted)

    formattedJobs.push(formatted)
  }

  return formattedJobs
}

export const getTransportReportJobs = async ({
  selectedGlobalCompany,
  input,
  isFiltered,
  columnsToHide,
  columnsOrder,
  includeIncentives,
  jobsIncrement = 0
}) => {
  const jobsRawData = await getGqlResponse(selectedGlobalCompany, TRANSPORT_REPORT_JOBS_JSON, {
    input
  })

  const jobs =
    jobsRawData?.data?.transportReportJobsJson?.rows &&
    cloneDeep(jobsRawData?.data?.transportReportJobsJson?.rows)

  const jobsExcelData =
    formatJobs(selectedGlobalCompany, jobs, jobsIncrement, includeIncentives, isFiltered, columnsToHide, columnsOrder)

  return { jobsRawData, jobs, jobsExcelData }
}

export const getFirstObjWithPopulatedHeaders = (
  incentiveTypeHeaders: string[],
  totalJobsToExport: any[],
) => {
  const newFirstRow = new Map()

  for (const [key, value] of Object.entries(totalJobsToExport[0])) {
    newFirstRow.set(key, value)
  }

  // set at last columns
  incentiveTypeHeaders.forEach(header =>
    newFirstRow.set(header, totalJobsToExport[0][header] || '')
  )

  return newFirstRow.size ? Object.fromEntries(newFirstRow) : totalJobsToExport[0]
}

export const getGroupingLookupKey = (method: GroupingMethod, key: string) => `${method} of ${key}`

export const jobStatusExportHelper = async (
  selectedGlobalCompany: any,
  columnsPrefData: any,
  columnsToHide: any,
  queryObj: any,
  setIsLoading: any,
  fileFormat: string,
  isFiltered: boolean = false,
  groupByFields: string[] = [],
  groupingMethods: GroupingMethod[]
) => {
  try {
    setIsLoading(true)
    respHandler('Loading...', 'load')

    fileDate = {
      start: queryObj?.shipperRequiredDateStart
        ? moment(queryObj?.shipperRequiredDateStart).format('DD-MM-YYYY')
        : 'beginning-of-time',
      end: queryObj?.shipperRequiredDateEnd
        ? moment(queryObj?.shipperRequiredDateEnd).format('DD-MM-YYYY')
        : moment().format('DD-MM-YYYY')
    }

    const querySize: number = 500
    let totalJobsToExport: any = []
    let totalJobsData: any = []
    const incentiveRelatedColumns = [
      'incentiveVoucherNo',
      'incentiveVoucherAmount',
      'incentiveVoucherStatus',
      'incentiveVoucherDate'
    ]

    const includeIncentives = !!(
      intersection(columnsToHide, incentiveRelatedColumns).length !==
      incentiveRelatedColumns.length &&
      groupByFields.filter(Boolean).length &&
      groupingMethods.filter(Boolean).length
    )

    let totalRequest = 1

    for (let i = 0; i < totalRequest; i++) {
      const jobsIncrement = i * querySize

      respHandler(`Fetching data ${Math.floor((i / totalRequest) * 100)}%...`, 'load')

      const queryJobsInput = { ...queryObj, includeIncentives, limit: querySize, offset: i }

      const { jobsExcelData, jobsRawData, jobs } = await getTransportReportJobs({
        selectedGlobalCompany,
        input: queryJobsInput,
        isFiltered,
        columnsToHide,
        columnsOrder: columnsPrefData,
        includeIncentives,
        jobsIncrement
      })

      if (!jobsExcelData?.length) {
        setIsLoading(false)
        return respHandler('No data to export.', 'warning')
      }

      if (i === 0) {
        totalRequest = Math.ceil(
          jobsRawData?.data?.transportReportJobsJson?.pageInfo?.count / querySize
        )
      }

      totalJobsData = totalJobsData.concat(jobs)
      totalJobsToExport = totalJobsToExport.concat(jobsExcelData)
    }

    // ensure first row has all headers for incentive types
    if (includeIncentives) {
      const incentiveTypeHeaders: string[] = uniq(
        totalJobsData?.flatMap(job =>
          job.incentives?.map(
            (iv: Incentive) => `${iv.type?.code}[${iv.type?.name}]` || iv.typeUuid
          )
        )
      )

      totalJobsToExport[0] = getFirstObjWithPopulatedHeaders(
        incentiveTypeHeaders,
        totalJobsToExport
      )

      if (groupByFields?.length && groupingMethods?.length) {
        respHandler('Grouping data...', 'load')
        const groupedJobs = groupBy(totalJobsToExport, job =>
          groupByFields.map(f => job[f]).join('-')
        )
        const computeMethods = Object.values(GroupingMethod)
          .filter(f => groupingMethods.includes(f))
          .sort((a, b) => b.localeCompare(a)) // ensure AVERAGE is last
        const NotIncludeLatestValue = !groupingMethods.includes(GroupingMethod.LATEST_VALUE)

        totalJobsToExport = Object.values(groupedJobs).flatMap((jobs, parentIndex) => {
          const incentiveTypeGroupingMethods = jobs.reduce((acc, job) => {
            const headerKeys = Object.keys(job)

            headerKeys.forEach(key => {
              if (incentiveTypeHeaders.includes(key)) {
                computeMethods.forEach(method => {
                  const lookupKey = getGroupingLookupKey(method, key)
                  const lookupValue = Number(job[key])
                  const previousValue = Number(acc[lookupKey]) || 0

                  switch (method) {
                    case GroupingMethod.COUNT: {
                      acc[lookupKey] = previousValue + (lookupValue ? 1 : 0)
                      break
                    }

                    case GroupingMethod.SUM: {
                      acc[lookupKey] = previousValue + lookupValue
                      break
                    }

                    case GroupingMethod.AVERAGE: {
                      acc[lookupKey] =
                        acc[getGroupingLookupKey(GroupingMethod.SUM, key)] /
                        acc[getGroupingLookupKey(GroupingMethod.COUNT, key)]
                      break
                    }

                    case GroupingMethod.LATEST_VALUE: {
                      acc[lookupKey] = job[lookupKey]
                      delete job[lookupKey]
                      break
                    }
                  }
                })

                if (NotIncludeLatestValue) {
                  delete job[getGroupingLookupKey(GroupingMethod.LATEST_VALUE, key)]
                }
              }
            })
            return acc
          }, {})

          const groupIndex = parentIndex + 1

          jobs.forEach((job, i) => {
            Object.assign(job, incentiveTypeGroupingMethods, {
              index: groupIndex + (i + 1) / 1000
            })
          })

          return jobs
        })
      }
    }

    if (!totalJobsToExport.filter((v) => !isEmpty(v)).length) {
      return respHandler('No data to export.', 'warning')
    }

    if (fileFormat === 'xlsx') {
      json2xlsx('Jobs_Status', totalJobsToExport, exportType, fileDate)
    } else if (fileFormat === 'csv') {
      // @ts-ignore
      json2csv(totalJobsToExport, csv2jsonDownload)
    } else {
      return respHandler('Please select either .xlsx or .csv format.', 'error')
    }

    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
    logger.error('jobStatusExportHelper error', error)
    return respHandler(error, 'error')
  }
}
