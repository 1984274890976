import { graphql } from 'react-apollo'
import { compose } from 'recompose'

import companyGql from './companyGql'

const companyGraphql = graphql(companyGql, {
  name: 'companyGraphql',
  skip: props => {
    return !props.companyUuid
  },
  options: props => ({
    variables: {
      uuid: props.companyUuid
    }
  })
})

const enhance = compose(companyGraphql)

const Company = props => {
  const { companyUuid = '', companyGraphql = {} } = props
  const { loading, error, company } = companyGraphql

  if (!companyUuid) {
    return <span>Company is not selected.</span>
  }

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return <span>Error while loading company.</span>
  }

  if (!company) {
    return <span>Error: Company not found.</span>
  }

  return <span>{company.name}</span>
}

export default enhance(Company)
