export default {
  Container: {
    CharLookup: {
      A: 10,
      B: 12,
      C: 13,
      D: 14,
      E: 15,
      F: 16,
      G: 17,
      H: 18,
      I: 19,
      J: 20,
      K: 21,
      L: 23,
      M: 24,
      N: 25,
      O: 26,
      P: 27,
      Q: 28,
      R: 29,
      S: 30,
      T: 31,
      U: 32,
      V: 34,
      W: 35,
      X: 36,
      Y: 37,
      Z: 38
    }
  },
  DocumentCreatorTemplate: {
    Type: {
      CONSIGNMENT_NOTE: 'CONSIGNMENT_NOTE',
      CONSOLE_INVOICE: 'CONSOLE_INVOICE',
      INCENTIVE_VOUCHER: 'INCENTIVE_VOUCHER',
      INVOICE: 'INVOICE',
      MANIFEST: 'MANIFEST',
      WAYBILL_SLIP: 'WAYBILL_SLIP',
      WORKSHEET: 'WORKSHEET'
    }
  },
  Incentive: {
    Category: {
      INCENTIVE: 'INCENTIVE',
      EXPENSES: 'EXPENSES'
    }
  },
  Job: {
    Type: {
      LADEN: 'LADEN',
      EMPTY: 'EMPTY'
    }
  },
  UserPreference: {
    Type: {
      JOBS_STATUS_TABLE: 'JOBS_STATUS_TABLE',
      NEWBOOKING: 'NEWBOOKING',
      PLANNING_JOBS_TABLE: 'PLANNING_JOBS_TABLE',
      SETTINGS: 'SETTINGS'
    }
  },
  Vehicle: {
    Display: {
      both: 'both',
      code: 'code',
      key: 'VEHICLE_CODE_REGISTRATION',
      registration: 'registration'
    }
  }
}
