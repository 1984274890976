export const actions = {
  UPDATE_WARNINGS: 'UPDATE_WARNINGS',
  UPDATE_CURRENT_STEP: 'UPDATE_CURRENT_STEP',
  UPDATE_NEW_BOOKING: 'UPDATE_NEW_BOOKING',
  TOGGLE_SUBMIT_STATUS: 'TOGGLE_SUBMIT_STATUS',
  TOGGLE_OVERRIDE_REQUIRED: 'TOGGLE_OVERRIDE_REQUIRED'
}

export const toggleOverrideRequired = () => ({
  type: actions.TOGGLE_OVERRIDE_REQUIRED
})

export const updateWarnings = warnings => ({
  type: actions.UPDATE_WARNINGS,
  warnings
})

export const updateCurrentStep = step => ({
  type: actions.UPDATE_CURRENT_STEP,
  step
})

export const updateNewBooking = newBooking => ({
  type: actions.UPDATE_NEW_BOOKING,
  newBooking
})

export const toggleSubmitStatus = () => ({
  type: actions.TOGGLE_SUBMIT_STATUS
})

const initialState = {
  warnings: [],
  currentStep: 0,
  newBooking: {},
  overrideRequired: false,
  isSubmitting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_WARNINGS:
      return { ...state, warnings: action.warnings }
    case actions.UPDATE_CURRENT_STEP:
      return { ...state, currentStep: action.step }
    case actions.UPDATE_NEW_BOOKING:
      return { ...state, newBooking: action.newBooking }
    case actions.TOGGLE_SUBMIT_STATUS:
      return { ...state, isSubmitting: !state.isSubmitting }
    case actions.TOGGLE_OVERRIDE_REQUIRED:
      return { ...state, overrideRequired: !state.overrideRequired }
    default:
      return state
  }
}
