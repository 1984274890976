import ResourceViewer from 'App/components/ResourceViewer'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'
import ActivitiesView from './Activities'
import { ActivityPanel, DocumentView, PreviewWrapper, StyledContent, Wrapper } from './Styled'

function ViewerContent({ bookingDocument = {}, bookingQuery, refreshBooking }) {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const fileType = bookingDocument?.document?.type
  const url = bookingDocument?.url

  return (
    <Wrapper>
      <StyledContent>
        <DocumentView>
          <PreviewWrapper type={fileType}>
            <ResourceViewer
              url={url}
              mimeType={fileType}
              fetchOptions={{
                method: 'GET',
                headers: {
                  authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
                  'base-company-uuid': selectedGlobalCompany.uuid
                }
              }}
            />
          </PreviewWrapper>
        </DocumentView>
        <ActivityPanel>
          <ActivitiesView
            bookingQuery={bookingQuery}
            bookingDocument={bookingDocument}
            toggleDocumentViewer={this?.props?.toggleDocumentViewer}
            refreshBooking={refreshBooking}
          />
        </ActivityPanel>
      </StyledContent>
    </Wrapper>
  )
}

export default ViewerContent
