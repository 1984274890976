import { Button, Col, Divider, Form } from 'antd'

import { EditAction, StyledEditableRow } from '../Styled'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
}

const EditableForm = props => {
  const { fields, getFieldDecorator, onCancel, handleSubmit } = props

  return (
    <Form style={{ maxHeight: '420px', overflowX: 'hidden', overflowY: 'auto' }}>
      {fields.map((field, rowIndex) => {
        return (
          <StyledEditableRow key={rowIndex}>
            {field.useDivider && <Divider>{field.title}</Divider>}
            {field.cols.map((col, colIndex) => {
              return (
                <Col key={colIndex} span={24}>
                  <FormItem label={col.label} {...formItemLayout}>
                    {col.nonSubmitField
                      ? col.input
                      : getFieldDecorator(col.value, col.attributes)(col.input)}
                  </FormItem>
                </Col>
              )
            })}
          </StyledEditableRow>
        )
      })}
      <EditAction>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit}>
          Update
        </Button>
      </EditAction>
    </Form>
  )
}

export default EditableForm
