import { useCallback, useState } from 'react'
import { Col, Dropdown, Icon, Input, Menu, Row } from 'antd'
import { compose } from 'recompose'
import styled from 'styled-components'

import UserQuery from 'App/containers/user/query/user'
import { Booking } from 'App/types/graphql'
import BookingRow from './BookingRow'
import { exportCsv } from './helpers/ExportCsv'

const enhance = compose(UserQuery)

const StyledAnchor = styled.span`
  color: rgba(0, 0, 0, 0.65);
`

const BulkInvoiceGenerator = (props: any) => {
  const { onSelectSingleCostItem, onCostItemAdded } = props

  const [referenceNums, setReferenceNums] = useState<Array<string>>([])

  const [bookings, setBookings] = useState<Map<number, Booking>>(new Map())

  const onSearchReferences = (value: string) => {
    if (!value) return

    setReferenceNums(value.trim().split(/[ ,]+/))
  }

  const addBookingRow = (booking: Booking, index: number) => {
    if (!booking.no) return

    const existingBookings = bookings

    existingBookings.set(index, booking)

    setBookings(existingBookings)
  }

  const triggerCsvExport = () => exportCsv(bookings)

  const dropdownMenu = (
    <Menu>
      <Menu.Item onClick={triggerCsvExport}>
        <div style={{ cursor: 'pointer' }}>
          <StyledAnchor>Copy Values To CSV</StyledAnchor>
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Row>
        <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input.Search
            placeholder="Comma/Tab Seperated Booking References"
            onSearch={onSearchReferences}
            loading={false}
          />
          <Dropdown overlay={dropdownMenu} trigger={['click']}>
            <Icon
              type="ellipsis"
              style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '10px' }}
            />
          </Dropdown>
        </Col>
      </Row>

      <table style={{ width: '100%', tableLayout: 'fixed' }}>
        <tbody>
          <tr>
            <th style={{ width: '10%' }}>JobNo</th>
            <th style={{ width: '10%' }}>Ship/Con</th>
            <th>CostSheet</th>
          </tr>
          {referenceNums.map((r: string, index: number) => (
            <BookingRow
              // @ts-ignore
              onSelectSingleCostItem={onSelectSingleCostItem}
              onCostItemAdded={onCostItemAdded}
              key={r}
              index={index}
              referenceNum={r.trim()}
              addBookingRow={addBookingRow}
            />
          ))}
        </tbody>
      </table>
    </>
  )
}

export default enhance(BulkInvoiceGenerator)
