import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { compose } from 'recompose'

import TransportSubmitModal from 'App/components/TransportSubmit/TransportSubmitModal'
import createDocumentCreatorMutation from 'App/containers/documentCreator/mutation/createDocumentCreator'

const enhance = compose(
  createDocumentCreatorMutation,
  connect((state, props) => ({
    selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
  }))
)

class ROTAddAction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      documentCreator: this.getNewDocumentCreator(props)
    }
  }

  getNewDocumentCreator = props => {
    const { booking, selectedGlobalCompany = {} } = props

    return {
      bookingUuid: booking.uuid,
      status: 'NEW',
      data: {
        billToCompany: {
          uuid: selectedGlobalCompany.company && selectedGlobalCompany.company.uuid
        }
      }
    }
  }

  handleClick = () => {
    this.setState({
      visible: true,
      documentCreator: this.getNewDocumentCreator(this.props)
    })
  }

  handleClose = () => {
    this.setState({
      visible: false,
      documentCreator: this.getNewDocumentCreator(this.props)
    })
  }

  handleChange = documentCreator => {
    const { onAdded } = this.props

    this.setState({
      ...this.state,
      documentCreator
    })

    if (onAdded) {
      onAdded(documentCreator)
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button icon="plus" shape="circle" onClick={this.handleClick} style={{ marginLeft: 10 }} />
        <TransportSubmitModal
          documentCreator={this.state.documentCreator}
          visible={this.state.visible}
          onChange={this.handleChange}
          onClose={this.handleClose}
        />
      </React.Fragment>
    )
  }
}

export default enhance(ROTAddAction)
