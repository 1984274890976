import { Row } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: auto;
  padding: 20px 30px;
`

export const LogoWrapper = styled.div`
  text-align: center;

  .logo-text {
    font-size: 18px;
    font-weight: 400;
    color: #151b26;
    letter-spacing: 0.3px;
    padding: 15px 0;
  }
`

export const InputWrapper = styled.div`
  .error-msg {
    font-size: 13.5px;
    font-weight: 500;
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
`

export const StyledRow = styled(Row)`
  height: 100vh;
`
