import { gql } from '@apollo/client'

import documentCreator from '../fragment/documentCreator'

export default gql`
  mutation createDocumentCreator($input: CreateDocumentCreator!) {
    createDocumentCreator(input: $input) {
      ...DocumentCreatorDetails
    }
  }

  ${documentCreator}
`
