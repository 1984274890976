import React, { Component } from 'react'
import { Col, Table } from 'antd'
import sumBy from 'lodash/sumBy'
import numeral from 'numeral'

import FormattedCurrency from 'App/components/Formatted/Currency'
import { NoItemsFound, SectionTitle, Summary, TableWrapper } from '../Styled'
import CreatePayment from './Create'

const { LabelItem, ValueItem, SummaryItem, SummaryLabels, SummaryValues, SummaryWrapper } = Summary

class PaymentsTableView extends Component {
  renderCreateControl = () => {
    const { createPayment, selectedVoucher } = this.props

    if (selectedVoucher?.status !== 'DELETED') {
      return <CreatePayment createPayment={createPayment} />
    } else {
      return null
    }
  }

  render() {
    const { columns, dataSource, selectedVoucher, voucherPayments } = this.props

    const totalPaid = sumBy(
      voucherPayments,
      vp => vp.payment && vp.payment.status !== 'VOIDED' && numeral(vp.amount).value()
    )

    const summaries = [
      {
        label: 'Total Paid',
        value: totalPaid,
        state: 'normal'
      },
      {
        label: 'Balance',
        value: selectedVoucher?.balance,
        state: 'highlight'
      }
    ]

    return (
      <React.Fragment>
        <SectionTitle borderless>
          Payments
          {this.renderCreateControl()}
        </SectionTitle>
        <TableWrapper>
          {!voucherPayments.length ? (
            <NoItemsFound>No payments available.</NoItemsFound>
          ) : (
            <Table
              pagination={false}
              locale={{
                emptyText: 'No items selected.'
              }}
              columns={columns}
              dataSource={dataSource}
            />
          )}
        </TableWrapper>
        <SummaryWrapper>
          <Col span={18} />
          <Col span={6}>
            {summaries.map((summary, index) => (
              <SummaryItem key={index} state={summary.state}>
                <SummaryLabels span={12}>
                  <LabelItem>{summary.label} : </LabelItem>
                </SummaryLabels>
                <SummaryValues span={12}>
                  <ValueItem>
                    <FormattedCurrency
                      value={summary.value}
                      currency={selectedVoucher?.currency?.code}
                    />
                  </ValueItem>
                </SummaryValues>
              </SummaryItem>
            ))}
          </Col>
        </SummaryWrapper>
      </React.Fragment>
    )
  }
}

export default PaymentsTableView
