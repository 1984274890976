import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import PropTypes from 'prop-types'

import { QUERY_REQUISITION_TEMPLATE, SEND_REQUISITION } from 'App/graphql/template'
import handleResponse from 'App/utils/responseHandler'

export default WrappedComponent => {
  class WithRequisition extends Component {
    constructor(props) {
      super(props)

      this.getTemplate = this.getTemplate.bind(this)
      this.handleSendRequisition = this.handleSendRequisition.bind(this)
    }

    async handleSendRequisition(params) {
      if (params) {
        const { bookingQuery } = this.props

        try {
          handleResponse('Sending request, hang on...', 'load')

          await this.props.sendRequisition(params)
          await bookingQuery.refetch({
            uuid: bookingQuery.booking.uuid
          })

          handleResponse('Sent successfully.', 'success')
        } catch (error) {
          handleResponse(error, 'error')
        }
      }
    }

    async getTemplate(type) {
      const { booking } = this.props.bookingQuery

      if (booking && type) {
        const resp = await this.props.client.query({
          query: QUERY_REQUISITION_TEMPLATE,
          fetchPolicy: 'network-only',
          variables: {
            uuid: booking && booking.uuid,
            bookingType: booking.type,
            type
          }
        })

        return resp
      } else {
        console.log('booking or type is missing')
      }
    }

    render() {
      const actions = {
        getTemplate: this.getTemplate,
        handleSendRequisition: this.handleSendRequisition
      }

      return <WrappedComponent requisitionActions={actions} {...this.props} />
    }
  }

  WithRequisition.propTypes = {
    bookingQuery: PropTypes.object,
    client: PropTypes.object,
    sendRequisition: PropTypes.func,
    sendDocRequisition: PropTypes.func
  }

  return compose(
    withApollo,
    graphql(SEND_REQUISITION, {
      props: ({ mutate, ownProps }) => ({
        sendRequisition: params =>
          mutate({
            variables: {
              input: params
            }
          })
      })
    })
  )(WithRequisition)
}
