import { useTranslation } from 'react-i18next'
import { Dropdown, Icon, Typography } from 'antd'
import styled from 'styled-components'

import type { BookingTableColumnProps } from 'App/components/Booking/ListView/ColumnBuilder'
import lazyLoad from 'App/utils/retryLazyLoad'

const DropDownContent = lazyLoad(() => import('./DropDownContent'))

type ExportBookingListProps = {
  query: any
  total: number
  columns: BookingTableColumnProps[]
}

const ExportButtonStyle = styled.span`
  gap: 10px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: blue;
  }
`

const ExportBookingList = ({ total, query, columns }: ExportBookingListProps) => {
  const { t } = useTranslation()
  return (
    <Dropdown overlay={<DropDownContent total={total} query={query} columns={columns} />}>
      <ExportButtonStyle>
        <Icon type="export" />
        <Typography.Text strong type="secondary">
          {t('index.export')}
        </Typography.Text>
      </ExportButtonStyle>
    </Dropdown>
  )
}

export default ExportBookingList
