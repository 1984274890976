import { gql } from '@apollo/client'

import { fragments as voucherFragment } from 'App/containers/voucher/fragment'

export default gql`
  mutation linkDocumentToVoucher1($bookingDocumentUuid: UUID!, $voucherUuid: UUID!) {
    linkDocumentToVoucher(bookingDocumentUuid: $bookingDocumentUuid, voucherUuid: $voucherUuid) {
      ...VoucherDetails
    }
  }

  ${voucherFragment.voucherDetails}
`
