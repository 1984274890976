import { gql } from '@apollo/client'

import { fragments as voucherFragment } from 'App/containers/voucher/fragment'

export const UPDATE_VOUCHER_ITEM = gql`
  mutation updateVoucherItem2($input: UpdateVoucherItemInput!) {
    updateVoucherItem(input: $input) {
      ...VoucherItemDetails
    }
  }

  ${voucherFragment.voucherItemDetails}
`
