import { connect } from 'react-redux'
import { Col, Radio, Row, Table } from 'antd'
import findIndex from 'lodash/findIndex'
import { withHandlers } from 'recompose'
import { bindActionCreators, compose } from 'redux'

import { Brief } from 'App/components/Workflow/Styled'
import * as voucherActions from 'App/states/reducers/voucher'
import { NoItemsFound, SectionTitle, Summary, TableWrapper } from '../Styled'
import AddCostItemAction from './AddCostItemAction'
import { StyledRadio } from './Styled'
import SummaryItem from './SummaryItem'

const { SummaryWrapper } = Summary

const handlers = compose(
  connect(
    state => ({
      costItemsView: state.voucher.costItemsView,
      showDeletedCostItems: state.voucher.showDeletedCostItems
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  ),
  withHandlers({
    toggleCostItemsView: props => async e => {
      if (e.target && e.target.value) {
        const { updateCostItemsView } = props

        updateCostItemsView(e.target.value)
      }
    },
    toggleCostItemsDeletedView: props => e => {
      const { showDeletedCostItems, updateCostItemsDeletedView } = props

      updateCostItemsDeletedView(!showDeletedCostItems)
    }
  })
)

const ShowHideButton = props => {
  const { showDeletedCostItems, voucherBookingCostItems, toggleCostItemsDeletedView } = props

  const hasDeletedCostItem = findIndex(voucherBookingCostItems, vbci => vbci.isDeleted) >= 0

  if (hasDeletedCostItem) {
    return (
      <Brief onClick={toggleCostItemsDeletedView}>
        {showDeletedCostItems ? 'Hide' : 'Show'} deleted
      </Brief>
    )
  } else {
    return null
  }
}

const CostItemsTableView = props => {
  const {
    compact,
    columns,
    dataSource,
    costItemsView,
    voucherBooking,
    toggleCostItemsView,
    voucherBookingCostItems,
    onCostItemAdded = () => {}
  } = props

  const costSheet = voucherBooking.costSheet

  return (
    <>
      <SectionTitle style={{ display: compact ? 'none' : 'inline' }} borderless>
        <Row>
          <Col span={10}>
            Cost Sheet
            <AddCostItemAction
              bookingUuid={voucherBooking.uuid}
              onCostItemAdded={onCostItemAdded}
            />
            {ShowHideButton(props)}
          </Col>
          <Col span={14}>
            <StyledRadio.Wrapper>
              <Radio.Group
                size="small"
                value={costItemsView}
                buttonStyle="solid"
                onChange={toggleCostItemsView}
              >
                <Radio.Button value="operation">Operation</Radio.Button>
                <Radio.Button value="finance">Finance</Radio.Button>
              </Radio.Group>
            </StyledRadio.Wrapper>
          </Col>
        </Row>
      </SectionTitle>
      <TableWrapper>
        {!voucherBookingCostItems.length ? (
          <NoItemsFound align="left">No cost items available.</NoItemsFound>
        ) : (
          <Table
            pagination={false}
            locale={{
              emptyText: 'No cost items available.'
            }}
            columns={columns}
            dataSource={dataSource}
          />
        )}
      </TableWrapper>
      <SummaryWrapper style={{ display: compact ? 'none' : 'inline' }}>
        <Col span={12} />
        <Col span={6}>
          <SummaryItem label="Sell Total" value={costSheet && costSheet.sellTotal} />
          <SummaryItem label="Cost Total" value={costSheet && costSheet.costTotal} />
          <SummaryItem label="AR Total" value={costSheet && costSheet.accountReceivable} />
          <SummaryItem label="AP Total" value={costSheet && costSheet.accountPayable} />
        </Col>
        <Col span={6}>
          <SummaryItem label="Estimated Profit" value={costSheet && costSheet.estimatedProfit} />
          <SummaryItem label="Short Bill" value={costSheet && costSheet.shortBill} />
          <SummaryItem label="Accrual Total" value={costSheet && costSheet.accrual} />
          <SummaryItem label="Gross Profit" value={costSheet && costSheet.grossProfit} />
        </Col>
      </SummaryWrapper>
    </>
  )
}

export default handlers(CostItemsTableView)
