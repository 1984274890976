import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { IS_BROWSER } from 'App/utils/website'
import webStorage from 'App/utils/webStorage'

export const actions = {
  CHANGE_GLOBAL_COMPANY: 'CHANGE_GLOBAL_COMPANY',
  UPDATE_GLOBAL_COMPANIES: 'UPDATE_GLOBAL_COMPANIES'
}

/** @deprecated use Zustand setSelectedGlobalCompany instead */
export const changeGlobalCompany = company => {
  return {
    type: actions.CHANGE_GLOBAL_COMPANY,
    company
  }
}

/** @deprecated use Zustand setGlobalCompanies instead */
export const updateGlobalCompanies = companies => {
  return {
    type: actions.UPDATE_GLOBAL_COMPANIES,
    companies
  }
}

const initialState = {
  globalCompanies: [],
  selectedGlobalCompany: {}
}

/** @deprecated use Zustand GlobalCompany Store instead */
const globalCompany = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_GLOBAL_COMPANY:
      if (IS_BROWSER && action.company?.uuid) {
        webStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_GLOBAL_COMPANY, action.company)
      }

      return { ...state, selectedGlobalCompany: action.company }

    case actions.UPDATE_GLOBAL_COMPANIES:
      if (IS_BROWSER) {
        webStorage.setItem(LOCAL_STORAGE_KEYS.GLOBAL_COMPANIES, action.companies)
      }
      return { ...state, globalCompanies: action.companies }

    default:
      return state
  }
}

export default globalCompany
