import type { Incentive, TransportJob } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useLazyQuery, useMutation } from '@apollo/client'
import { Alert, Button, Col, Form, Row } from 'antd'
import { pick } from 'lodash'
import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

import { ActionItem, Actions } from '@/components/Manage/Styled'
import { INCENTIVES_QUERY } from '@/components/Transport/Components/Modals/JobActivityModal/schema'
import {
  UPDATE_LEG_MUTATION,
  UPDATE_LEG_TIMING_MUTATION
} from '@/components/Transport/Schemas/schema'
import { isActiveLeg } from '@/components/Transport/Utils/jobHelper'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import JobContainerSealDisplay from './JobContainerSealDisplay'
import LegDetailsDisplay from './LegDetailsDisplay'
import LegFormDetails from './LegFormDetails'
import LegTimingsWrapper from './LegTimingsWrapper'
import styles from './UpdateLegForm.module.css'

interface UpdateLegFormProps {
  form: any
  client?: ApolloClient<any>
  isVDJM?: boolean
  leg: TransportJob
  legs: TransportJob[]
  onCloseForm: any
  legsLoading: boolean
  refetchLegs: any
}

const UpdateLegForm = memo((props: UpdateLegFormProps) => {
  const { client, form, isVDJM, leg, legs, onCloseForm, legsLoading, refetchLegs } = props

  const { t } = useTranslation()

  const isDeleted: boolean = useMemo(() => !isActiveLeg(leg), [leg])

  const [editLegMode, setEditLegMode] = useState(
    !(isDeleted || (leg?.driverName && leg?.vehicleName))
  )
  const [incentives, setIncentives] = useState<Incentive[]>([])
  const [formSuccessMsg, setFormSuccessMsg] = useState('')
  const [formError, setFormError] = useState('')

  const onSwitchEditMode = useCallback(() => {
    setEditLegMode((state: boolean) => !state)
    setFormSuccessMsg('')
    setFormError('')
  }, [])

  const [updateLegTiming, { loading: updateLoading }] = useMutation(UPDATE_LEG_TIMING_MUTATION, {
    client
  })

  const [updateLegMain, { loading }] = useMutation(UPDATE_LEG_MUTATION, { client })

  const [getIncentives, { loading: incentiveLoading }] = useLazyQuery(INCENTIVES_QUERY, {
    client,
    onCompleted: data => {
      setIncentives(data?.incentives?.rows as Incentive[])
    },
    fetchPolicy: 'cache-first'
  })

  const onUpdateLeg = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      if (isDeleted) {
        return setFormError(`Leg ${leg?.sequence} is ${leg?.legStatus} and cannot be updated.`)
      }

      try {
        setFormSuccessMsg('')
        setFormError('')

        const fields = [
          'tripUuid',
          'legUuid',
          'transportUuid',
          'remarks',
          'driverUuid',
          'driverCode',
          'driverName',
          'driverIc',
          'driverPhoneNo',
          'assistantUuids',
          'vehicleUuid',
          'vehicleCode',
          'vehicleName',
          'trailerUuid',
          'trailerCode',
          'trailerName'
        ]

        const updateObj = {
          ...pick(values, fields),
          tripUuid: leg?.tripUuid,
          legUuid: leg?.legUuid,
          _id: uuidV4()
        }

        const isFirstTripAndFirstLeg = leg?.sequence === 1 && leg?.tripSequence === 1

        const updateLegTimingObj: any = {
          _id: uuidV4(),
          tripUuid: leg?.tripUuid,
          legUuid: leg?.legUuid,
          planStart: moment(
            isFirstTripAndFirstLeg ? leg.shipperRequiredDate : values.planStart
          ).toDate()
        }

        setFormSuccessMsg('Saving leg details...')

        if (updateObj.legUuid && updateLegMain) {
          const res = await updateLegMain({ variables: { input: updateObj } })
          if (res?.data?.updateLeg?.success) {
            responseHandler('Successfully updated leg.', 'success')
            onSwitchEditMode()

            const resUpdateLegTiming = await updateLegTiming({
              variables: { input: updateLegTimingObj }
            })
            if (resUpdateLegTiming?.data?.updateLegTiming?.success) {
              responseHandler('Successfully update planStart.', 'success')
              refetchLegs()
            }
          }
        } else {
          setFormSuccessMsg('')
          responseHandler('No leg selected for update.', 'warning')
        }
      } catch (error: any) {
        responseHandler(error, 'error')
        logger.error('UpdateLegForm updateLegMain error', error)
        setFormSuccessMsg('')
        setFormError(error.message || JSON.stringify(error))
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, leg, updateLegMain, updateLegTiming, refetchLegs])

  const setFieldsFromPreviousLeg = useCallback(
    (fields: string[]) => {
      if (!fields.length || !legs || legs?.length <= 1) return

      const currentLegIndex = legs.findIndex(l => l.uuid === leg.uuid)

      if (currentLegIndex <= 0) return

      const previousLeg = legs[currentLegIndex - 1]

      fields.forEach((field: string) => {
        form.setFieldsValue({ [field]: previousLeg[field] })
      })
    },
    [legs, leg, form]
  )

  useEffect(() => {
    if (leg?.driverUuid || (leg?.driverName && leg?.driverCode)) return
    setFieldsFromPreviousLeg(['driverUuid', 'driverCode', 'driverName'])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg, legs])

  useEffect(() => {
    if (isDeleted || (leg?.driverName && leg?.vehicleName)) {
      setEditLegMode(false)
    } else {
      setEditLegMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leg])

  useEffect(() => {
    if (!legs || !legs.length) return

    getIncentives({
      variables: { input: { legUuids: legs.map(leg => leg.legUuid) } }
    })
  }, [getIncentives, legs])

  return (
    <div className={!isVDJM ? styles.container : styles.vdjmContainer}>
      <div className={styles.left}>
        <JobContainerSealDisplay
          // @ts-ignore
          leg={leg}
          legsLoading={legsLoading}
          incentives={incentives || []}
          incentiveLoading={incentiveLoading}
        />
      </div>

      <div className={styles.rightContainer}>
        <div className={styles.rLeft}>
          {editLegMode ? (
            <LegFormDetails
              leg={leg}
              form={form}
              client={client}
              isVDJM={isVDJM}
              setFormError={setFormError}
              setFormSuccessMsg={setFormSuccessMsg}
            />
          ) : (
            <LegDetailsDisplay leg={leg} />
          )}

          <Row gutter={24}>
            <Col span={5} />
            <Col span={19}>
              {formSuccessMsg && (
                <Alert message={formSuccessMsg} type="success" style={{ fontSize: '12px' }} />
              )}
              {formError && <Alert message={formError} type="error" style={{ fontSize: '12px' }} />}
            </Col>
          </Row>

          <Actions>
            <ActionItem span={24}>
              <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  id="edit-cancel-button-leg-form"
                  disabled={isDeleted}
                  loading={legsLoading}
                  onClick={onSwitchEditMode}
                  icon={editLegMode ? '' : 'edit'}
                >
                  {editLegMode ? 'Cancel' : 'Edit'}
                </Button>
                {editLegMode && (
                  <Button
                    id="edit-leg-form-submit-button"
                    icon="check"
                    onClick={onUpdateLeg}
                    loading={loading || legsLoading || updateLoading}
                  >
                    {t('common.submit')}
                  </Button>
                )}
              </Row>
            </ActionItem>
          </Actions>
        </div>
        <div className={styles.rRight}>
          <LegTimingsWrapper
            client={client}
            leg={leg}
            form={form}
            legs={legs}
            legsLoading={legsLoading}
            onCloseForm={onCloseForm}
            refetchLegs={refetchLegs}
            setFormError={setFormError}
            setFormSuccessMsg={setFormSuccessMsg}
          />
        </div>
      </div>
    </div>
  )
})

// @ts-ignore
export default withApollo(Form.create()(UpdateLegForm))
