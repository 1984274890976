import * as Sentry from '@sentry/react'
import { Col } from 'antd'

import ErrorFallback from 'App/ErrorFallback'
import Companies from '../Companies'
import Logo from '../Logo'
import Options from '../Options'
import SearchBox from '../Search'
import { Content, Header } from '../Styled'
import styles from './StandardLayout.module.css'

function StandardLayout({ loggedInUser, children }) {
  return (
    <>
      <Header>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.companies} id="basecompany">
            <Companies />
          </div>
          <div className={styles.searchBox}>
            <SearchBox />
          </div>
          <div className={styles.options}>
            <Options loggedInUser={loggedInUser} />
          </div>
        </div>
      </Header>

      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <Content>
          <Col span={24} className={styles.isCollapse}>
            {children}
          </Col>
        </Content>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default StandardLayout
