import React, { PureComponent } from 'react'
import { Mutation } from 'react-apollo'
import { gql } from '@apollo/client'
import { Button, Modal } from 'antd'

import { YardStatus } from 'App/types/graphql'
import { YARDS_QUERY } from './TableView/schema'

const confirm = Modal.confirm

class Actions extends PureComponent {
  handleDelete = gqlMutation => {
    const { item } = this.props

    confirm({
      title: `Confirm to ${item?.status === YardStatus.Active ? 'delete' : 'undelete'} this yard?`,
      content: 'All changes or links to this yard will be affected.',
      okText: item?.status === YardStatus.Active ? 'Delete' : 'Undelete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        gqlMutation({
          variables: {
            uuid: item?.uuid
          }
        })
      }
    })
  }

  handleDeleted = () => {
    const { onDeleted } = this.props

    if (onDeleted) {
      this.props.onDeleted()
    }
  }

  render() {
    const { item, submitText, parentLoading, customProps, refetchItem } = this.props

    if (item?.status === YardStatus.Active) {
      return (
        <Mutation
          mutation={yardDelete}
          onCompleted={this.handleDeleted}
          refetchQueries={
            refetchItem && [
              {
                query: YARDS_QUERY,
                fetchPolicy: 'cache-and-network',
                variables: {
                  input: {
                    offset: 0 * 20,
                    limit: 20
                  }
                }
              }
            ]
          }
        >
          {(deleteYard, { loading, error }) => (
            <>
              <Button type="danger" onClick={() => this.handleDelete(deleteYard)} loading={loading}>
                Delete
              </Button>
              <Button type="primary" {...customProps} loading={loading || parentLoading}>
                {submitText}
              </Button>
            </>
          )}
        </Mutation>
      )
    }

    return (
      <Mutation
        mutation={yardUndelete}
        onCompleted={this.handleDeleted}
        refetchQueries={
          refetchItem && [
            {
              query: YARDS_QUERY,
              fetchPolicy: 'cache-and-network',
              variables: {
                input: {
                  offset: 0 * 20,
                  limit: 20
                }
              }
            }
          ]
        }
      >
        {(undeleteYard, { loading, error }) => (
          <>
            <Button type="danger" onClick={() => this.handleDelete(undeleteYard)} loading={loading}>
              Undelete
            </Button>
            <Button type="primary" {...customProps} loading={loading || parentLoading}>
              {submitText}
            </Button>
          </>
        )}
      </Mutation>
    )
  }
}

const yardDelete = gql`
  mutation deleteYard1($uuid: UUID!) {
    deleteYard(uuid: $uuid) {
      success
    }
  }
`

const yardUndelete = gql`
  mutation undeleteYard1($uuid: UUID!) {
    undeleteYard(uuid: $uuid) {
      success
    }
  }
`

export default Actions
