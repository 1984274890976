import { Component } from 'react'
import { Col, Icon, Popconfirm, Popover, Row } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { compose, withState } from 'recompose'

import { ActionWrapper, StyledAnchor, StyledButton } from '../Styled'
import EditableFieldsWrapper from './EditableFieldsWrapper'

class VoucherItemActions extends Component {
  constructor(props) {
    super(props)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleShiftUp = this.handleShiftUp.bind(this)
    this.handleShiftDown = this.handleShiftDown.bind(this)
  }

  handleView = () => {
    const { voucherItem, showVoucherItem, toggleVoucherItemView, updateSelectedVoucherItem } =
      this.props

    updateSelectedVoucherItem(voucherItem)
    toggleVoucherItemView(!showVoucherItem)
  }

  handleCancel = () => {
    this.props.toggleVoucherItemView(false)
  }

  async handleShiftUp() {
    const { voucherItem, handleShiftUp } = this.props
    await handleShiftUp(voucherItem)

    return
  }

  async handleShiftDown() {
    const { voucherItem, handleShiftDown } = this.props
    await handleShiftDown(voucherItem)

    return
  }

  async handleRemove() {
    const { voucherItem, toggleLoader, onRemoveVoucherItem } = this.props

    toggleLoader(true)

    const success = await onRemoveVoucherItem(voucherItem)

    if (!success) {
      toggleLoader(false)
    }
  }

  renderEditables = () => {
    const { form, booking, showVoucherItem, selectedVoucherItem, handleUpdateVoucherItem } =
      this.props

    if (!showVoucherItem) {
      return 'Closing...'
    }

    return (
      <EditableFieldsWrapper
        form={form}
        booking={booking}
        onCancel={this.handleCancel}
        selectedVoucherItem={selectedVoucherItem}
        handleUpdateVoucherItem={handleUpdateVoucherItem}
      />
    )
  }

  renderPopoverTitle = () => {
    const { selectedVoucherItem } = this.props
    if (isEmpty(selectedVoucherItem)) return

    const { code, description } = selectedVoucherItem.costItem.chargeItem

    return (
      <Row type="flex" justify="space-between">
        <Col>
          <p>Edit voucher item</p>
        </Col>
        <Col>
          <p>{code}</p>
          <p>{description || ''}</p>
        </Col>
      </Row>
    )
  }

  render() {
    const { loading, showVoucherItem, toggleVoucherItemView } = this.props
    return (
      <ActionWrapper>
        <Popover
          trigger="click"
          placement="right"
          visible={showVoucherItem}
          overlayStyle={{ width: 400 }}
          content={this.renderEditables()}
          title={this.renderPopoverTitle()}
          getPopupContainer={trigger => trigger.parentNode}
          onVisibleChange={visible => toggleVoucherItemView(visible)}
        >
          <StyledAnchor onClick={this.handleView}>
            <Icon type="edit" />
          </StyledAnchor>
        </Popover>
        <Popconfirm
          okText="Confirm"
          placement="topRight"
          onConfirm={this.handleRemove}
          title="All changes to this item will be lost, confirm remove ?"
        >
          <StyledAnchor>
            <Icon type={loading ? 'loading' : 'delete'} />
          </StyledAnchor>
        </Popconfirm>
        <StyledButton
          icon="arrow-up"
          onClick={this.handleShiftUp}
          disabled={this.props.loadingShift}
        />
        <StyledButton
          icon="arrow-down"
          onClick={this.handleShiftDown}
          disabled={this.props.loadingShift}
        />
      </ActionWrapper>
    )
  }
}

export default compose(
  withState('loading', 'toggleLoader', false),
  withState('showVoucherItem', 'toggleVoucherItemView', false),
  withState('selectedVoucherItem', 'updateSelectedVoucherItem', {})
)(VoucherItemActions)
