import { Col } from 'antd'

import Attachments from 'App/components/Voucher/Attachments'
import VoucherBookingDetails from 'App/components/Voucher/Bookings/Details'
import Transactions from 'App/components/Voucher/Bookings/Transactions'
import CostItems from 'App/components/Voucher/CostItems'
import { InnerSection } from 'App/components/Voucher/Styled'

function ViewContent(props) {
  const {
    data,
    refetch,
    onRemoveDocument,
    onSelectSingleCostItem,
    onSelectSingleDocument,
    onCostItemEdited,
    onCostItemAdded
  } = props

  return (
    <>
      <InnerSection gutter={20} top="0px" left="20px">
        <Col span={24}>
          <VoucherBookingDetails refetch={refetch} voucherBooking={data.booking} />
        </Col>
      </InnerSection>
      <InnerSection gutter={20} top="0px" left="20px">
        <Col span={12}>
          <Attachments
            voucherBooking={data.booking}
            onRemoveDocument={onRemoveDocument}
            onSelectSingleDocument={onSelectSingleDocument}
          />
        </Col>
        <Col span={12}>
          <Transactions voucherBooking={data.booking} />
        </Col>
      </InnerSection>
      <InnerSection gutter={20} top="30px" left="20px">
        <Col span={24}>
          <CostItems
            voucherBooking={data.booking}
            onSelectSingleCostItem={onSelectSingleCostItem}
            onCostItemEdited={onCostItemEdited}
            onCostItemAdded={onCostItemAdded}
          />
        </Col>
      </InnerSection>
    </>
  )
}

export default ViewContent
