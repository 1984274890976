import { Row } from 'antd'
import styled from 'styled-components'

export const BorderedRow = styled(Row)`
  border-bottom: 1px solid rgb(230, 230, 230);
`

export const TypeText = styled.span`
  font-size: 12.5px;
  font-weight: 500;
  color: rgb(40, 45, 50);
`
