import React, { Component } from 'react'
import { Col } from 'antd'

import { BorderedRow, StyledInput, TypeText } from './Styled'

class Subject extends Component {
  render() {
    const { value, onChange } = this.props

    return (
      <BorderedRow>
        <Col span={2}>
          <TypeText>Subject : </TypeText>
        </Col>
        <Col span={22}>
          <StyledInput value={value} onChange={onChange} />
        </Col>
      </BorderedRow>
    )
  }
}

export default Subject
