import { Icon as AntIcon } from 'antd'
import styled from 'styled-components'

const StyledIcon = styled(AntIcon)`
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  padding: 8px;
`

const Icon = props => {
  return <StyledIcon {...props} />
}

export default Icon
