import { connect } from 'react-redux'
import { Input } from 'antd'
import { compose } from 'recompose'
import uuidv4 from 'uuid/v4'

const enhance = compose(
  connect(state => ({
    selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
  }))
)

const HiddenFieldsSubForm = props => {
  const { form, selectedGlobalCompany } = props
  const { getFieldDecorator } = form

  return (
    <div>
      {getFieldDecorator('uuid', {
        initialValue: uuidv4()
      })(<Input type="hidden" />)}
      {getFieldDecorator('companyUuid', {
        initialValue: selectedGlobalCompany?.uuid
      })(<Input type="hidden" />)}
    </div>
  )
}

export default enhance(HiddenFieldsSubForm)
