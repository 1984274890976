import type { FormComponentProps } from 'antd/lib/form'
import type { TransportJob } from 'App/types/graphql'

import { memo, useCallback, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { ApolloClient, useMutation, useQuery } from '@apollo/client'
import { Form, Modal, Typography } from 'antd'
import { cloneDeep } from 'lodash'

import { TRIP_AND_LEGS_QUERY } from 'App/components/Transport/Schemas/schema'
import { UPDATE_JOB } from 'App/graphql/booking'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { isUuid } from 'App/utils/u'
import LegTable from './LegTable'
import UpdateJobForm from './StatusForm'

export type UpdateJobDataType = {
  uuid?: string
  no?: string
}

interface JobActivityModalProps extends FormComponentProps {
  client: ApolloClient<Cache>
  vehicleCodeOrReg?: string
  setRefetchTrips?: any
  leg?: TransportJob
  visible?: boolean
  onCancel?: any
}

const JobActivityModal = memo((props: JobActivityModalProps) => {
  const { leg, form, client, visible, onCancel, setRefetchTrips, vehicleCodeOrReg } = props

  const [refetchLoading, setRefetchLoading] = useState(false)

  const {
    loading: tripLoading,
    data: job,
    error: tripErr,
    refetch: refetchTrip
  } = useQuery(TRIP_AND_LEGS_QUERY, {
    client,
    variables: {
      input: {
        limit: 100,
        tripUuids: [leg?.tripUuid]
      }
    },
    fetchPolicy: 'cache-and-network'
  })
  const [updateJobNo, { loading: updateJobLoading }] = useMutation(UPDATE_JOB, { client })

  const refetchLegsDelayed = useCallback(async () => {
    setRefetchLoading(true)
    if (setRefetchTrips) {
      setRefetchTrips(true)
    }
    setTimeout(async () => {
      try {
        if (leg?.tripUuid && isUuid(leg?.tripUuid) && refetchTrip) {
          await refetchTrip()
        }
        if (setRefetchLoading) {
          setRefetchLoading(false)
        }
      } catch (error) {
        // ok to fail if the modal has unmounted
        if (setRefetchLoading) {
          setRefetchLoading(false)
        }
      }
    }, 1500)
  }, [leg?.tripUuid, refetchTrip, setRefetchTrips])

  const handleModalOk = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        const updateJobVals: UpdateJobDataType = {
          uuid: leg?.jobUuid,
          no: values?.no
        }

        const res = await updateJobNo({ variables: { input: updateJobVals } })

        if (res?.data?.updateJob?.uuid) {
          respHandler(`Successfully updated job ${leg?.jobNo}.`, 'success')
          refetchLegsDelayed()
        }
      } catch (error) {
        logger.error('JobActivityModal updateJobNo error', error)
        respHandler(error, 'error')
      }
    })
  }, [form, refetchLegsDelayed, leg, updateJobNo])

  const sortedLegs = useMemo(
    () => cloneDeep(job?.transportJobs?.rows)?.sort((a: any, b: any) => a.sequence - b.sequence),
    [job]
  )

  if (tripErr) {
    logger.error('JobActivityModal refetchTrip error', tripErr)
    respHandler(tripErr, 'error')
  }

  const findLeg = sortedLegs?.find((l: TransportJob) => l.legUuid === leg?.legUuid)

  return (
    <Modal
      width="97%"
      visible={!!visible}
      onCancel={onCancel}
      onOk={handleModalOk}
      style={{ top: 20 }}
      title={
        <Typography.Text strong>
          Update Legs for Trip{' '}
          {`(${leg?.tripType}${leg?.tripSequence ? `-${leg?.tripSequence}` : ''}${leg?.tripStatus ? `-${leg?.tripStatus}` : ''})`}
        </Typography.Text>
      }
      footer={null}
    >
      <>
        <UpdateJobForm
          form={form}
          trip={sortedLegs}
          leg={findLeg || leg}
          handleModalOk={handleModalOk}
          updateJobLoading={updateJobLoading}
        />
        <LegTable
          legs={sortedLegs || [leg]}
          refetchLegs={refetchLegsDelayed}
          vehicleCodeOrReg={vehicleCodeOrReg}
          legsLoading={tripLoading || refetchLoading}
        />
      </>
    </Modal>
  )
})

// @ts-expect-error
export default withApollo(Form.create<FormComponentProps>()(JobActivityModal))
