import { useMutation } from '@apollo/client'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { debounce } from 'lodash'

import { useClient } from 'App/hooks/useClient'
import { useBookingStore } from 'App/store/booking'
import responseHandler from 'App/utils/responseHandler'
import { UPDATE_GENERATED_DOCUMENT } from '../../../../../schema'
import { DocumentGeneratorFormHandler } from '../../ModalFooter/utils'

const validateDataAndUpdate = debounce(
  async (
    documentGeneratorDataForm: WrappedFormUtils,
    documentGeneratorModalGeneratedDocument: any,
    updateGeneratedDocument,
    documentGeneratorModalRefetch: () => any
  ) => {
    const formHandler = new DocumentGeneratorFormHandler(
      documentGeneratorModalGeneratedDocument,
      null,
      documentGeneratorDataForm
    )

    await formHandler.updateGeneratedDocumentData(updateGeneratedDocument)

    documentGeneratorModalRefetch()
  },
  800
)

export const useCustomOnChange = (onChange: (args: any) => void) => {
  const client = useClient()

  const documentGeneratorDataForm = useBookingStore.use.documentGeneratorDataForm()
  const documentGeneratorModalGeneratedDocument =
    useBookingStore.use.documentGeneratorModalGeneratedDocument()

  const documentGeneratorModalRefetch = useBookingStore.use.documentGeneratorModalRefetch()

  const [updateGeneratedDocument] = useMutation(UPDATE_GENERATED_DOCUMENT, {
    client
  })

  const extendOnChangeWrapper = (onChange: (args: any) => void) => {
    return async (args: any) => {
      onChange(args)

      try {
        await validateDataAndUpdate(
          documentGeneratorDataForm,
          documentGeneratorModalGeneratedDocument,
          updateGeneratedDocument,
          documentGeneratorModalRefetch
        )
      } catch (error) {
        responseHandler(error, 'error')
      }
    }
  }

  const wrappedOnChange = extendOnChangeWrapper(onChange)

  return wrappedOnChange
}
