import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Col, Icon, Popover, Row, Typography } from 'antd'

import { PluginComponentLocation } from '@/plugins/options'
import PluginLoader from '@/plugins/PluginLoader'
import { ActionItem, Actions } from 'App/components/Manage/Styled'
import { isActiveLeg } from 'App/components/Transport/Utils/jobHelper'
import { usePreviousProps } from 'App/hooks/usePreviousProps'
import { transLegTimings } from 'App/utils/labelMap'
import TimeInputWrapper from './TimeInputWrapper'

const LegTimingsWrapper = memo((props: any) => {
  const {
    form,
    leg,
    legs,
    legsLoading,
    refetchLegs,
    setFormError,
    isControlMode,
    setActivityObj,
    setFormSuccessMsg,
    client
  } = props

  // useMemo so that this won't overwrite the legActivities state
  const defaultTimings = useMemo(
    () =>
      transLegTimings.map((timing: any) => {
        return {
          ...timing,
          isEditable: false,
          timing: leg?.[timing.label]
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const [legActivities, setLegActivities] = useState(defaultTimings)
  const [dateSuccessMsg, setDateSuccessMsg] = useState('')
  const [dateError, setDateError] = useState('')
  const [, setRenderCount] = useState(0)

  const prevLeg: any = usePreviousProps(leg)

  const refreshLegs = useCallback(() => {
    if (refetchLegs) {
      refetchLegs()
    }
    setRenderCount((count: number) => count + 1)
  }, [refetchLegs])

  // Loads leg timings to a state when the user selects a leg
  useEffect(() => {
    if (prevLeg?.legUuid === leg?.legUuid && prevLeg?.planStart === leg?.planStart) return

    setFormSuccessMsg('')
    setFormError('')
    setDateSuccessMsg('')
    setDateError('')

    setLegActivities((activities: any) => {
      const newActs = activities.map((act: any) => {
        act.timing = leg?.[act.label] && new Date(leg[act.label])
        act.isEditable = false
        act.legStatus = leg?.legStatus
        return act
      })

      const noTiming = newActs?.find((act: any) => !act.timing && isActiveLeg(act))
      if (noTiming) {
        noTiming.isEditable = true
      }

      const isPlanEnd = noTiming?.label.includes('planEnd')
      const hasEndTime = newActs?.find((act: any) => act.label.indexOf('end') >= 0 && act.timing)
      if (isPlanEnd && hasEndTime) {
        noTiming.isEditable = false
        const endOut = newActs?.find((act: any) => act.label.includes('endOut') && isActiveLeg(act))
        if (!endOut?.timing) {
          endOut.isEditable = true
        }
      }

      return newActs
    })

    form.resetFields()
  }, [form, leg, prevLeg, setFormError, setFormSuccessMsg])

  return (
    <Row>
      {legActivities?.map((activity: any, index: number, array: any) => {
        return (
          <TimeInputWrapper
            key={activity.label}
            // @ts-ignore
            currentActivity={activity}
            leg={leg}
            legs={legs}
            legActivities={array}
            legsLoading={legsLoading}
            refetchLegs={refetchLegs}
            setLegActivities={setLegActivities}
            setDateSuccessMsg={setDateSuccessMsg}
            setDateError={setDateError}
            setRenderCount={setRenderCount}
            isControlMode={!!isControlMode}
            setActivityObj={setActivityObj}
          />
        )
      })}

      <Row gutter={24}>
        <Col span={4} />
        <Col span={20}>
          {dateSuccessMsg && (
            <Alert message={dateSuccessMsg} type="success" style={{ fontSize: '12px' }} />
          )}
          {dateError && <Alert message={dateError} type="error" style={{ fontSize: '12px' }} />}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" style={{ gap: 20, paddingTop: 15 }}>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          *Press Enter to submit the time.
        </Typography.Text>
      </Row>

      <Actions>
        <ActionItem span={24}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Popover
              content={
                <div className="flex flex-col space-y-2">
                  <Button icon="sync" onClick={refreshLegs} loading={legsLoading}>
                    Refresh
                  </Button>
                  <PluginLoader
                    componentLocation={[PluginComponentLocation.UpdateLegTimingPopoverPost]}
                    componentProps={{ jobUuid: leg?.jobUuid, leg }}
                  />
                </div>
              }
            >
              <Button>
                <Icon type="more" />
              </Button>
            </Popover>
          </Row>
        </ActionItem>
      </Actions>
    </Row>
  )
})

export default LegTimingsWrapper
