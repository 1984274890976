export const documentTypeArr = [
  { type: 'impCustStart', label: 'Custom Start' },
  { type: 'impMiscSupplierPaymentInvoiceApproval', label: 'Misc Supplier Payment Invoice' },
  { type: 'impCustCustomsSecondApproval', label: 'Customs Second Approval' },
  { type: 'impCustCustomsAaApproval', label: 'Customs AA Approval' },
  { type: 'impCustomsPaymentVoucherAaApproval', label: 'Custom Payment Voucher Approval' },
  { type: 'impCustCustomsPaymentInvoiceAaApproval', label: 'Customs Payment Invoice Approval' },
  { type: 'impCustomsTaxPaymentFinAaApproval', label: 'Customs Tax Payment Finance Approval' },
  { type: 'impCustConsigneeCustomsApprovalDoc', label: 'Consignee Customs Doc Approval' },
  { type: 'impLinerLinerPaymentOprAaApproval', label: 'Liner Payment Operation Approval' },
  { type: 'expLinerStart', label: 'Liner Start' },
  { type: 'expLinerComplete', label: 'Complete' },
  { type: 'expCustPendCustomsRelease', label: 'Pending Custom Release' },
  { type: 'expCustPendOperationsReceive', label: 'Pending Operations Receive' },
  {
    type: 'expCustPendCustomsPaymentDocumentUploadOrCustomsRelease',
    label: 'Pending Customs Payment Document Upload or Release'
  },
  { type: 'ImpFreightHouseNoa', label: 'House NOA' },
  { type: 'ImpFreightK4', label: 'K4' },
  { type: 'impFreightComplete', label: 'Freight Complete' },
  {
    type: 'expCustPendCustomsPaymentDocumentAaApproval',
    label: 'Pending Customs Payment document Approval'
  },
  { type: 'expCustPendShipperTaxInvoiceAaApproval', label: 'Pending Shipper Tax Invoice Approval' },
  { type: 'expCustPendShipperInvoiceGenerate', label: 'Pending Shipper Invoice Generation' },
  {
    type: 'expCustPendShipperTaxDraftConfirmation',
    label: 'Pending Shipper Tax Draft Confirmation'
  },
  {
    type: 'expCustPendShipperTaxDraftNotification',
    label: 'Pending Shipper Customs Document Draft Confirmation Notification'
  },
  { type: 'expCustStart', label: 'Custome Start' },
  { type: 'ExpLinerBookingConfirmation', label: 'Liner Booking Confirmation' },
  {
    type: 'expCustCustomsDocumentDraftConfirmation',
    label: 'Shipper confirmation of Customs Document'
  },
  { type: 'expCustCustomsPaymentReceipt', label: 'Custom Payment Receipt' },
  {
    type: 'ExpOutstandingSupplierPaymentInvoiceDocument',
    label: 'Outstanding Supplier Payment Invoice'
  },
  {
    type: 'ExpOutstandingSupplierPaymentReceiptDocument',
    label: 'Outstanding Supplier Payment Receipt'
  },
  { type: 'expLinerVoucherReceipt', label: 'Liner Voucher Receipt' },
  { type: 'expLinerInvoice', label: 'Liner Invoice' },
  { type: 'expForeignAgentBill', label: 'Foreign Agent Bill' },
  {
    type: 'ExpOutstandingSuppierPaymentShipperProofLiabilityDocument',
    label: 'Shipper Proof Liability Document'
  },
  { type: 'portBill', label: 'Port Bill' },
  { type: 'permits', label: 'Permits' },
  { type: 'formA', label: 'Form A' },
  { type: 'formE', label: 'Form E' },
  { type: 'billOfLading', label: 'Bill of Lading (BL)' },
  { type: 'consumerInvoice', label: 'Commercial Invoice' },
  { type: 'commercialInvoice', label: 'Commercial Invoice' },
  { type: 'packingList', label: 'Packing List' },
  { type: 'impCustCustomsDocumentDraft', label: 'Customs Document Draft' },
  { type: 'impCustCustomsTaxPaymentInvoice', label: 'Customs Tax Payment Invoice' },
  { type: 'impCustCustomsPaymentInvoice', label: 'Customs Payment Invoice' },
  { type: 'ImpContainerMovementOrder', label: 'Container Movement Order' },
  { type: 'ImpRequestOfTransportResponse', label: 'ROT Response' },
  { type: 'ImpOutstandingSupplierPaymentReceiptDocument', label: 'Supplier Payment Receipt' },
  { type: 'ImpOutstandingSupplierPaymentInvoiceDocument', label: 'Supplier Payment Invoice' },
  {
    type: 'ImpOutstandingSuppierPaymentConsigneeProofLiabilityDocument',
    label: 'Consignee Proof Liability'
  },
  {
    type: 'impCompOutstandingConsigneeInvoiceSupportingDocuments',
    label: 'Consignee Invoice Supporting Document'
  },
  { type: 'letterOfIntent', label: 'Letter of Intent (LOI)' },
  { type: 'loiTemplate', label: 'LOI Template' },
  { type: 'noa', label: 'Notice of Arrival' },
  { type: 'impLinerInvoice', label: 'Liner Invoice' },
  { type: 'impLinerVoucher', label: 'Liner Invoice' },
  { type: 'impLinerVoucherRecipt', label: 'Liner Voucher Receipt' },
  { type: 'impLinerVoucherVoucher', label: 'Liner Voucher Voucher' },
  { type: 'impLinerVoucherTransaction', label: 'Liner Voucher Transaction' },
  { type: 'impLinerVoucherReceipt', label: 'Liner Voucher Receipt' },
  { type: 'impDocInvoice', label: 'Invoice' },
  { type: 'impDocVoucherReceipt', label: 'Voucher Receipt' },
  { type: 'impCustK1', label: 'K1' },
  { type: 'consigneeTaxInvoiceToCustomer', label: 'Consignee Tax Invoice' },
  { type: 'impCustConsigneeVoucherReceipt', label: 'Consignee Voucher Receipt' },
  { type: 'bookingConfirmation', label: 'Booking Confirmation' },
  { type: 'ExpContainerMovementOrder', label: 'Container Movement Order' },
  { type: 'ExpRequestOfTransportResponse', label: 'ROT Response' },
  { type: 'expCustCustomsDocumentDraft', label: 'Customs Document Draft' },
  { type: 'expCustCustomsPaymentInvoice', label: 'Customs Payment Invoice' },
  {
    type: 'expCompOutstandingConsigneeInvoiceSupportingDocuments',
    label: 'Consignee Invoice Supporting Documents'
  },
  { type: 'linerInvoice', label: 'Liner Invoice' },
  { type: 'linerVoucherVoucher', label: 'Liner Voucher Voucher' },
  { type: 'linerVoucherTransaction', label: 'Liner Voucher Transaction' },
  { type: 'gatePass', label: 'Gate Pass' },
  { type: 'linerVoucherReceipt', label: 'Liner Voucher' },
  { type: 'electronicDeliveryOrder', label: 'Electronic Delivery Order' },
  { type: 'ip', label: 'Import Permit' },
  { type: 'lk', label: 'Permit Perikanan (LK)' },
  { type: 'dvs', label: 'Veterenary (DVS)' },
  { type: 'others', label: 'Others' },
  { type: 'linerTaxInvoice', label: 'Liner Tax Invoice' },
  { type: 'k1Draft', label: 'K1 Draft' },
  { type: 'K1Draft', label: 'K1 Draft' },
  { type: 'k1Chopped', label: 'K1 Chopped' },
  { type: 'gatePass', label: 'GatePass' },
  { type: 'rot', label: 'Request of Transport' },
  { type: 'customerCostSheet', label: 'Customer Cost Sheet' },
  { type: 'customerInvoice', label: 'Customer Invoice' },
  { type: 'transHlgPodDoc', label: 'Transport Haulage POD Document' }
]

export const approvalTypes = {
  impCustCustomsDutyVoucherFinHodApproval: {
    text: 'Custom Fin HOD Approval',
    value: 'impCustCustomsDutyVoucherFinHodApproval'
  },
  impCustCustomsDutyVoucherOprHodApproval: {
    text: 'Custom Opr HOD Approval',
    value: 'impCustCustomsDutyVoucherOprHodApproval'
  },
  impCustCustomsDutyVoucherCustomsApproval: {
    text: 'Custom Approval',
    value: 'impCustCustomsDutyVoucherCustomsApproval'
  },
  impCustCustomsDutyVoucherOprHodK1Approval: {
    text: 'Custom Opr HOD K1 Approval',
    value: 'impCustCustomsDutyVoucherOprHodK1Approval'
  },
  impCustCustomsDutyVoucherConsigneeApproval: {
    text: 'Custom Consignee Approval',
    value: 'impCustCustomsDutyVoucherConsigneeApproval'
  },
  impLinerLinerPaymentFinHodApproval: {
    text: 'Fin HOD Approval',
    value: 'impLinerLinerPaymentFinHodApproval'
  },
  impLinerLinerPaymentOprHodApproval: {
    text: 'Opr HOD Approval',
    value: 'impLinerLinerPaymentOprHodApproval'
  },
  impLinerPendHodApproval: {
    value: 'impLinerOperHod',
    text: 'impLinerOperHod'
  },
  impLinerPendFinanceHodApproval: {
    value: 'impLinerFinHod',
    text: 'impLinerFinHod'
  },
  impCustPendCustomerApproval: {
    value: 'impCustConCustomsVoucherApproval',
    text: 'Consignee Customs Voucher Approval'
  },
  impCustPendOprHodK1Approval: {
    text: 'Operation HOD K1 Approval',
    value: 'impCustPendOprHodK1Approval'
  },
  impCustPendCustomsApproval: {
    text: 'Customs K1 Approval',
    value: 'impCustCustomsApprovedK1'
  },
  impCustPendOprHodVoucherApproval: {
    text: 'Operation HOD Tax Voucher Approval',
    value: 'impCustOperHodTaxVoucher'
  },
  impCustPendFinanceHodVoucherApproval: {
    text: 'Finance HOD Approval',
    value: 'impCustFinHod'
  }
}

export const voucherTypes = {
  pettyCash: {
    text: 'Petty Cash',
    value: 'pettyCash'
  },
  impCompOutstandingConsigneeInvoiceVoucherAaApproval: {
    text: 'Import - Outstanding Consignee Invoice Aa Approval',
    value: 'impCompOutstandingConsigneeInvoiceVoucherAaApproval'
  },
  impCompOutstandingConsigneeInvoiceVoucherFinAaApproval: {
    text: 'Import - Outstanding Consignee Invoice Fin. Aa Approval',
    value: 'impCompOutstandingConsigneeInvoiceVoucherFinAaApproval'
  },
  expCompOutstandingInvoiceVoucher: {
    text: 'Export - Outstanding Invoice',
    value: 'expCompOutstandingInvoiceVoucher'
  },
  expCustShipperCustomsPaymentInvoice: {
    text: 'Export - Shipper Customs Payment Invoice',
    value: 'expCustShipperCustomsPaymentInvoice'
  },
  expCompForeignAgentInvoiceVoucher: {
    text: 'Export - Foreign Agent Invoice',
    value: 'expCompForeignAgentInvoiceVoucher'
  },
  expLinerLinerPayment: {
    text: 'Export - Liner Payment',
    value: 'expLinerLinerPayment'
  },
  expPortBill: {
    text: 'Export - Port Bill',
    value: 'expPortBill'
  },
  expCustPermits: {
    text: 'Export - Custom Permits',
    value: 'expCustPermits'
  },
  expHaulageBill: {
    text: 'Export - Haulage Bill',
    value: 'expHaulageBill'
  },
  expCompErrosAndOmissionsVoucher: {
    text: 'Export - Errors and Omissions Voucher',
    value: 'expCompErrosAndOmissionsVoucher'
  },
  expCompForeignAgentPaymentVoucher: {
    text: 'Export - Foreign Agent Payment Voucher',
    value: 'expCompForeignAgentPaymentVoucher'
  },
  impCustomsPaymentVoucherHodApproval: {
    text: 'Import - Custom Payment Fin HOD Approval',
    value: 'impCustomsPaymentVoucherHodApproval'
  },
  impCustCustomsDutyVoucherFinHodApproval: {
    text: 'Import - Custom Fin HOD Approval',
    value: 'impCustCustomsDutyVoucherFinHodApproval'
  },
  impCustCustomsDutyVoucherOprHodApproval: {
    text: 'Import - Custom Opr HOD Approval',
    value: 'impCustCustomsDutyVoucherOprHodApproval'
  },
  impCustCustomsDutyVoucherCustomsApproval: {
    text: 'Import - Custom Approval',
    value: 'impCustCustomsDutyVoucherCustomsApproval'
  },
  impCustCustomsDutyVoucherOprHodK1Approval: {
    text: 'Import - Custom Opr HOD K1 Approval',
    value: 'impCustCustomsDutyVoucherOprHodK1Approval'
  },
  impCustCustomsDutyVoucherConsigneeApproval: {
    text: 'Import - Custom Consignee Approval',
    value: 'impCustCustomsDutyVoucherConsigneeApproval'
  },
  impCustConsigneeCustomsPaymentInvoiceVoucher: {
    text: 'Import - Consignee Invoice for Custom Payment',
    value: 'impCustConsigneeCustomsPaymentInvoiceVoucher'
  },
  impLinerLinerPayment: {
    text: 'Import - Liner Payment',
    value: 'impLinerLinerPayment'
  },
  impLinerLinerPaymentFinHodApproval: {
    text: 'Import - Fin HOD Approval',
    value: 'impLinerLinerPaymentFinHodApproval'
  },
  impLinerLinerPaymentOprHodApproval: {
    text: 'Import - Opr HOD Approval',
    value: 'impLinerLinerPaymentOprHodApproval'
  },
  impCustCustomsDutyVoucher: {
    text: 'Import - Custom Duty Voucher',
    value: 'impCustCustomsDutyVoucher'
  },
  impCustConsigneeTaxVoucherInvoice: {
    text: 'Import - Consignee Tax Voucher Invoice',
    value: 'impCustConsigneeTaxVoucherInvoice'
  },
  impCustConsigneeCustomsPaymentInvoice: {
    text: 'Import - Customs Consignee Payment Invoice',
    value: 'impCustConsigneeCustomsPaymentInvoice'
  },
  impCustCustomsTaxPaymentVoucher: {
    text: 'Import - Customs Tax Payment Voucher',
    value: 'impCustCustomsTaxPaymentVoucher'
  },
  impLinerPendHodApproval: {
    text: 'Import - Liner Pending HOD Approval',
    value: 'impLinerOperHod'
  },
  impLinerPendFinanceHodApproval: {
    text: 'Import - Liner Pending Finance HOD',
    value: 'impLinerFinHod'
  },
  impLinerPendFinanceVoucherVoucher: {
    text: 'Import - Pending Finance Voucher',
    value: 'impLinerFinHod'
  },
  impHaulageBill: {
    text: 'Import - Haulier Payment',
    value: 'impHaulageBill'
  },
  impPortBill: {
    text: 'Import - Port Bill',
    value: 'impPortBill'
  },
  impCustPendCustomerApproval: {
    text: 'Import - Consignee Customs Voucher Approval',
    value: 'impCustConCustomsVoucherApproval'
  },
  impCustPendOprHodK1Approval: {
    text: 'Import - Operation HOD K1 Approval',
    value: 'impCustPendOprHodK1Approval'
  },
  impCustPendCustomsApproval: {
    text: 'Import - Customs K1 Approval',
    value: 'impCustCustomsApprovedK1'
  },
  impCustPendOprHodVoucherApproval: {
    text: 'Import - Operation HOD Tax Voucher Approval',
    value: 'impCustOperHodTaxVoucher'
  },
  impCustPendFinanceHodVoucherApproval: {
    text: 'Import - Finance HOD Approval',
    value: 'impCustFinHod'
  },
  impCompOutstandingSupplierPaymentVoucher: {
    text: 'Import - Outstanding Supplier Payment',
    value: 'impCompOutstandingSupplierPaymentVoucher'
  },
  impCompConsigneeInvoiceVoucher: {
    text: 'Import - Consignee Invoice',
    value: 'impCompOutstandingConsigneeInvoiceVoucher'
  },
  impCompOutstandingConsigneeInvoiceVoucher: {
    text: 'Import - Outstanding Invoice',
    value: 'impCompOutstandingConsigneeInvoiceVoucher'
  },
  expCustCustomsTaxPaymentVoucher: {
    text: 'Export - Customs Tax Payment',
    value: 'expCustCustomsTaxPaymentVoucher'
  },
  expCompShipperInvoiceVoucher: {
    text: 'Export - Shipper Invoice',
    value: 'expCompShipperInvoiceVoucher'
  },
  expCompOutstandingSupplierPaymentVoucher: {
    text: 'Export - Outstanding Supplier Payment',
    value: 'expCompOutstandingSupplierPaymentVoucher'
  },
  transSupplierPayment: {
    text: 'Supplier Payment',
    value: 'transSupplierPayment'
  },
  transCustomerInvoice: {
    text: 'Customer Invoice',
    value: 'transCustomerInvoice'
  },
  transCustomerInvoiceFinAaApproval: {
    text: 'Customer Invoice Fin Approval',
    value: 'transCustomerInvoiceFinAaApproval'
  }
}

export const requestTypes = {
  ExpCustShipperDraftNotification: {
    text: ' Shipper Draft Notification',
    value: 'ExpCustShipperDraftNotification'
  },
  ImpConsigneeDocs: {
    text: 'Consignee Documents',
    value: 'ImpConsigneeDocs'
  },
  ImpEdoRequisition: {
    text: 'EDO Request',
    value: 'ImpEdoRequisition'
  },
  RequestImportDocuments: {
    text: 'Request Import Documents',
    value: 'RequestImportDocuments'
  },
  ImpNoaRequisition: {
    text: 'Import NOA Request',
    value: 'ImpNoaRequisition'
  },
  ImpCustConsigneeDraftNotification: {
    text: 'Import Customs Consignee Draft Notification',
    value: 'ImpCustConsigneeDraftNotification'
  },
  ImpHlgRequestOfTransport: {
    text: 'Request of Transport',
    value: 'ImpHlgRequestOfTransport'
  },
  ImpHlgRequestOfTransportExtraDocs: {
    text: 'Request of Transport - Extra Docs',
    value: 'ImpHlgRequestOfTransportExtraDocs'
  },
  ImpHlgRequestForCollection: {
    text: 'Request For Collection',
    value: 'ImpHlgRequestForCollection'
  },
  ImpCompOutstandingSupplierPaymentNotification: {
    text: 'Outstanding Supplier Payment Notification',
    value: 'ImpCompOutstandingSupplierPaymentNotification'
  },
  ImpOutstandingSupplierPaymentReceiptDocument: {
    text: 'Outstanding Supplier Payment Receipt Document',
    value: 'ImpOutstandingSupplierPaymentReceiptDocument'
  },
  RequestExportDocuments: {
    text: 'Request Export Documents',
    value: 'RequestExportDocuments'
  },
  ExpHlgRequestOfTransport: {
    text: 'Request of Transport',
    value: 'ExpHlgRequestOfTransport'
  },
  expCustConsigneeDraftNotification: {
    text: 'Export Customs Consignee Draft Notification',
    value: 'expCustConsigneeDraftNotification'
  },
  ExpHlgRequestForCollection: {
    text: 'Request For Collection',
    value: 'ExpHlgRequestForCollection'
  },
  ExpCompOutstandingSupplierPaymentNotification: {
    text: 'Outstanding Supplier Payment Notification',
    value: 'ExpCompOutstandingSupplierPaymentNotification'
  },
  ExpLinerLinerPaymentNotify: {
    text: 'Liner Payment Notify',
    value: 'ExpLinerLinerPaymentNotify'
  }
}

export const documentType = documentTypeArr.reduce((dtObj, dt) => {
  dtObj[dt.type] = dt.label
  return dtObj
}, {})

export default {
  impDoc: 'Document',
  impDocPre: 'Document (Pre)',
  impDocPendSubmission: 'Submission',
  impDocBookingConfirmation: 'Booking Confirmation',
  impDocPendDocumentRequest: 'Document Request',
  impDocPendVerification: 'Verification',
  impDocPendXDaysBeforeEta: '2d Before Eta',
  impDocPendDocumentSubmit: 'Document Submit',
  impDocPendPermitApplication: 'Permit Application',
  impDocPendPermitApproval: 'Permit Approval',
  impDocPendHardCopySubmit: 'Submit Hard Copy',
  impDocComplete: 'Complete',

  impLiner: 'Liner',
  impLinerPre: 'Import Liner (Pre)',
  impLinerVoucher: 'Import Liner (Voucher)',
  impLinerPost: 'Import Liner (Post)',
  impLinerStart: 'Start',
  impLinerPendNoaReq: 'Liner NOA Request',
  impLinerPendNoa: 'Liner NOA',
  impLinerPendVoucherVoucherGeneration: 'PV Generation',
  impLinerPendNotifyLiner: 'Liner Notification',
  impLinerPendNoaInvoiceLoi: 'NOA/LOI/Invoice',
  impLinerPendInvoiceGeneration: 'Invoice Generation',
  impLinerPendHodApproval: 'HOD Approval',
  impLinerPendFinanceVoucherVoucher: 'Fin. Voucher Generation',
  impLinerPendFinanceHodApproval: 'Fin. HOD Approval',
  impLinerPendFinancePayment: 'Fin. Payment',
  impLinerPendFinanceVoucher: 'Finance Voucher',
  impLinerFinancePaid: 'Invoice Paid',
  impLinerInvoiceLoiSentToLiner: 'Invoice & LOI Sent to Liner',
  impLinerEdoRelease: 'EDO Released',
  impLinerPendEdo: 'EDO',
  impLinerLoiRec: 'LOI Upload',
  impLinerPendVoucherNotify: 'Voucher Notify',
  impLinerPendLoiRec: 'LOI Upload',
  impLinerVoucherNotified: 'Liner Voucher Notified',
  impLinerComplete: 'Complete',
  impLinerPendVoucherGeneration: 'Voucher Generation',

  impFreight: 'Freight',
  impFreightPre: 'Import Freight (Pre)',
  impFreightVoucher: 'Import Freight (Voucher)',
  impFreightPost: 'Import Freight (Post)',
  impFreightStart: 'Start',

  impCust: 'Custom',
  impCustPre: 'Import Custom (Pre)',
  impCustNotify: 'Import Custom (Notify)',
  impCompPendStart: 'Start',
  impCustPendPackingList: 'Packing List',
  impCustPendCustomsDocumentDraft: 'Customs Document Draft',
  impCustPendCustomsDocumentSecondCheckApproval: 'Second Check Approval',
  impCustPendCustomsDocumentHodApproval: 'HOD Approval',
  impCustSubmit: 'Import Custom (Submission)',
  impCustPendCustomsSystemSubmit: 'Customs System Submit',
  impCustPendPhysicalSubmit: 'Physical Submit',
  impCustPendSystemApproval: 'System Approval',
  impCustPendCustomsPaymentDocumentUpload: 'Customs Payment Invoice Upload',
  impCustVoucher: 'Import Custom (Voucher)',
  impCustPendConsigneeInvoiceGenerate: 'Consignee Invoice Generation',
  impCustPendCustomsPaymentDocumentHodApproval: 'Payment Document HOD Approval',
  impCustPendConsigneeTaxInvoiceHodApproval: 'Consignee Tax Invoice HOD Approval',
  impCustPendCustomsTaxPaymentVoucherGeneration: 'Tax Payment Voucher Generation',
  impCustPendCustomsPaymentFinHodApproval: 'Fin. HOD Approval',
  impCustPendCustomsPaymentTresuryPayment: 'Treasury Payment',
  impCustPost: 'Import Custom (Post)',
  impCustCustomsDutyVoucherOprHodK1Approval: 'Duty Voucher Hod K1 Approval',
  impCustPendDraft: 'K1 Draft',
  impCustPendConsigneeTaxInvoiceGeneration: 'Consignee Tax Invoice Gen.',
  impCustPendTaxVoucherGeneration: 'Tax Voucher Voucher',
  impCustPendConsigneeTaxDraftNotification: 'Consignee Notice',
  // impCustPendCustomerTaxInvoiceGeneration: 'Tax Invoice',
  impCustPendCustomerNotification: 'Consignee Notify',
  impCustPendCustomerApproval: 'Consignee Approval',
  impCustPendOprHodK1Approval: 'Opr. Hod K1 Approval',
  impCustPendCustomerTaxVoucher: 'Consignee Voucher',
  impCustPendFinanceTaxVoucherVerify: 'Voucher Verify',
  impCustPendCustomsSubmit: 'K1 Submit',
  impCustPendHardCopySubmit: 'K1 Hard Copy Submit',
  impCustPendCustomsApproval: 'Customs Approval',

  impCustPendTaxVoucherVoucherGeneration: 'Opr PV',
  impCustPendOprHodVoucherApproval: 'Opr Hod Voucher Approval',
  impCustPendFinanceVoucherVoucher: 'Fin PV',
  impCustPendFinanceHodVoucherApproval: 'Fin Hod Approval',
  impCustPendFinanceVoucher: 'Voucher',

  impCustPendingCustomsRelease: 'Customs Release',
  impCustComplete: 'Complete',

  impHlg: 'Haulage/Transport',
  impHlgPre: 'Haulage/Transport (Pre)',
  impHlgPost: 'Haulage/Transport (Post)',
  impHlgPendingStart: 'Start',
  impHlgPendCmoUpload: 'CMO Upload',
  impHlgPendRot: 'ROT',
  impHlgPendRotResponse: 'ROT Response',
  impHlgPendDelPlan: 'Delivery Plan',
  impHlgPendDelStart: 'Delivery Start',
  impHlgPendDeliveryComplete: 'Delivery Complete',
  impHlgPendDelivery: 'Delivery',
  impHlgPendRfc: 'RFC',
  impHlgPendCollection: 'Collection',
  impHlgPendColPlan: 'Collection Plan',
  impHlgPendCollectionStart: 'Collection Start',
  impHlgPendCollectionComplete: 'Collection Complete',
  impHlgPendComplete: 'Complete',
  impHlgComplete: 'Complete',

  impFreightComplete: 'Complete',
  impCustStart: 'Start',
  impCompPendCustomerVoucherAaApproval: 'Pending Customer Voucher Approval',
  impLinerPendAaApproval: 'Pending Approval',
  impLinerPendFinanceAaApproval: 'Pending Finance Approval',
  impCompPendOutstandingInvoiceAaApprove: 'Pending Outstanding Invoice Approval',
  impCompPendOutstandingInvoiceVoucherFinAaApproval: 'Pending Outstanding Invoice Finance Approval',
  impComp: 'Complete',
  impCompAp: 'Complete AP',
  impCompAr: 'Complete AR',
  impCompApPayment: 'AP Payment',
  impCompArInvoice: 'AR Invoice',
  impCompPre: 'Complete (Pre)',
  impCompPayment: 'Complete (Payment)',
  impCompInvoice: 'Complete (Invoice)',
  impCompPost: 'Complete (Post)',
  impCompOperRecieveFromCust: 'Opr. Receive K1 from Customs',
  impCompCsRecieveFromOper: 'CS Receive K1 from Opr',
  impCompPendCostSheetGeneration: 'Cost Sheet Generation',
  impCompPendCostSheetHodApproval: 'Cost Sheet HOD',
  impCompPendCustomerInvoice: 'Cost Sheet Invoice',
  impCompComplete: 'Complete',
  impCompPendUploadOustandingInvoice: 'Upload Outstanding Invoice',
  impCompPendOutstandingInvoiceHodApprove: 'HOD Approval',
  impCompPendOutstandingInvoiceVoucherGeneration: 'Voucher Generation',
  impCompPendOutstandingInvoiceVoucherFinHodApproval: 'Fin. HOD Approval',
  impCompPendOutstandingInvoiceVoucherPayment: 'Voucher Payment',
  impCompPendOutstandingInvoiceVoucherNotification: 'Voucher Notification',
  impCompPendOutstandingConsigneeInvoiceVoucher: 'Consignee Invoice Voucher',
  impCompPendCustomerVoucherGeneration: 'Customer Voucher Generation',
  impCompPendCustomerVoucherHodApproval: 'HOD Approval',
  impCompPendCustomerVoucherFinHodApproval: 'Fin. HOD Approval',
  impCompPendOperClose: 'Operation Close',
  impCompPendFinClose: 'Finance Close',

  expDoc: 'Document',
  expDocPre: 'Document (Pre)',
  expDocBookingConfirmation: 'Booking Confirmation',
  expDocPendDocumentRequest: 'Document Request',
  expDocPendVerification: 'Verification',
  expDocPendXDaysBeforeEtd: 'Days Before ETD',
  expDocPendDocumentSubmit: 'Document Submit',
  expDocPendConfirmation: 'Confirmation',
  expDocPendSubmission: 'Submission',
  expDocComplete: 'Complete',
  expDocLessXDayEtd: 'Less Than 1d ETD',

  expLiner: 'Liner',
  expLinerStart: 'Start',
  expLinerComplete: 'Complete',
  expLinerPre: 'Liner (Pre)',
  expLinerSubmit: 'Liner (Submit)',
  expLinerVoucher: 'Liner (Voucher)',
  expLinerPost: 'Liner (Post)',

  expHlg: 'Haulage/Transport',
  expHlgPre: 'Haulage/Transport (Pre)',
  expHlgPost: 'Haulage/Transport (Post)',
  expHlgPendingStart: 'Start',
  expHlgPendCmoUpload: 'CMO Upload',
  expHlgPendRot: 'ROT',
  expHlgPendRotResponse: 'ROT Response',
  expHlgPendDelPlan: 'Delivery Plan',
  expHlgPendDelivery: 'Delivery',
  expHlgPendRfc: 'RFC',
  expHlgPendCollection: 'Collection',
  expHlgPendColPlan: 'Collection Plan',
  expHlgPendComplete: 'Complete',
  expHlgPendDelStart: 'Delivery Start',
  expHlgPendDeliveryComplete: 'Delivery Complete',
  expHlgPendCollectionStart: 'Collection Start',
  expHlgPendCollectionComplete: 'Collection Complete',
  expHlgComplete: 'Complete',

  expFreight: 'Freight',
  expFreightPre: 'Export Freight (Pre)',
  expFreightPost: 'Export Freight (Post)',
  expFreightStart: 'Start',

  expCust: 'Custom',
  expCustPre: 'Custom (Pre)',
  expCustSubmit: 'Custom Submit',
  expCustVoucher: 'Custom Voucher',
  expCustPost: 'Custom (Post)',

  expCustStart: 'Start',
  expCustPendOperationsReceive: 'Pending Operations Receive',
  expCustPendCustomsPaymentDocumentUploadOrCustomsRelease:
    'Pending Customs Payment Document Upload or Release',
  expCustPendShipperInvoiceGenerate: 'Pending Shipper Invoice Generation',
  expCustPendShipperTaxInvoiceAaApproval: 'Pending Shipper Tax Invoice Approval',
  expCustPendCustomsPaymentDocumentAaApproval: 'Pending Customs Payment document Approval',
  expCustPendCustomsPaymentFinAaApproval: 'Pending customs Payment Finance Approval',
  expCustPendCustomsRelease: 'Pending Custom Release',
  expCompPendStart: 'Pending Start',
  expCompPendOutstandingInvoiceAaApprove: 'Pending Outstanding Invoice Approve',
  expCompPendOutstandingInvoiceVoucherFinAaApproval: 'Pending Outstanding Invoice Finance Aproval',
  expCompPendOutstandingShipperInvoiceVoucher: 'Pending Outstanding Shipper Invoice',
  expCompPendCustomerVoucherAaApproval: 'Pending Customer Voucher Approval',
  expCustPendShipperTaxDraftNotification:
    'Pending Shipper Customs Document Draft Confirmation Notification',
  expCustPendShipperTaxDraftConfirmation: 'Pending Shipper Tax Draft Confirmation',
  expCustPendDraft: 'Draft',
  expCustPendCustomer: 'Customer',
  expCustSubmitted: 'Submitted',
  expCustCleared: 'Cleared',
  expCustPendPackingList: 'Upload Packing List & Commercial Invoice',
  expCustPendCustomsDocumentDraft: 'Customs Document Draft',
  expCustPendCustomsDocumentSecondCheckApproval: 'Second Check Approval',
  expCustPendCustomsDocumentHodApproval: 'HOD Approval',
  expCustPendConsigneeTaxDraftNotification: 'Consignee Tax Draft Notification',
  expCustPendCustomsSystemSubmit: 'System Submit',
  expCustPendPhysicalSubmit: 'Physical Submit',
  expCustPendSystemApproval: 'System Approval',
  expCustPendCustomsPaymentDocumentUpload: 'Payment Document Upload',

  expCustPendConsigneeInvoiceGenerate: 'Consignee Invoice Generate',
  expCustPendCustomsPaymentDocumentHodApproval: 'Payment Document HOD Approval',
  expCustPendConsigneeTaxInvoiceHodApproval: 'Consignnee Tax Invoice HOD Approval',
  expCustPendCustomsTaxPaymentVoucherGeneration: 'Tax Payment Voucher Generation',
  expCustPendCustomsPaymentFinHodApproval: 'Fin. HOD Approval',
  expCustPendCustomsPaymentTresuryPayment: 'Treasury Payment',
  expCustPendingCustomsRelease: 'Release',
  expCustComplete: 'Complete',

  expComp: 'Complete',
  expCompAp: 'Complete AP',
  expCompAr: 'Complete AR',
  expCompInvoice: 'Invoice',
  expCompPendInvoice: 'Invoice',
  expCompPendHodApproval: 'HOD Approval',
  expCompPendFinanceComplete: 'Finance Complete',
  expCompPayment: 'Complete (Payment)',
  expCompApPayment: 'AP Payment',
  expCompArInvoice: 'AR Invoice',
  expCompPost: 'Complete (Post)',

  expCompPendUploadOustandingInvoice: 'Upload Outstanding Invoice',
  expCompPendOutstandingInvoiceHodApprove: 'HOD Approve',
  expCompPendOutstandingInvoiceVoucherGeneration: 'Voucher Generation',
  expCompPendOutstandingInvoiceVoucherFinHodApproval: 'Fin. HOD Approval',
  expCompPendOutstandingInvoiceVoucherPayment: 'Payment',
  expCompPendOutstandingInvoiceVoucherNotification: 'Notification',
  expCompPendOutstandingConsigneeInvoiceVoucher: 'Invoice Voucher',
  expCompPendCustomerVoucherGeneration: 'Voucher Generation',
  expCompPendCustomerVoucherHodApproval: 'HOD Approval',
  expCompPendCustomerVoucherFinHodApproval: 'Fin. HOD Approval',
  expCompPendOperClose: 'Operation Close',
  expCompPendFinClose: 'Fin. Close',
  expCompComplete: 'Complete',

  status: 'Status',
  isAssigned: 'Not Assigned',

  transDoc: 'Document',
  transHlg: 'Transport',
  transCompAp: 'AP Payment',
  transCompApPayment: 'AP Payment',
  transCompAr: 'AR Invoice',
  transCompArInvoice: 'AR Invoice',
  transComp: 'Complete',
  transCompPost: 'Complete',

  transHlgStart: 'Start',
  transHlgTransDelStart: 'Start Del.',
  transHlgTransDelPlan: 'Delivery Plan',
  transHlgPendTransDeliveryComplete: 'Complete Del.',
  transHlgPendTransPodSubmitted: 'Submit POD',
  transHlgComplete: 'Complete',
  transHlgPendTransPodVerified: 'POD Verified',

  transCompApComplete: 'Complete',

  transCompPendStartAr: 'Start',
  transCompPendDeliveryComplete: 'Create Invoice',
  transCompPendOutstandingConsigneeInvoiceVoucher: 'Pending Approval',
  transCompPendCustomerVoucherOprAaApproval: 'Approved',
  transCompArComplete: 'Complete',

  transCompPendStart: 'Start',
  transCompPendOperClose: 'Pending Opr. Close',
  transCompPendFinClose: 'Pending Fin. Close',
  transCompComplete: 'Complete',

  miscCategories: [
    [
      'status',
      'sub_category',
      (a, b) => {
        if (a.key === 'SUBMITTED' && b.key === 'ACCEPTED') return -1
        if (a.key === 'ACCEPTED' && b.key === 'SUBMITTED') return 1
        return 0
      }
    ],
    ['isAssigned', 'sub_category']
  ]
}

export const SectionMap = {
  impDoc: {
    title: 'Import Documentation',
    tag: 'impDoc'
  },
  impLiner: {
    title: 'Import Liner',
    tag: 'impLiner'
  },
  impCust: {
    title: 'Import Custom',
    tag: 'impCust'
  },
  impHlg: {
    title: 'Import Haulage',
    tag: 'impHlg'
  },
  impComp: {
    title: 'Import Completion',
    tag: 'impComp'
  }
}

export const workflowMap = {
  impDoc: {
    title: 'Import Documentation',
    tag: 'impDoc'
  },
  impLiner: {
    title: 'Import Liner',
    tag: 'impLiner'
  },
  impCust: {
    title: 'Import Custom',
    tag: 'impCust'
  },
  impHlg: {
    title: 'Import Haulage',
    tag: 'impHlg'
  },
  impComp: {
    title: 'Import Completion',
    tag: 'impComp'
  }
}

export const chronologyTypes = {
  impCustDocSystemSubmit: 'System Submit',
  impCustOperationsReceive: 'Operations Receive',
  impCustDocPhysicalSubmit: 'Physical Submit',
  impCustDocSystemApproval: 'System Approval',
  impCustCustomsRelease: 'Customs Release',
  impCompOperationalClose: 'Operational Close',
  impCompFinanceClose: 'Finance Close',
  ImpHlgDelStart: 'Delivery Start',
  ImpHlgDelComplete: 'Delivery Complete',
  ImpHlgColStart: 'Collection Start',
  ImpHlgColComplete: 'Collection Complete',
  impLastPortStorage: 'Last Port Storage',
  impLastDayDemurrage: 'Last Day Demurrage',
  impLastDayDetention: 'Last Day Detention',
  linerEstimatedTimeOfArrival: 'Liner Estimated Time of Arrival',
  linerEstimatedTimeOfDeparture: 'Liner Estimated Time of Departure',
  linerEtaTransshipmentPort: 'Liner ETA transshipment port',
  linerEtaPortOfDischarge: 'Liner ETA Port of Discharge',
  expHlgDelStart: 'Delivery Start',
  expHlgDelComplete: 'Delivery Complete',
  expHlgColStart: 'Collection Start',
  expHlgColComplete: 'Collection Complete',
  expCustDocSystemSubmit: 'System Submit',
  expCustDocPhysicalSubmit: 'Physical Submit',
  expCustDocSystemApproval: 'System Approval',
  expCustCustomsRelease: 'Customs Release',
  expCompOperationalClose: 'Operational Close',
  expCompFinanceClose: 'Finance Close',
  expPortOpeningDate: 'Port Opening Date',
  expPortClosingDate: 'Port Closing Date',
  shipperRequiredDate: 'Shipper Required Date',
  consigneeRequiredDate: 'Consignee Required Date',
  transHlgPod: 'Haulage/Transport POD',
  transHlgPodDoc: 'Haulage/Transport POD Document'
}

export const transTripTypes = {
  haulageDelivery: 'HDEL',
  haulageCollection: 'HCOL',
  transportDelivery: 'DEL',
  transportCollection: 'COL'
}

export const transLegTimings = [
  { code: 'P01', label: 'planStart', text: 'Plan Start' },
  { code: 'O01', label: 'start', text: 'Start' },
  { code: 'O03', label: 'startOut', text: 'Start Out' },
  { code: 'P02', label: 'planEnd', text: 'Plan End' },
  { code: 'D01', label: 'end', text: 'End' },
  { code: 'D02', label: 'endOut', text: 'End Out' }
]
