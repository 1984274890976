import { useCallback } from 'react'
import { Icon, Tooltip } from 'antd'
import { t } from 'i18next'

type HelpIconProps = {
  articleUrl: string
  style?: React.CSSProperties
}

const HelpIcon = ({ articleUrl, style }: HelpIconProps) => {
  const onClick = useCallback(() => {
    window.open(articleUrl, '_blank')
  }, [articleUrl])

  return (
    <Tooltip title={t('common.clickForDetails')}>
      <Icon
        onClick={onClick}
        type="question-circle"
        style={{ color: 'rgba(0,0,0,0.45)', ...style }}
      />
    </Tooltip>
  )
}

export default HelpIcon
