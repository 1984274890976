import { Icon } from 'antd'
import { compose, withHandlers, withState } from 'recompose'

import { StyledAnchor } from '../Styled'

const loadingState = withState('loading', 'setLoading', false)

const handlers = withHandlers({
  handleSelectSingleCostItem: props => async () => {
    const { setLoading, onSelectSingleCostItem = () => {} } = props

    setLoading(true)

    await onSelectSingleCostItem()

    setLoading(false)
  }
})

const enhance = compose(loadingState, handlers)

const VoucherCostItemUsage = props => {
  const { loading, handleSelectSingleCostItem, index } = props

  return (
    <StyledAnchor
      id={`select-charge-item-${index}-anchor`}
      onClick={() => handleSelectSingleCostItem(props)}
    >
      <Icon type={loading ? 'loading' : 'plus'} />
    </StyledAnchor>
  )
}

export default enhance(VoucherCostItemUsage)
