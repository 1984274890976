import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button, Icon } from 'antd'
import moment from 'moment'
import { bindActionCreators, compose } from 'redux'

import BookingDocumentQuery from 'App/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from 'App/containers/booking/schema'
import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import handleResponse from 'App/utils/responseHandler'
import FieldItem from '../FieldItem'
import { APPROVE_BOOKING_DOCUMENT } from '../schema'
import { Loader } from '../Styled'
import { HiddenActions, HiddenFields } from './Styled'

class HiddenView extends Component {
  handleApprove = approveDocument => {
    const { approvalType, selectedBookingDocument } = this.props

    approveDocument({
      variables: {
        uuid: selectedBookingDocument.uuid,
        type: approvalType
      }
    })
  }

  handleApproved = result => {
    const { approveBookingDocument } = result

    const { selectedBookingDocument, updateSelectedBookingDocument, toggleHiddenFields } =
      this.props

    updateSelectedBookingDocument({
      ...approveBookingDocument,
      allowedApprovals: selectedBookingDocument.allowedApprovals
    })

    toggleHiddenFields()
  }

  renderHiddenFields = () => {
    const { showHidden, isApproved, selectedBookingDocument } = this.props

    if (!showHidden) {
      return null
    }

    if (!isApproved) {
      return (
        <Mutation
          mutation={APPROVE_BOOKING_DOCUMENT}
          onCompleted={this.handleApproved}
          onError={error => handleResponse(error, 'error')}
          refetchQueries={[
            {
              query: schema.BOOKING_QUERY,
              variables: {
                uuid: this.props.match.params.uuid
              }
            },
            {
              query: BookingDocumentQuery,
              variables: {
                uuid: selectedBookingDocument.uuid
              }
            }
          ]}
        >
          {(approveDocument, { loading, error }) => {
            if (loading) {
              return (
                <Loader>
                  <Icon type="loading" /> Approving...
                </Loader>
              )
            }

            return (
              <HiddenActions>
                <Button onClick={this.props.toggleHiddenFields}>Not now</Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => this.handleApprove(approveDocument)}
                >
                  {loading ? 'Hang on...' : 'Approve'}
                </Button>
              </HiddenActions>
            )
          }}
        </Mutation>
      )
    }

    const { approvedApproval } = this.props

    return (
      <>
        <FieldItem
          name="Approved by :"
          value={(approvedApproval.approvedBy && approvedApproval.approvedBy.email) || '-'}
        />
        <FieldItem
          name="Approved on :"
          value={
            (approvedApproval.createdAt &&
              moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')) ||
            '-'
          }
        />
      </>
    )
  }

  render() {
    const { showHidden, isApproved } = this.props

    if (!showHidden) {
      return null
    }

    return <HiddenFields border={isApproved}>{this.renderHiddenFields()}</HiddenFields>
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      selectedBookingDocument: state.bookingDocument.selectedBookingDocument
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...bookingDocumentActions
        },
        dispatch
      )
    })
  )
)(HiddenView)
