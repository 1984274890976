import { useState } from 'react'
import { Avatar, Button, List, Modal, Row, Tooltip, Typography } from 'antd'
import moment from 'moment'

import { getActTime } from 'App/components/RelativeDateTime'
import { EventTime, EventTitleSpan, StyledEventListItem } from './Styled'

const { Text } = Typography

const EventListItem = props => {
  const { event } = props
  const { payload } = event

  const [show, setShow] = useState(false)
  const updateModalState = () => setShow(!show)

  const prettifiedJsonString = JSON.stringify(JSON.parse(payload), null, 2)

  return (
    <StyledEventListItem>
      <List.Item.Meta
        avatar={<Avatar src={event.user.picture} size={24} />}
        title={
          <EventTitleSpan onClick={updateModalState}>
            <Row>
              {event.context}:{event.event}
            </Row>
            <Row>
              <Text disabled>
                {event.user.nickname
                  ? `${event.user.nickname} <${event.user.email}>`
                  : event.user.email}
              </Text>
            </Row>
          </EventTitleSpan>
        }
      />
      <Modal
        title={`Event :${event.event} (${
          event.user.nickname ? `${event.user.nickname} <${event.user.email}>` : event.user.email
        }) @${getActTime(event.time)}`}
        width="80vw"
        visible={show}
        onCancel={updateModalState}
        onOk={updateModalState}
        footer={[
          <Button key="back" onClick={updateModalState}>
            Okay
          </Button>
        ]}
      >
        <pre>{prettifiedJsonString}</pre>
      </Modal>
      <Tooltip title={moment(event.time).format('LLL')}>
        <EventTime>{getActTime(event.time)}</EventTime>
      </Tooltip>
    </StyledEventListItem>
  )
}

export default EventListItem
