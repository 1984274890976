import { memo, useMemo } from 'react'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { List, Row, Spin } from 'antd'
import orderBy from 'lodash/orderBy'

import EventListItem from 'App/components/Booking/Events/EventListItem'
import NoAccess from 'App/components/Shared/NoAccess'
import { ErrorWrapper } from 'App/components/TableView/Styled'
import config from 'App/config'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import BookingEventsGql from './BookingEventsGql'
import { NoEvents } from './Styled'

const EventList = memo(props => {
  const { client } = props

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'event',
    permission: 'view'
  })
  const { loading, error, data } = useQuery(BookingEventsGql, {
    client,
    variables: { uuid: props.bookingUuid },
    fetchPolicy: 'cache-and-network'
  })

  const events = useMemo(() => data?.booking?.events, [data])
  const sortedEvents = useMemo(() => orderBy(events, e => e.time, 'desc'), [events])

  if (permLoading || loading) {
    return (
      <Row type="flex" align="middle" justify="center" style={{ height: 200 }}>
        <Spin />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  if (error) {
    logger.error('Booking EventList Error.', error)
    return <ErrorWrapper>{config.anErrorOccurredPleaseTryAgainLater}</ErrorWrapper>
  }

  if (!events?.length) {
    return <NoEvents>No events</NoEvents>
  }

  return <List dataSource={sortedEvents} renderItem={event => <EventListItem event={event} />} />
})

export default withApollo(EventList)
