import React from 'react'

const initial = {}
const ViewerContext = React.createContext(initial)

export function withViewer(Component) {
  return function ViewerComponent(props) {
    return (
      <ViewerContext.Consumer>
        {value => <Component {...props} viewer={value} />}
      </ViewerContext.Consumer>
    )
  }
}

export default ViewerContext
