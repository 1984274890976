import { useMemo } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useQuery } from '@apollo/client'
import { Col, Divider } from 'antd'

import VoucherBookings from './Bookings'
import InputFields from './InputFields'
import Overrides from './Overrides'
import Payments from './Payments'
import { SectionTitle, ViewSection, ViewWrapper } from './Styled'
import VoucherItems from './VoucherItems'

const PERMISSION_IS_ALLOWED_QUERY = gql`
  query PermissionIsAllowed($resource: String, $permission: String) {
    permissionIsAllowed(resource: $resource, permission: $permission)
  }
`

const VoucherView = (props: any) => {
  const {
    form,
    handleUpdate,
    loadingShift,
    handleShiftUp,
    handleShiftDown,
    selectedVoucher,
    onRemoveDocument,
    onRemoveVoucherItem,
    onSelectSingleDocument,
    onSelectSingleCostItem,
    handleUpdateVoucherItem
  } = props

  const renderOverrides = () => {
    return (
      <>
        {/* @ts-expect-error */}
        <SectionTitle borderless>Overrides</SectionTitle>
        <Overrides />
      </>
    )
  }

  const renderPayments = () => {
    const { createPayment } = props
    return <Payments createPayment={createPayment} />
  }

  const isNewVoucher = useMemo(() => selectedVoucher?.status === 'NEW', [selectedVoucher])

  const voucherItemTitle = useMemo(
    () => (selectedVoucher?.transactionType === 'ACCPAY' ? 'Payment' : 'Invoice'),
    [selectedVoucher]
  )

  const { loading } = useQuery(PERMISSION_IS_ALLOWED_QUERY, {
    client: props.client,
    variables: {
      resource: 'booking',
      permission: 'view'
    },
    fetchPolicy: 'cache-and-network'
  })

  if (loading) return <></>

  return (
    <ViewWrapper>
      <InputFields form={form} handleUpdate={handleUpdate} selectedVoucher={selectedVoucher} />
      <Divider>Booking(s) Info</Divider>
      <ViewSection>
        <Col span={24}>
          <VoucherBookings
            selectedVoucher={selectedVoucher}
            onRemoveDocument={onRemoveDocument}
            onSelectSingleDocument={onSelectSingleDocument}
            onSelectSingleCostItem={onSelectSingleCostItem}
          />
        </Col>
      </ViewSection>
      <Divider>{voucherItemTitle} Items</Divider>
      <ViewSection>
        <Col span={24}>
          <VoucherItems
            loadingShift={loadingShift}
            handleShiftUp={handleShiftUp}
            handleShiftDown={handleShiftDown}
            onRemoveVoucherItem={onRemoveVoucherItem}
            handleUpdateVoucherItem={handleUpdateVoucherItem}
          />
        </Col>
      </ViewSection>
      <ViewSection
        // @ts-ignore
        top="30px"
        bottom="30px"
      >
        <Col span={24}>{isNewVoucher ? renderOverrides() : renderPayments()}</Col>
      </ViewSection>
    </ViewWrapper>
  )
}

export default withApollo(VoucherView)
