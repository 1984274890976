import { cloneElement } from 'react'
import { withApollo } from 'react-apollo'
import { isEmpty } from 'lodash'

import NoAccess from '@/components/Shared/NoAccess'
import Spinner from '@/components/Spinner'
import useFeatureFlag, { useFeatureFlagProps } from '@/hooks/useFeatureFlag'
import responseHandler from '@/utils/responseHandler'

interface FeatureGuardProps extends useFeatureFlagProps {
  children: React.ReactNode
  ghost?: boolean
}

const FeatureGuard = (props: FeatureGuardProps) => {
  const { children, ghost = true, name, baseCompanyUuid, client, ...childrenProps } = props
  const { error, loading, isEnabled } = useFeatureFlag({ name, baseCompanyUuid, client })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    responseHandler(
      `Error occurred when checking Feature Flag "${name}": ${error.message}`,
      'error'
    )
    return null
  }

  if (isEnabled) {
    return (
      <>
        {isEmpty(childrenProps)
          ? children
          : cloneElement(children as React.ReactElement, { ...childrenProps })}
      </>
    )
  }

  if (ghost) {
    return null
  }

  return (
    <NoAccess
      iconType="stop"
      text={
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <span>{`Feature "${props.name}" is currently disabled.`}</span>
          <a href="/manage/feature-flags" target="_blank">
            Manage feature flags
          </a>
        </div>
      }
    />
  )
}

export default withApollo(FeatureGuard)
