import './index.css'
import './shipx.css'
import 'regenerator-runtime'

import ReactDOM from 'react-dom'

import App from './App/App'
import config from './App/config'
import { init } from './App/utils/logger'

init(config.sentry)

ReactDOM.render(<App />, document.getElementById('root'))
