import { useEffect } from 'react'

import config from 'App/config'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import respHandler from 'App/utils/responseHandler'
import webStorage from 'App/utils/webStorage'

const queue = async (
  state: any,
  setState: any,
  voucher: any,
  type: any,
  selectedGlobalCompany: any
) => {
  const documentCreatorUrl = `${config.api.baseUrl}/documentCreator/${type}/${voucher.uuid}/${selectedGlobalCompany.uuid}`
  const pdfServiceUrl = `${config.pdf.baseUrl}/queue`

  try {
    const bodyData = {
      url: documentCreatorUrl,
      header: {
        authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
        'base-company-uuid': selectedGlobalCompany.uuid
      }
    }

    const response = await fetch(pdfServiceUrl, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })

    if (!response.ok) {
      throw new Error(await response.text())
    }

    const body = await response.json()
    const pdfUuid = body.uuid

    setState({ ...state, status: 'checking', pdfUuid })
  } catch (error) {
    console.log(error)
    respHandler(error, 'error')
    setState({ ...state, status: 'none' })
  }
}

const useQueueEffect = (
  state: any,
  setState: any,
  voucher: any,
  type: any,
  selectedGlobalCompany: any
) => {
  useEffect(() => {
    if (state.status === 'queueing') {
      queue(state, setState, voucher, type, selectedGlobalCompany)
    }

    return () => {}
  })
}

export default useQueueEffect
