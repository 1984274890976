import { Button } from 'antd'
import { compose, withHandlers, withProps } from 'recompose'

const connectorProps = withProps({
  clearsQuery: true
})

const handlers = withHandlers({
  handleClick: props => () => {
    const { refine, items } = props
    refine(items)
  }
})

const enhance = compose(connectorProps, handlers)

const ClearButton = props => {
  const { handleClick } = props

  return <Button icon="close" onClick={handleClick} />
}

export default enhance(ClearButton)
