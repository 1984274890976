import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/pick'
import uuid from 'uuid'

export const getBookingType = (ori, dest, selectedGlobal) => {
  const isImport = dest.country.alpha3 === selectedGlobal.country.alpha3
  const isExport = ori.country.alpha3 === selectedGlobal.country.alpha3
  const sameCountry = ori.country.alpha3 === dest.country.alpha3

  if (sameCountry) {
    return 'PENDING'
  }

  if (isImport) {
    return 'IMPORT'
  } else if (isExport) {
    return 'EXPORT'
  } else {
    return 'NONE'
  }
}

export const getJobs = (
  shipmentType,
  twentyFoot,
  fourtyFoot,
  containerType,
  trailerType,
  uomDetails
) => {
  const uom = shipmentType === 'FCL' ? 'CNTR' : 'PALLET'

  const jobs = []

  if (!isEmpty(uomDetails)) {
    jobs.push({
      details: {
        size: uomDetails.quantity,
        uom: uomDetails.type,
        containerType,
        transportType: trailerType
      }
    })
  }

  for (let i = 0; twentyFoot > i; i++) {
    jobs.push({
      details: {
        size: 20,
        uom,
        containerType,
        transportType: trailerType
      }
    })
  }

  for (let i = 0; fourtyFoot > i; i++) {
    jobs.push({
      details: {
        size: 40,
        uom,
        containerType,
        transportType: trailerType
      }
    })
  }

  return jobs
}

export const generateNewBookingRequest = newBooking => {
  const newBookingRequest = {
    uuid: uuid.v4(),
    ...pick(newBooking, [
      'details.bl',
      'jobs',
      'type',
      'override',
      'details.shipperRef',
      'details.shippingRef',
      'chronologies',
      'serviceTypes',
      'details.shipCallSign',
      'details.voyageNo',
      'details.vesselName',
      'details.motherVesselName',
      'details.shipmentType',
      'remarks',
      'containerType',
      'twentyFootContainer',
      'fourtyFootContainer',
      'warehouseUuid',
      'warehouseAddressUuid'
    ])
  }

  newBookingRequest.quotationUuid = newBooking.quotation && newBooking.quotation.uuid
  newBookingRequest.billToUuid = newBooking.billTo && newBooking.billTo.uuid
  newBookingRequest.placeDest = newBooking.placeDest.placeId
  newBookingRequest.placeOri = newBooking.placeOri.placeId
  newBookingRequest.portDestUuid = newBooking.portDest.uuid
  newBookingRequest.portOriUuid = newBooking.portOri.uuid

  if (newBooking.type === 'IMPORT') {
    // dest
    newBookingRequest.consigneeUuid = newBooking.consignee.uuid
    newBookingRequest.forwarderDestUuid = newBooking.forwarderDest.uuid
    newBookingRequest.linerDestUuid = newBooking.linerDest && newBooking.linerDest.uuid
  } else {
    // ori
    newBookingRequest.shipperUuid = newBooking.shipper.uuid
    newBookingRequest.forwarderOriUuid = newBooking.forwarderOri.uuid
    newBookingRequest.linerOriUuid = newBooking.linerOri && newBooking.linerOri.uuid
  }

  return newBookingRequest
}

export const generateDuplicateBookingRequest = booking => {
  const newBookingRequest = {
    uuid: uuid.v4(),
    type: booking.type,
    override: true,
    quotationUuid: booking.quotation && booking.quotation.uuid,
    remarks: booking.remarks,
    serviceTypes: booking.serviceTypes,
    tags: booking.tags,
    shipperUuid: booking.shipper && booking.shipper.uuid,
    shipperAddressUuid: booking.shipperAddress && booking.shipperAddress.uuid,
    consigneeUuid: booking.consignee && booking.consignee.uuid,
    consigneeAddressUuid: booking.consigneeAddress && booking.consigneeAddress.uuid,
    placeOri: booking.placeOri && booking.placeOri.placeId,
    placeDest: booking.placeDest && booking.placeDest.placeId,
    portOriUuid: booking.portOri && booking.portOri.uuid,
    portDestUuid: booking.portDest && booking.portDest.uuid,
    billToUuid: booking.billTo && booking.billTo.uuid,
    haulierOriUuid: booking.haulierOri && booking.haulierOri.uuid,
    haulierDestUuid: booking.haulierDest && booking.haulierDest.uuid,
    forwarderOriUuid: booking.forwarderOri && booking.forwarderOri.uuid,
    forwarderDestUuid: booking.forwarderDest && booking.forwarderDest.uuid,
    linerOriUuid: booking.linerOri && booking.linerOri.uuid,
    linerDestUuid: booking.linerDest && booking.linerDest.uuid,
    commodities: booking.commodities,
    details: {
      haulierOriRef: booking.details.haulierOriRef,
      haulierDestRef: booking.details.haulierDestRef,
      forwarderOriRef: booking.details.forwarderOriRef,
      forwarderDestRef: booking.details.forwarderDestRef,
      bl: booking.details.bl,
      houseBl: booking.details.houseBl,
      shippingRef: booking.details.shippingRef,
      internalReference: booking.details.internalReference,
      saBookingNo: booking.details.saBookingNo,
      scn: booking.details.scn,
      shipCallSign: booking.details.shipCallSign,
      voyageNo: booking.details.voyageNo,
      vesselName: booking.details.vesselName,
      motherVesselName: booking.details.motherVesselName,
      shipmentType: booking.details.shipmentType,
      manifestNo: booking.details.manifestNo,
      shipperRef: booking.details.shipperRef,
      consigneeRef: booking.details.consigneeRef,
      operatorOri: booking.details.operatorOri,
      operatorDest: booking.details.operatorDest,
      terminalOri: booking.details.terminalOri,
      terminalDest: booking.details.terminalDest,
      transportDocumentType: booking.details.transportDocumentType,
      incoTerm: booking.details.incoTerm
    },
    jobs:
      booking.jobs &&
      booking.jobs.map(j => ({
        no: j.no,
        reference: j.reference,
        remarks: j.remarks,
        details: {
          containerType: j.details && j.details.containerType,
          transportType: j.details && j.details.transportType,
          size: j.details && j.details.size,
          seal: j.details && j.details.seal,
          uom: j.details && j.details.uom,
          weight: (j.details && j.details.weight) || 0,
          options: (j.details && j.details.options) || []
        },
        expReadyForCollectionDate: j.expReadyForCollectionDate,
        expRequestOfTransportDate: j.expRequestOfTransportDate,
        impReadyForCollectionDate: j.impReadyForCollectionDate,
        impRequestOfTransportDate: j.impRequestOfTransportDate
      })),
    chronologies:
      booking.chronologies &&
      booking.chronologies.map(c => ({
        type: c.type,
        date: c.date,
        remarks: c.remarks,
        reference: c.reference
      }))
  }

  return newBookingRequest
}
