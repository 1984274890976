import { Button, Row } from 'antd'

import { isActiveLeg } from 'App/components/Transport/Utils/jobHelper'
import DeleteLegButton from './DeleteLegButton'

export const getColumns = (
  setFormVisibility: any,
  refetchLegs: any,
  setActivitiesVisibility: any
) => {
  const columns = [
    {
      Header: 'Leg',
      accessor: (r: any) => `${r.sequence} - ${r.tripType}`
    },
    {
      Header: 'Leg Status',
      accessor: 'legStatus',
      style: { textAlign: 'left' }
    },
    {
      Header: 'From',
      accessor: 'fromName',
      style: { textAlign: 'left' }
    },
    {
      Header: 'To',
      accessor: 'toName',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Source',
      accessor: 'transportSource',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Driver',
      accessor: 'driverDisplay',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Vehicle',
      accessor: 'vehicleDisplay',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Trailer',
      accessor: 'trailerDisplay',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Ship Req',
      accessor: 'shipperRequiredDate',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Plan Start',
      accessor: 'planStart',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Start',
      accessor: 'start',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Start Out',
      accessor: 'startOut',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Plan End',
      accessor: 'planEnd',
      style: { textAlign: 'left' }
    },
    {
      Header: 'End',
      accessor: 'end',
      style: { textAlign: 'left' }
    },
    {
      Header: 'End Out',
      accessor: 'endOut',
      style: { textAlign: 'left' }
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
      style: { textAlign: 'left', maxWidth: 300 }
    },
    {
      Header: '',
      accessor: 'legCrudButtons',
      Cell: (cellValue: any) => {
        const leg = cellValue.data[parseInt(cellValue.row.id)]
        const isDeleted: boolean = !isActiveLeg(leg)
        const moreThan1 = cellValue.rows.length > 1

        return (
          <Row style={{ textAlign: 'left' }}>
            <Button
              id="leg-edit-button"
              disabled={!!isDeleted}
              icon="edit"
              type="link"
              shape="circle"
              style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
              onClick={() => setFormVisibility(leg?.legUuid)}
            />
            {(moreThan1 || isDeleted) && <DeleteLegButton leg={leg} refetchLegs={refetchLegs} />}
            <span {...cellValue.row.getToggleRowExpandedProps()}>
              <Button
                type="link"
                size="small"
                shape="circle"
                icon="plus-circle"
                style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
              />
            </span>
            <Button
              type="link"
              shape="circle"
              icon="database"
              onClick={() => setActivitiesVisibility(leg?.legUuid)}
              style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
            />
          </Row>
        )
      }
    }
  ]

  return columns
}
