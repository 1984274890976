import { Button } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: 8px;
`

export const IconButton = styled(Button)`
  border: none;
  height: 20px;
  margin-left: 3px !important;
  padding-left: 2px !important;
  padding-right: 0px !important;
`

export const ChronoName = styled.div`
  font-size: 11px;
  color: rgb(40, 45, 50);
  text-transform: uppercase;
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ChronoDate = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgb(150, 152, 154);
`
