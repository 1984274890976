import { Tooltip } from 'antd'

import { StyledWarning } from './Styled'

export default ({ message }) => {
  return (
    <Tooltip title={<strong>{message}</strong>}>
      <StyledWarning type="warning" />
    </Tooltip>
  )
}
