import React, { useState } from 'react'
import { Icon } from 'antd'

import ChangeTypeModal from './ChangeTypeModal'

const ChangeTypeAction = props => {
  const { voucher, onChanged = () => {} } = props
  const [visible, setVisible] = useState(false)

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setVisible(true)
        }}
      >
        <Icon type="edit" style={{ marginRight: '8px' }} />
        Change voucher type
      </div>
      <ChangeTypeModal
        voucher={voucher}
        visible={visible}
        onClose={value => {
          setVisible(false)
          onChanged(value)
        }}
      />
    </React.Fragment>
  )
}

export default ChangeTypeAction
