import { useState } from 'react'
import { Form } from 'antd'
import { v4 as uuidv4 } from 'uuid'

import JobsForm from './JobsForm'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 }
}

const TransportDetailsSubForm = ({ form: { getFieldDecorator }, duplicateBookingObject = {}, bookingTypeCode }) => {
  const [jobs] = useState(duplicateBookingObject?.jobs || [{ details: {}, key: uuidv4() }])

  return (
    <Form.Item {...formItemLayout}>
      {getFieldDecorator('jobs', { initialValue: jobs })(<JobsForm bookingTypeCode={bookingTypeCode}/>)}
    </Form.Item>
  )
}

export default TransportDetailsSubForm
