import { Fragment } from 'react'
import PropTypes from 'prop-types'

import Attachments from '../Requisition/Template/Attachments'
import { TemplateSection } from '../Requisition/Template/Container'
import { FieldText, FieldValue, FieldWrapper } from '../Requisition/Template/Fields'

const DefaultFormTemplate = props => {
  const { fields } = props.requisitionTemplate
  const { getFieldDecorator } = props.form
  const documents = fields.documents || []
  const { booking } = props

  return (
    <Fragment>
      <TemplateSection>
        <FieldWrapper>
          <FieldText>Bill of Lading</FieldText>
          <FieldValue>{fields.bl || '-'}</FieldValue>
        </FieldWrapper>
        <FieldWrapper>
          <FieldText>Consignee</FieldText>
          <FieldValue>{fields.consignee || '-'}</FieldValue>
        </FieldWrapper>
        <FieldWrapper>
          <FieldText>Containers</FieldText>
          <FieldValue>{fields.containers || '-'}</FieldValue>
        </FieldWrapper>
      </TemplateSection>
      <TemplateSection>
        {getFieldDecorator('attachments', {
          initialValue: documents.map(a => a.uuid)
        })(<Attachments attachments={documents} selectableDocuments={booking.bookingDocuments} />)}
      </TemplateSection>
    </Fragment>
  )
}

DefaultFormTemplate.propTypes = {
  form: PropTypes.object,
  requisitionTemplate: PropTypes.object
}

export default DefaultFormTemplate
