import React, { useEffect, useState } from 'react'

type DynamicComponentHookProps = {
  componentName: string
}

export const useDynamicComponent = ({ componentName }: DynamicComponentHookProps) => {
  const [Component, setComponent] = useState<React.ComponentType | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const loadComponent = async () => {
      try {
        const module = await import(`@/plugins/${componentName}`)
        setComponent(() => module.default)
      } catch (error) {
        setError(`Component ${componentName} not found`)
        console.error(error)
      }
    }

    if (componentName) {
      loadComponent()
    }
  }, [componentName])

  return { Component, error }
}
