import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import moment from 'moment'
import { compose } from 'recompose'

import EditableFields from './EditableFields'
import exchangeRatesGql from './exchangeRatesGql'

const exchangeRatesGraphql = graphql(exchangeRatesGql, {
  name: 'exchangeRatesGraphql',
  skip: props => {
    const voucherCurrencyUuid = props.selectedVoucher.currency.uuid
    const localCurrency = props.selectedGlobalCompany.company.currency.uuid

    return voucherCurrencyUuid === localCurrency
  },
  options: props => ({
    variables: {
      limit: 1,
      offset: 0,
      fromUuid: props.selectedVoucher.currency.uuid,
      toUuid: props.selectedGlobalCompany.company.currency.uuid,
      date: moment().format('YYYY-MM-DD')
    }
  })
})

const enhance = compose(
  connect((state, props) => ({
    selectedVoucher: state.voucher.selectedVoucher,
    selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
  })),
  exchangeRatesGraphql
)

const getLocalExchangeRate = props => {
  const {
    selectedVoucher: voucher,
    selectedVoucherItem: voucherItem,
    selectedGlobalCompany,
    exchangeRatesGraphql
  } = props

  const localCurrency = selectedGlobalCompany.company.currency

  const costItemCurrency =
    voucher.transactionType === 'ACCPAY'
      ? voucherItem.costItem.costCurrencyUuid
      : voucherItem.costItem.sellCurrencyUuid

  if (costItemCurrency === voucher.currencyUuid && voucher.currencyUuid !== localCurrency.uuid) {
    return voucher.transactionType === 'ACCPAY'
      ? voucherItem.costItem.costExchangeRate
      : voucherItem.costItem.sellExchangeRate
  }

  if (voucher.currencyUuid !== localCurrency.uuid) {
    const { error, exchangeRates = {} } = exchangeRatesGraphql
    const { rows = [] } = exchangeRates

    if (error || rows.length === 0) {
      return undefined
    }

    return rows[0].rate
  }

  return 1
}

// TODO: this wrapper component is probably obsolete not needed anymore.
// to be removed if really not needed.
const EditableFieldsWrapper = props => {
  const { exchangeRatesGraphql } = props
  const localExchangeRate = getLocalExchangeRate(props)

  if (!exchangeRatesGraphql) {
    return <EditableFields {...props} localExchangeRate={localExchangeRate} />
  }

  const { loading, exchangeRates = {} } = exchangeRatesGraphql
  const { rows = [] } = exchangeRates

  if (loading) {
    return 'Loading...'
  }

  const dbExchangeRate = rows[0] && rows[0].rate

  return (
    <EditableFields
      {...props}
      localExchangeRate={localExchangeRate}
      dbExchangeRate={dbExchangeRate}
    />
  )
}

export default enhance(EditableFieldsWrapper)
