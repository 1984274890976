import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Popconfirm } from 'antd'

import { useClient } from 'App/hooks/useClient'
import { useBookingStore } from 'App/store/booking'
import respHandler from 'App/utils/responseHandler'
import { UPDATE_GENERATED_DOCUMENT_STATUS } from '../../schema'
import { DocumentGeneratorFormHandler } from '../EditAction/DocumentCreatorModal/ModalFooter/utils'

interface DeleteActionProps {
  generatedDocument: any
  onDelete: () => void
}

const DeleteAction = (props: DeleteActionProps) => {
  const { generatedDocument, onDelete } = props

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const client = useClient()

  const documentGeneratorDataForm = useBookingStore.use.documentGeneratorDataForm()

  const [changeDocumentCreatorStatus] = useMutation(UPDATE_GENERATED_DOCUMENT_STATUS, {
    client
  })

  const handleConfirm = async () => {
    try {
      setLoading(true)

      const formHandler = new DocumentGeneratorFormHandler(
        generatedDocument,
        null,
        documentGeneratorDataForm
      )

      await formHandler.updateGeneratedDocumentStatus(changeDocumentCreatorStatus, 'VOID')

      onDelete()
    } catch (error) {
      respHandler(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Popconfirm
      title={t('booking.documentGenerator.documentDeleteConfirmation')}
      onConfirm={handleConfirm}
      okText={t('common.yes')}
      okType="danger"
      cancelText={t('common.no')}
    >
      <Button type="danger" loading={loading}>
        {t('common.delete')}
      </Button>
    </Popconfirm>
  )
}

export default DeleteAction
