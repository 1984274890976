import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import { Form, Select } from 'antd'
import { compose } from 'recompose'

import { voucherTypes } from 'App/utils/labelMap'
import bookingVoucherTypesGql from './bookingVoucherTypesGql'
import extractCommonVoucherTypes from './extractCommonVoucherTypes'

const enhance = compose(withApollo)

const ModalContent = class extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      error: null,
      data: []
    }
  }

  getData = async () => {
    const { voucher, client } = this.props

    try {
      const promises = voucher.bookings.map(b => {
        return client.query({
          query: bookingVoucherTypesGql,
          variables: {
            uuid: b.uuid
          }
        })
      })

      const results = await Promise.all(promises)
      const bookings = results.map(r => r.data.booking)
      const data = extractCommonVoucherTypes(bookings)

      this.setState({
        loading: false,
        data
      })
    } catch (error) {
      console.log(error)
      this.setState({
        loading: false,
        error
      })
    }
  }

  async componentDidMount() {
    this.getData()
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form

    if (this.state.loading) {
      return <div>Loading...</div>
    }

    if (this.state.error) {
      return <div>An error occurred. Please try again later.</div>
    }

    if (this.state.data.length === 0) {
      return (
        <div>
          <p>
            No supported voucher types that can be changed to. Please check the bookings in your
            voucher.
          </p>
        </div>
      )
    }

    return (
      <div>
        <p>
          Based on the bookings in your voucher, you can change the voucher type to one of the
          following:
        </p>
        <Form>
          <Form.Item>
            {getFieldDecorator('type')(
              <Select>
                {this.state.data.map(v => {
                  return (
                    <Select.Option key={v}>
                      {(voucherTypes[v] && voucherTypes[v].text) || v}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          </Form.Item>
        </Form>
      </div>
    )
  }
}

export default enhance(ModalContent)
