import { withRouter } from 'react-router-dom'
import { Card, Col, List, Row } from 'antd'
import { compose } from 'recompose'
import styled from 'styled-components'

import { EmptyResults, ResultCount } from '../Styled'
import BookingStatusIcon from './BookingStatusIcon'
import ContainerNos from './ContainerNos'
import EtaEtd from './EtaEtd'
import ShipperConsignee from './ShipperConsignee'
import ShortNoLink from './ShortNoLink'

const enhance = compose(withRouter)

const StyledDiv = styled.div`
  padding: 0 8px;
  margin-bottom: 16px;

  .ant-card:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }

  .ant-card-body {
    padding: 8px;
  }

  .ant-list-pagination {
    margin-top: 12px;
  }
`

const handleClick = props => booking => () => {
  const { history } = props

  history.push(`/bookings/${booking.uuid}`)
}

const handlePageChange = props => (page, pageSize) => {
  const { refine } = props
  refine(page)
}

const getRenderItem = props => booking => {
  if (!booking.details) {
    booking.details = {}
  }

  return (
    <List.Item>
      <Card onClick={handleClick(props)(booking)}>
        <Row type="flex" justify="space-between" style={{ marginBottom: '8px' }}>
          <Col>
            <ShortNoLink booking={booking} />
            <br />
            {booking.details.bl}
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <EtaEtd booking={booking} />
            <br />
            <BookingStatusIcon booking={booking} />
          </Col>
        </Row>
        <div>
          <ShipperConsignee booking={booking} />
        </div>
        <div>
          <ContainerNos booking={booking} />
        </div>
      </Card>
    </List.Item>
  )
}

const BookingsList = props => {
  const { searchResults } = props

  if (!searchResults) {
    return null
  }

  const results =
    (searchResults &&
      searchResults.hits.map((hit, index) => ({
        key: index,
        ...hit
      }))) ||
    []

  if (!results.length) {
    return <EmptyResults>Couldn&apos;t find any bookings.</EmptyResults>
  }

  const pagination = {
    pageSize: searchResults.hitsPerPage,
    total: searchResults.nbHits,
    current: searchResults.page + 1,
    size: 'small',
    onChange: handlePageChange(props)
  }

  return (
    <>
      <ResultCount>Total found : {searchResults.nbHits}</ResultCount>
      <StyledDiv>
        <List
          dataSource={results}
          grid={{
            gutter: 8,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3
          }}
          size="small"
          renderItem={getRenderItem(props)}
          pagination={pagination}
        />
      </StyledDiv>
    </>
  )
}

export default enhance(BookingsList)
