import { Button, Modal } from 'antd'

import RequestItemResultModalContent from './RequestItemResultModalContent'

const RequestItemResultModal = props => {
  const { visible, onClose, request } = props
  return (
    <Modal
      title="Request Status"
      destroyOnClose
      visible={visible}
      cancelText={null}
      okText="Close"
      footer={[
        <Button key="close" type="primary" onClick={onClose}>
          Close
        </Button>
      ]}
      onOk={onClose}
      onCancel={onClose}
    >
      <RequestItemResultModalContent request={request} />
    </Modal>
  )
}

export default RequestItemResultModal
