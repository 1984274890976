import 'App/libs/i18n' // initialize i18n

import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import AppRouter from 'App/AppRouter'
import { Auth0ProviderWithHistory } from 'App/Auth0ProviderWithHistory'
import CacheBuster from 'App/components/CacheBuster'
import initRedux from 'App/libs/initRedux'

const store = initRedux()

function App() {
  return (
    <CacheBuster>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <Provider store={store}>
            <AppRouter />
          </Provider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </CacheBuster>
  )
}

export default App
