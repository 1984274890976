import { compose, graphql } from 'react-apollo'
import { Form, Modal } from 'antd'
import PropTypes from 'prop-types'
import { withHandlers, withState } from 'recompose'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import { ROLLOVER_JOB } from 'App/graphql/booking'
import respHandler from 'App/utils/responseHandler'
import RolloverForm from './RolloverForm'

const withGraphqlRolloverJob = graphql(ROLLOVER_JOB, {
  props: ({ mutate, ownProps }) => ({
    rolloverJob: (jobUuid, bookingUuid) =>
      mutate({
        variables: {
          jobUuid,
          bookingUuid: bookingUuid === '' ? undefined : bookingUuid
        },
        refetchQueries: [
          {
            query: JOBS_FROM_BOOKING_SLIM_QUERY,
            variables: {
              uuid: ownProps.job.bookingUuid
            }
          }
        ]
      })
  })
})

const loadingState = withState('loading', 'setLoading', false)

const handlers = {
  onModalOk: props => event => {
    const { setLoading, form } = props
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)

        try {
          await props.rolloverJob(values.jobUuid, values.bookingUuid)
          setLoading(false)

          respHandler('Job rollover successful.', 'success')

          props.onClose()
        } catch (error) {
          setLoading(false)
          console.log(error)
          respHandler(error, 'error')
        }
      }
    })
  }
}

const enhance = compose(Form.create(), withGraphqlRolloverJob, loadingState, withHandlers(handlers))

const RolloverModal = props => {
  const { loading, job, form, onClose, onModalOk } = props

  return (
    <Modal
      title={`Rollover Job: ${job.no || ''}`}
      visible
      confirmLoading={loading}
      okText="Rollover"
      onCancel={onClose}
      onOk={onModalOk}
    >
      <RolloverForm form={form} job={job} />
    </Modal>
  )
}

RolloverModal.propTypes = {
  form: PropTypes.object,
  onModalOk: PropTypes.func,

  onClose: PropTypes.func,
  job: PropTypes.object
}

export default enhance(RolloverModal)
