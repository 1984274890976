import { compose } from 'recompose'

import SignIn from 'App/components/Auth/SignIn'
import AuthLayout from 'App/components/Layouts/Auth'
import Auth0 from 'App/hocs/auth0'
import withLogin from 'App/hocs/login'

const SignInPage = props => {
  return (
    <AuthLayout>
      <SignIn {...props} />
    </AuthLayout>
  )
}

export default compose(Auth0, withLogin)(SignInPage)
