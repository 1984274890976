import { memo } from 'react'
import { Avatar, Tooltip } from 'antd'

const UserIcon = ({ user: { user } }) => {
  return (
    <Tooltip title={`${user.nickname} <${user.email}>`}>
      <Avatar src={user.picture} size={16} style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}

export default memo(UserIcon)
