import { useEffect } from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import { startCase } from 'lodash'
import { compose } from 'recompose'

import Booking from 'App/components/Booking'
import StandardLayout from 'App/components/Layouts/Standard'
import bootstrap from 'App/hocs/bootstrap'

const BookingPage = props => {
  const location = useLocation()

  useEffect(() => {
    const length = location.pathname.split('/').length
    const pathname = location.pathname.split('/')
    let title
    if (length === 3 && pathname?.[1] === 'bookings') {
      title = pathname?.[1]?.replace('-', ' ')
    }
    document.title = `ShipX ${title ? '|' : ''} ${startCase(title)}`
  }, [location])

  return (
    <StandardLayout {...props}>
      <Booking {...props} />
    </StandardLayout>
  )
}

export default compose(bootstrap, withRouter)(BookingPage)
