import { StyledText } from './Styled'

const UnitSizeContainer = props => {
  const { unit, size, container } = props
  const textArr = []

  if (size) {
    const calculatedSize = !(size % 1) ? Number(size) : Number(size).toFixed(2)
    textArr.push(calculatedSize)
  }

  if (unit) {
    textArr.push(unit)
  }

  if (container) {
    textArr.push(container)
  }

  if (!textArr.length) {
    textArr.push('-')
  }

  const text = textArr.filter(Boolean).join('/')

  return <StyledText>{text}</StyledText>
}

export default UnitSizeContainer
