import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type persistedBookingData = {
  bookingData: any
  setBookingData: (data: any) => void
}

export const usePersistedBookingData = create<persistedBookingData>()(
  persist(
    set => ({
      bookingData: {},
      setBookingData: data => set({ bookingData: data })
    }),
    {
      name: 'booking-data'
    }
  )
)
