import Dropzone from 'react-dropzone'
import styled from 'styled-components'

export default styled(Dropzone)`
  vertical-align: middle;
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
  border: 2px dashed rgb(200, 200, 200);
  border-radius: 5px;
  background: white;
  display: inline-block;
  width: 200px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    transition: 0.4s;
    border-color: #1890ff;
  }
`
