import { Icon, Table, Tooltip } from 'antd'

import useGlobalCompanyStore from 'App/store/globalCompany'
import { getRef } from 'App/utils/booking'

const JobTable = (props: any) => {
  const { bookings, onHandleJobSelected, loading } = props
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const type = selectedGlobalCompany.types && selectedGlobalCompany.types[0]

  if (loading) {
    return <>Loading...</>
  }

  if (!bookings) {
    return <>No Bookings Found.</>
  }

  bookings.map((j: any) => {
    j.lastStatus = j.statuses && j.statuses[j?.statuses.length - 1]

    return j
  })

  const isProd = process.env.NODE_ENV === 'production'

  const columns = [
    {
      title: 'Add',
      key: 'add',
      width: 20,
      render: (j: any, record: any) => (
        <Icon type="plus" onClick={(e: any) => onHandleJobSelected(j)} />
      )
    },
    {
      title: 'No',
      key: 'noshipperRef',
      width: isProd ? 45 : 60,
      ellipsis: true,
      render: (j: any, record: any) => {
        return (
          <a target="_bookings" href={`/bookings/${j.uuid}`}>
            {getRef(type, j)}
          </a>
        )
      }
    },
    {
      title: 'Shipper',
      key: 'shipper',
      width: 100,
      ellipsis: true,
      render: (j: any, record: any) => (
        <Tooltip placement="topLeft" title={j.shipper?.name}>
          {j.shipper?.name}
        </Tooltip>
      )
    },
    {
      title: 'Consignee',
      key: 'consignee',
      width: 100,
      ellipsis: true,
      render: (j: any, record: any) => (
        <Tooltip placement="topLeft" title={j.consignee?.name}>
          {j.consignee?.name}
        </Tooltip>
      )
    }
  ]

  return <Table rowKey={(r: any) => r.uuid} columns={columns} dataSource={bookings} size="small" />
}

export default JobTable
