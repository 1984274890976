import { Fragment } from 'react'

import TripContent from './TripContent'

const TripsContent = props => {
  const { trips, onChange } = props
  return (
    <Fragment>
      {trips.map(t => {
        return (
          <Fragment key={t.uuid}>
            <TripContent trip={t} onChange={onChange} />
          </Fragment>
        )
      })}
    </Fragment>
  )
}

export default TripsContent
