import { Col, Icon, Row } from 'antd'
import styled from 'styled-components'

const highlightStyle = `
  background: rgb(242, 244, 246);
`

export const SummaryWrapper = styled(Row)`
  padding: 10px 0;
`

export const Summary = styled(Row)`
  padding: 6px 0;
  ${props => props.state === 'highlight' && highlightStyle}
`

export const SummaryLabels = styled(Col)`
  text-align: right;
`

export const SummaryValues = styled(Col)`
  text-align: right;
`

export const LabelItem = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: black;
`

export const ValueItem = styled.div`
  font-size: 13px;
  font-weight: 500;
  padding-right: 5px;
`

export const ItemCurrency = styled.span`
  font-size: 11.5px;
  font-weight: 600;
  color: rgb(170, 170, 170);
  padding-right: 5px;
`

export const StyledWarning = styled(Icon)`
  color: #faad14;
  padding-left: 5px;
  cursor: pointer;
`
