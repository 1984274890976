import * as download from 'downloadjs'
import { Maybe } from 'graphql/jsutils/Maybe'
import { json2csv } from 'json-2-csv'
import moment from 'moment'

import { Booking } from 'App/types/graphql'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { printStringOrArray } from 'App/utils/u'

type BookingRows = {
  bookingNo: Maybe<string>
  shipperRef: Maybe<string>
  references: Maybe<string>
  departments: Maybe<string>
  tags: Maybe<string>
  billToCode: Maybe<string>
  billToName: Maybe<string>
  shipperCode: Maybe<string>
  shipperName: Maybe<string>
  consigneeCode: Maybe<string>
  consigneeName: Maybe<string>
  costCode: Maybe<string>
  costDesc: Maybe<string>
  cost: Maybe<number>
  sell: Maybe<number>
  quantity: Maybe<number>
  costTotal: Maybe<number>
  sellTotal: Maybe<number>
}

const parseRequiredDataForCsv = (bookingRows: Map<number, Booking>) => {
  const results: BookingRows[] = []

  const sortedKeys = Array.from(bookingRows.keys()).sort()

  for (const index of sortedKeys) {
    const booking = bookingRows.get(index)

    if (!booking) continue

    // @ts-expect-error
    const { billTo, shipper, consignee, no: bookingNo, details = {} } = booking

    booking.costItems?.forEach(costItem => {
      results.push({
        bookingNo,
        shipperRef: details?.shipperRef,
        references: printStringOrArray(details?.references),
        departments: printStringOrArray(details?.departments),
        tags: printStringOrArray(details?.tags),
        billToCode: billTo?.code,
        billToName: billTo?.name,
        shipperCode: shipper?.code,
        shipperName: shipper?.name,
        consigneeCode: consignee?.code,
        consigneeName: consignee?.name,
        costCode: costItem?.chargeItem?.code,
        costDesc: costItem?.chargeItem?.description,
        cost: costItem?.costRate,
        sell: costItem?.sellRate,
        quantity: costItem?.quantity,
        costTotal: costItem?.costTotal,
        sellTotal: costItem?.sellTotal
      })
    })
  }

  return results
}

const csvDownload = (err: any, csv: any) => {
  if (err) {
    logger.error('exportAllData json2csv err', err)
    return respHandler(err, 'error')
  }

  try {
    const blob = new Blob([csv], { type: 'text/csv' })
    const fileName = `${moment().format('YYYY-MM-DD_HHmm')}-Multi-Drop-Cost-Sheet.csv`
    // @ts-ignore
    download(blob, fileName)
  } catch (error) {
    logger.error('exportAllData download error', error)
    respHandler(error, 'error')
  }
}

export const exportCsv = (bookingRows: Map<number, Booking>) => {
  const arrayOfBookings = parseRequiredDataForCsv(bookingRows)

  json2csv(arrayOfBookings, csvDownload)

  respHandler('Successfully downloaded file.', 'success')
}
