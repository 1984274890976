import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'

const ResourceViewer = ({ url, fetchOptions, mimeType }) => {
  const [objectUrl, setObjectUrl] = useState('')

  useEffect(() => {
    const fetchResource = async (url, fetchOptions) => {
      const res = await fetch(url, fetchOptions)
      const blob = await res.blob()

      setObjectUrl(window.URL.createObjectURL(blob))
    }

    fetchResource(url, fetchOptions)
  }, [fetchOptions, url])

  if (objectUrl === '') {
    return (
      <div style={{ height: '100%', width: '100%', display: 'grid', alignItems: 'center' }}>
        <Spin />
      </div>
    )
  }

  const isImageType = mimeType && mimeType.startsWith('image/')

  if (isImageType) {
    return <img alt="resource_image" src={objectUrl} />
  }

  return <embed src={objectUrl} type={mimeType} width="100%" height="100%" />
}

export default ResourceViewer
