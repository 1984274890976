import { withApollo } from 'react-apollo'
import { ApolloClient } from '@apollo/client'
import { DatePicker, Input } from 'antd'
import type { Moment } from 'moment'
import moment from 'moment'

import { getDynamicFieldQuery } from 'App/components/Booking/DynamicField/helper'
import SelectWithQuery from 'App/components/Booking/DynamicField/SelectWithQuery'
import EnumSelector from 'App/components/Shared/EnumSelector'
import TagsInput from 'App/components/Shared/TagsInput/TagsInput'
import type { DynamicField, Maybe } from 'App/types/graphql'
import { DynamicFieldQuery, DynamicFieldType } from 'App/types/graphql'

interface DynamicFieldProps {
  client?: ApolloClient<any>
  field: Maybe<DynamicField>
  id?: string
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

const DynamicFieldComponent = (props: DynamicFieldProps) => {
  const { value, field, onChange, client, id, disabled = false } = props

  const renderField = (dynamicField: any) => {
    const { key, query, type, customValues, multiple, isMultiline } = dynamicField

    const renameId = id?.split('.').join('-') || id

    let sharedProps: any = {
      value,
      id: renameId,
      disabled
    }

    if (!field) {
      return <span>Dynamic Field is undefined</span>
    }

    switch (type) {
      case DynamicFieldType.Selector:
        sharedProps = {
          ...sharedProps,
          onChange
        }
        switch (query) {
          case DynamicFieldQuery.Enums:
            return (
              <EnumSelector
                client={client}
                placeholder="Select a value..."
                enumName={field.enumName}
                multiple={field.multiple}
                {...sharedProps}
              />
            )
          default: {
            if (query === DynamicFieldQuery.CustomValues && !customValues) {
              return <TagsInput placeholder={`Enter ${key}`} {...sharedProps} />
            }

            return (
              <SelectWithQuery
                fieldKey={key}
                query={getDynamicFieldQuery(field)}
                options={
                  query === DynamicFieldQuery.CustomValues
                    ? customValues?.split('\n') || []
                    : undefined
                }
                mode={
                  query === DynamicFieldQuery.CustomValues && !customValues
                    ? 'tags'
                    : multiple
                      ? 'multiple'
                      : 'default'
                }
                style={{ width: '100%' }}
                {...sharedProps}
              />
            )
          }
        }
      case DynamicFieldType.Date:
        sharedProps = {
          ...sharedProps,
          onChange: onChange ? (date: Moment) => onChange(date) : onChange,
          value: value ? moment(value) : undefined
        }

        return (
          <DatePicker
            showTime
            format={DATE_FORMAT}
            placeholder="Select a date and time"
            getCalendarContainer={trigger => trigger.parentNode}
            {...sharedProps}
          />
        )
      default:
        sharedProps = {
          ...sharedProps,
          onChange: onChange ? (e: any) => onChange(e.target.value) : onChange
        }

        return isMultiline ? (
          <Input.TextArea
            rows={4}
            autoComplete="off"
            placeholder={`Enter ${key}`}
            {...sharedProps}
          />
        ) : (
          <Input id={renameId} autoComplete="off" placeholder={`Enter ${key}`} {...sharedProps} />
        )
    }
  }

  return renderField(field)
}

export default withApollo(DynamicFieldComponent)
