import { Fragment } from 'react'
import { Icon } from 'antd'

const documentStates = {
  VERIFIED: 'check',
  UPLOADED: 'warning'
}

const StatusIcon = props => {
  const { uploadedDoc } = props
  const iconName = documentStates[uploadedDoc.status]

  if (uploadedDoc.approvals && uploadedDoc.approvals.length > 0) {
    return (
      <Fragment>
        {uploadedDoc.approvals.map(a => {
          return <Icon key={a.uuid} type="check-circle-o" />
        })}
      </Fragment>
    )
  }

  return <Icon type={iconName} />
}

export default StatusIcon
