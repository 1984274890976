import { gql } from '@apollo/client'

import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import { fragments as taxFragment } from 'App/containers/tax/fragment'
import { fragments as voucherFragment } from 'App/containers/voucher/fragment'

export default gql`
  query voucherRequiredData(
    $resource: templateResource!
    $voucherType: String!
    $bookingType: String!
  ) {
    currencies {
      rows {
        ...CurrencyDetails
      }
    }

    actionTemplate(resource: $resource, type: $voucherType, bookingType: $bookingType) {
      ... on VoucherTemplate {
        ...VoucherTemplateDetails
      }
    }

    taxes {
      rows {
        ...TaxDetails2
      }
    }
  }

  ${taxFragment.taxDetails}
  ${currencyFragment.currencyDetails}
  ${voucherFragment.voucherTemplateDetails}
`
