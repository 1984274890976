import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import { Input, InputNumber } from 'antd'

import BillingUnitSelect from 'App/components/Select/BillingUnitSelect'
import BookingSelect from 'App/components/Select/BookingSelect'
import TaxSelect from 'App/components/Select/TaxSelect'
import TransportJobSelector from 'App/components/Transport/Components/TransportJobSelector'
import { Job, JobStatus } from 'App/types/graphql'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import { isUuid } from 'App/utils/u'
import EditableForm from './EditableForm'

interface EditableFieldsProps {
  form: any
  client: any
  onCancel: any // from parent, not redux
  selectedVoucherItem: any // from parent, not redux
  dbExchangeRate: any // from parent, not redux
  handleUpdateVoucherItem: any // from parent, not redux
}

const EDIT_VOUCHER_ITEM_JOB_QUERY = gql`
  query jobsEditVoucherItem($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        uuid
        status
      }
    }
  }
`

const EditableFields = memo((props: EditableFieldsProps) => {
  const { client, form, selectedVoucherItem, dbExchangeRate, onCancel, handleUpdateVoucherItem } =
    props

  const { getFieldDecorator } = form

  const selectedVoucher = useSelector((state: any) => state.voucher.selectedVoucher)

  const [selectedBookingUuid, setSelectedBookingUuid] = useState('')
  const [selectedTax, setSelectedTax] = useState(selectedVoucherItem?.tax)

  const [getJobs, { data: jobsData, error: jobsErr }] = useLazyQuery(EDIT_VOUCHER_ITEM_JOB_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  if (jobsErr) {
    handleResponse(jobsErr, 'error')
    logger.error('EDIT_VOUCHER_ITEM_JOB_QUERY error', jobsErr)
  }

  useEffect(() => {
    // componentDidMount
    setSelectedTax(selectedVoucherItem?.tax)

    // componentWillUnmount
    return () => {
      form.resetFields()
    }
  }, [])

  useEffect(() => {
    if (isUuid(selectedVoucherItem.bookingUuid)) {
      getJobs({ variables: { bookingUuid: selectedVoucherItem.bookingUuid } })
    }
  }, [selectedVoucherItem.bookingUuid])

  const handleSubmit = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        const validJobUuidsFromBooking = jobsData?.jobs?.rows
          ?.filter((j: Job) => j.status === JobStatus.Active)
          ?.map((j: Job) => j.uuid)
        if (!validJobUuidsFromBooking?.includes(values.jobUuid)) {
          return handleResponse(
            'The selected job does not match valid jobs from the selected booking.',
            'error'
          )
        }

        const updateRequest = {
          ...values,
          uuid: selectedVoucherItem.uuid,
          currencyUuid: selectedVoucherItem.currency.uuid
        }

        if (selectedVoucher.status === 'NEW') {
          // @ts-ignore
          // const selectedJob = jobsSelection?.find((j: Job) => j.uuid === values.jobUuid) || selectedJobBooking?.jobs?.find((j: Job) => j.uuid === values.jobUuid)
          // updateRequest.job = selectedJob
          updateRequest.tax = selectedTax
        }

        await handleUpdateVoucherItem(updateRequest)

        onCancel()
      } catch (error) {
        handleResponse(error, 'error')
        logger.error('Edit voucher item handleSubmit error', error)
      }
    })
  }, [jobsData])

  const handleSelectTax = useCallback((value: any, tax: any) => setSelectedTax(tax), [])

  useEffect(() => {
    const bookingUuid = form.getFieldValue('bookingUuid')
    if (bookingUuid && selectedBookingUuid !== bookingUuid) {
      setSelectedBookingUuid(bookingUuid)

      const jobUuid = form.getFieldValue('jobUuid')
      if (jobUuid) {
        form.setFieldsValue({
          jobUuid: ''
        })
      }

      if (selectedVoucher.isCreditNote) {
        const voucherItemCnUuid = form.getFieldValue('voucherItemCnUuid')
        if (voucherItemCnUuid) {
          form.setFieldsValue({
            voucherItemCnUuid: ''
          })
        }
      }
    }
  }, [form])

  const voucherItemCols = [
    {
      label: 'Description',
      value: 'description',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.description
      },
      input: <Input.TextArea autoComplete="off" />
    },
    {
      label: 'Size',
      value: 'size',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.size
      },
      input: <Input autoComplete="off" type="number" />
    },
    {
      label: 'Billing Unit',
      value: 'unit',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.unit
      },
      input: <BillingUnitSelect />
    },
    {
      label: 'Quantity',
      value: 'quantity',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.quantity
      },
      input: <InputNumber autoComplete="off" type="number" />
    },
    {
      label: 'Base Rate',
      value: 'baseRate',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.baseRate
      },
      input: <InputNumber autoComplete="off" type="number" />
    },
    {
      label: 'Ex. Rate',
      value: 'exchangeRate',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.exchangeRate
      },
      input: <InputNumber autoComplete="off" type="number" />
    },
    {
      label: 'Local Ex. Rate',
      value: 'localExchangeRate',
      attributes: {
        initialValue: selectedVoucherItem && selectedVoucherItem.localExchangeRate
      },
      input: <InputNumber autoComplete="off" type="number" />
    }
  ]

  if (dbExchangeRate) {
    voucherItemCols.push({
      label: 'DB Ex. Rate',
      // @ts-ignore
      nonSubmitField: true,
      input: <span>{dbExchangeRate}</span>
    })
  }

  voucherItemCols.push({
    label: 'Tax Code',
    value: 'taxUuid',
    attributes: {
      initialValue: selectedVoucherItem.tax?.uuid
    },
    //@ts-ignore
    input: <TaxSelect onChange={handleSelectTax} />
  })

  const linkJobCols = [
    {
      label: 'Booking',
      value: 'bookingUuid',
      attributes: {
        initialValue: selectedVoucherItem?.bookingUuid || selectedVoucherItem?.job?.bookingUuid,
        rules: [
          {
            required: true,
            message: 'Booking is required.'
          }
        ]
      },
      input: (
        <BookingSelect
          queryOnMount
          // onChange={handleSelectBooking}
        />
      )
    }
  ]

  if (selectedBookingUuid) {
    linkJobCols.push({
      label: 'Job',
      value: 'jobUuid',
      // @ts-ignore
      attributes: {
        initialValue: selectedVoucherItem?.job?.uuid,
        rules: [
          {
            required: true,
            message: 'Job is required.'
          }
        ]
      },
      input: <TransportJobSelector bookingUuids={[selectedBookingUuid]} />
    })

    // if (selectedVoucher.isCreditNote) {
    //   linkJobCols.push({
    //     label: 'Voucher',
    //     value: 'voucherItemCnUuid',
    //     // @ts-ignore
    //     attributes: {
    //       initialValue: selectedVoucherItem?.voucherCn?.uuid
    //     },
    //     input: (
    //       <VouchersSelect
    //         queryOnMount
    //         bookingUuid={selectedBookingUuid}
    //         excludedVouchers={[selectedVoucher.uuid]}
    //       />
    //     )
    //   })
    // }
  }

  const fields = [
    {
      cols: voucherItemCols
    },
    {
      title: 'Link Job',
      useDivider: true,
      cols: linkJobCols
    }
  ]

  return (
    <EditableForm
      fields={fields}
      onCancel={onCancel}
      handleSubmit={handleSubmit}
      getFieldDecorator={getFieldDecorator}
    />
  )
})

export default withApollo(EditableFields)
