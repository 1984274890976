import { Component } from 'react'
import find from 'lodash/find'

import DividerWithText from '../DividerWithText'
import { workflows } from './Container'
import Section from './Section'

class Workflow extends Component {
  render() {
    const { process } = this.props

    const mappedWorkflow = workflows[process.type] || { type: process.type, text: process.type }

    const hasDisplayableSection = find(process.sections, section => section.display === true)

    if (mappedWorkflow && hasDisplayableSection) {
      const { extras } = mappedWorkflow

      return (
        <div id={process.type} style={{ marginBottom: 32 }}>
          <DividerWithText text={mappedWorkflow.text} />
          {process.sections.map(section => {
            if (section.display) {
              return (
                <Section
                  key={section.name}
                  section={section}
                  extras={extras && extras[section.type]}
                  {...this.props}
                />
              )
            }
            return null
          })}
        </div>
      )
    } else {
      return <div />
    }
  }
}

export default Workflow
