import { Component } from 'react'
import { Button, Icon, Popover } from 'antd'
import { compose, withState } from 'recompose'

import BookingSelect from 'App/components/Select/BookingSelect'
import { getJobNo } from 'App/components/Transport/Utils/jobHelper'
import UserQuery from 'App/containers/user/query/user'
import { BookingItem, ListDescription, SearchControl } from './Styled'

class VoucherBookingSearch extends Component {
  handleSelectBooking = async (value, option) => {
    const { handleSelect } = this.props

    await handleSelect(value)
  }

  renderPlaceholder = () => {
    return (
      <>
        <Icon type="search" /> Search booking here...
      </>
    )
  }

  renderSearchSelect = () => {
    const { showSearchResult, setShowSearchResult, voucher } = this.props

    return (
      <SearchControl>
        <BookingSelect
          onChange={this.handleSelectBooking}
          voucher={voucher}
          showSearchResult={showSearchResult}
          setShowSearchResult={setShowSearchResult}
        />
      </SearchControl>
    )
  }

  renderContent = (voucherBookings = []) => {
    return (
      <div style={{ maxHeight: '420px', overflowY: 'auto' }}>
        <ListDescription>Associated bookings :</ListDescription>
        {voucherBookings.map((booking, index) => {
          return (
            <BookingItem key={index} count={index.toString()}>
              {getJobNo(booking)}
            </BookingItem>
          )
        })}
      </div>
    )
  }

  render() {
    const { visible, toggleVisible, voucherBookings, setShowSearchResult } = this.props

    return (
      <Popover
        title={this.renderSearchSelect()}
        trigger="click"
        visible={visible}
        content={this.renderContent(voucherBookings)}
        placement="right"
        onVisibleChange={visible => {
          toggleVisible(visible)
          setShowSearchResult(false)
        }}
      >
        <Button shape="circle" icon="plus" />
      </Popover>
    )
  }
}

export default compose(
  UserQuery,
  withState('visible', 'toggleVisible', false),
  withState('showSearchResult', 'setShowSearchResult', true)
)(VoucherBookingSearch)
