import { withRouter as _withRouter } from 'react-router-dom'
import queryString from 'query-string'

export const withRouter = WrappedComponent => {
  const RouterHOC = props => {
    const { history, location, match } = props
    const query = queryString.parse(location.search)

    const router = {
      pathname: location.pathname,
      query: { ...query, ...match.params },
      asPath: `${location.pathname}${location.search}${location.hash}`,
      push: (url, as) => {
        if (!as) return history.push(url)
        history.push(as)
      }
    }

    return <WrappedComponent {...props} router={router} />
  }

  return _withRouter(RouterHOC)
}
