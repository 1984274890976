import React, { Component } from 'react'
import { Button, Popconfirm } from 'antd'

class ConfirmationControl extends Component {
  render() {
    const { icon, title, okText, onConfirm, cancelText, buttonType, buttonText } = this.props

    return (
      <Popconfirm
        title={title}
        okText={okText}
        okType={buttonType}
        cancelText={cancelText}
        onConfirm={onConfirm}
      >
        <Button type={buttonType} icon={icon}>
          {buttonText}
        </Button>
      </Popconfirm>
    )
  }
}

export default ConfirmationControl
