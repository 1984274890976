import { Button, Col, Row } from 'antd'
import { isEmpty } from 'lodash'

import { GeneratedDocumentQuery, GeneratedDocumentStatus } from 'App/types/graphql'
import DeleteAction from '../../../DeleteAction'
// import PopulateButton from './PopulateButton'
import SaveButton from './SaveButton'
import SubmitButton from './SubmitButton'

interface ModalFooterProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
  onChange?: () => void
  onClose?: () => void
}

const ModalFooter = (props: ModalFooterProps) => {
  const { generatedDocument, onChange = () => {}, onClose = () => {} } = props

  if (
    isEmpty(generatedDocument) ||
    generatedDocument.status === GeneratedDocumentStatus.Void ||
    generatedDocument.status === GeneratedDocumentStatus.Cancelled ||
    generatedDocument.status === GeneratedDocumentStatus.Submitted
  ) {
    return (
      <Row>
        <Col span={24}>
          {/* <PopulateButton generatedDocument={generatedDocument} onChange={onChange} /> */}
          <Button onClick={onClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  if (generatedDocument.status === GeneratedDocumentStatus.Draft) {
    return (
      <Row type="flex" justify="space-between">
        <Col>
          <DeleteAction generatedDocument={generatedDocument} onDelete={onClose} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <SaveButton generatedDocument={generatedDocument} onChange={onChange} />
          <SubmitButton generatedDocument={generatedDocument} onChange={onChange} />
        </Col>
      </Row>
    )
  }

  return null
}

export default ModalFooter
