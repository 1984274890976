import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Col, Divider, Row } from 'antd'

import config from 'App/config'
import { useBookingStore } from 'App/store/booking'
import { GeneratedDocumentQuery } from 'App/types/graphql'
import AssociatedBookingsTable from './AssociatedBookingsTable'
import DataForm from './DataForm'
import DocumentViewer from './DocumentViewer'

interface ModalContentProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
}

const ModalContent = (props: ModalContentProps) => {
  const { generatedDocument } = props

  const { t } = useTranslation()
  const [renderRaw, setRenderRaw] = useState(false)

  const setDocumentGeneratorModalGeneratedDocument =
    useBookingStore.use.setDocumentGeneratorModalGeneratedDocument()
  const resetDocumentGeneratorModalGeneratedDocument =
    useBookingStore.use.resetDocumentGeneratorModalGeneratedDocument()

  useEffect(() => {
    setDocumentGeneratorModalGeneratedDocument(generatedDocument)
    return () => {
      resetDocumentGeneratorModalGeneratedDocument()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row gutter={2} type="flex" justify="space-between">
        <Col span={11}>
          <AssociatedBookingsTable generatedDocument={generatedDocument} />
        </Col>
      </Row>

      <Divider>Editor</Divider>

      <Row gutter={16} type="flex">
        <Col span={16}>
          <Row type="flex" justify="space-between" style={{ marginBottom: '8px' }}>
            <Col>
              <h3>{t('booking.documentGenerator.document')}</h3>
            </Col>
            <Col>
              <Checkbox checked={renderRaw} onChange={() => setRenderRaw(!renderRaw)}>
                Raw Preview
              </Checkbox>
            </Col>
          </Row>
          <Row>
            <DocumentViewer generatedDocument={generatedDocument} renderRaw={renderRaw} />
          </Row>
        </Col>

        <Col span={8}>
          <Row style={{ marginBottom: '8px' }}>
            <h3>{t('booking.documentGenerator.data')}</h3>
          </Row>
          <DataForm generatedDocument={generatedDocument} />
        </Col>
      </Row>
    </>
  )
}

export default ModalContent
