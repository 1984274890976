import ROTExtras from '../Booking/General/ROTSection'
import CompPostExtras from './Section/Extras/CompPostExtras'
import HaulagePostExtras from './Section/Extras/HaulagePostExtras'

export const workflows = {
  impDoc: {
    type: 'impDoc',
    text: 'Import Documentation'
  },
  impLiner: {
    type: 'impLiner',
    text: 'Import Liner'
  },
  impFreight: {
    type: 'impFreight',
    text: 'Import Freight'
  },
  impCust: {
    type: 'impCust',
    text: 'Import Custom'
  },
  impHlg: {
    type: 'impHlg',
    text: 'Import Haulage',
    extras: {
      pre: ROTExtras,
      post: HaulagePostExtras
    }
  },
  impComp: {
    type: 'impComp',
    text: 'Import Completion',
    extras: {
      post: CompPostExtras
    }
  },
  expDoc: {
    type: 'expDoc',
    text: 'Export Documentation'
  },
  expHlg: {
    type: 'expHlg',
    text: 'Export Haulage',
    extras: {
      pre: ROTExtras,
      post: HaulagePostExtras
    }
  },
  expLiner: {
    type: 'expLiner',
    text: 'Export Liner'
  },
  expFreight: {
    type: 'expFreight',
    text: 'Export Freight'
  },
  expCust: {
    type: 'expCust',
    text: 'Export Custom'
  },
  expComp: {
    type: 'expComp',
    text: 'Export Completion',
    extras: {
      post: CompPostExtras
    }
  },
  transHlg: {
    type: 'transHlg',
    text: 'Transport'
    // extras: {
    //   pre: HaulagePostExtras
    // }
  },
  transComp: {
    type: 'transComp',
    text: 'Transport Completion',
    extras: {
      post: CompPostExtras
    }
  },
  others: {
    type: 'others',
    text: ''
  }
}
