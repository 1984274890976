export enum PluginComponentLocation {
  UpdateLegTimingPopoverPost = 'LegTimingsWrapper'
}

export enum PluginComponentName {
  FuelComputation = 'FuelComputation',
  ClipboardGenerateText = 'ClipboardGenerateText'
}

export const componentLocationRecord: Record<PluginComponentName, PluginComponentLocation[]> = {
  FuelComputation: [PluginComponentLocation.UpdateLegTimingPopoverPost],
  ClipboardGenerateText: [PluginComponentLocation.UpdateLegTimingPopoverPost]
}
