import bookingDocument from './bookingDocument'
import chargeItem from './chargeItem'
import costItem from './costItem'
import currency from './currency'
import globalCompany from './globalCompany'
import manageCompany from './manage/company'
import newBooking from './newBooking'
import page from './page'
import user from './user'
import voucher from './voucher'

export default {
  globalCompany,
  costItem,
  manageCompany,
  currency,
  chargeItem,
  voucher,
  page,
  newBooking,
  bookingDocument,
  user
}
