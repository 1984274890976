import React, { useState } from 'react'
import { Button, Col, Divider, Form, Row } from 'antd'
import { compose, withHandlers, withState } from 'recompose'

import withGqlUpdateCostItem from 'App/containers/costItem/mutation/updateCostItem'
import respHandler from 'App/utils/responseHandler'
import ChangeChargeItem from './ChangeChargeItem'
import CostItemForm from './CostItemForm'
import DeleteCostItemAction from './DeleteCostItemAction'

const loadingState = withState('loading', 'setLoading', false)

const handlers = withHandlers({
  handleUpdate: props => () => {
    const { form, costItem, updateCostItem, setLoading, onCostItemEdited } = props

    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true)
        respHandler('Updating cost item, hang on...', 'load')
        const updatevalues = {
          ...values,
          size: values.size ? values.size.toString() : null
        }
        try {
          await updateCostItem(updatevalues, costItem.bookingUuid)

          setLoading(false)
          respHandler('Updated successfully.', 'success')

          if (onCostItemEdited) {
            onCostItemEdited(updatevalues, costItem.bookingUuid)
          }
        } catch (error) {
          setLoading(false)
          respHandler(error, 'error')
          console.log(error)
        }
      }
    })
  },
  handleDeleted: props => costItem => {
    const { onCostItemDeleted = () => {} } = props
    onCostItemDeleted(costItem)
  }
})

const enhance = compose(withGqlUpdateCostItem, Form.create(), loadingState, handlers)

const EditCostItemForm = props => {
  const { form, costItem, loading, handleUpdate, handleDeleted, onCostItemEdited } = props
  const [isChangeMode, setIsChangeMode] = useState(false)

  const handleToggleChange = () => {
    setIsChangeMode(!isChangeMode)
  }
  return (
    <>
      {!isChangeMode && <CostItemForm mode="edit" form={form} costItem={costItem} />}

      {isChangeMode && <ChangeChargeItem costItem={costItem} onUpdate={onCostItemEdited} />}
      <Divider style={{ margin: '15px 0' }} />
      <Row>
        <Col span={12}>
          {!isChangeMode && (
            <DeleteCostItemAction costItem={costItem} onCostItemDeleted={handleDeleted} />
          )}
          &nbsp;
          <Button
            type="default"
            loading={loading}
            onClick={handleToggleChange}
            disabled={costItem?.isDeleted}
          >
            {!isChangeMode ? 'Change Charge Item' : 'Edit cost item'}
          </Button>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          {!isChangeMode && (
            <Button
              type="primary"
              loading={loading}
              onClick={handleUpdate}
              disabled={costItem?.isDeleted}
            >
              {costItem?.isDeleted ? 'Deleted' : 'Update'}
            </Button>
          )}
        </Col>
      </Row>
    </>
  )
}

export default enhance(EditCostItemForm)
