import React, { Component } from 'react'
import { Col, Row } from 'antd'

import FAIcon from 'App/components/Icons/FA'
import { Selector, SelectorText } from './Styled'

class VoucherTypeSelector extends Component {
  render() {
    // currently voucherTransaction value is ACCREC and ACCPAY
    const { text, icon, handleClick, voucherTransaction } = this.props

    return (
      <Selector id={`voucher-option-${voucherTransaction}`} onClick={handleClick}>
        <Row>
          <Col span={3}>
            <FAIcon type={icon} />
          </Col>
          <Col span={21}>
            <SelectorText>{text}</SelectorText>
          </Col>
        </Row>
      </Selector>
    )
  }
}

export default VoucherTypeSelector
