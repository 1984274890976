import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Button, Card, Col, Form, notification, Popconfirm, Row, Skeleton } from 'antd'
import includes from 'lodash/includes'

import VoucherApprovals from '../Approvals'
import ConfirmationControl from '../Confirmation'
import ModalHeader from '../ModalHeader'
import { Aligner, LoadingPanel, StyledModal } from '../Styled'
import VoucherView from '../View'

class ModalViewVoucher extends Component {
  renderCancelControl = () => {
    const { t } = this.props
    return <Button onClick={this.props.toggleVoucherView}>{t('common.close')}</Button>
  }

  renderCreateControl = () => {
    return (
      <Button onClick={() => this.props.handleSubmit(false)} loading={this.props.submitting}>
        Create
      </Button>
    )
  }

  onSubmit = action => {
    const { selectedVoucher } = this.props
    const zeroPriceItems = Array.from(
      new Set(
        selectedVoucher.voucherItems
          .filter(c => c.baseRate === 0 && !c.isDeleted)
          .map(item => item.costItem.chargeItem.code)
      )
    )

    if (zeroPriceItems.length > 0) {
      return notification.error({
        message: 'Error',
        description: `There are items with zero price : ${zeroPriceItems.join(', ')}, please update the price before submitting the voucher`,
        duration: 2
      })
    } else {
      action()
    }
  }

  renderCreateAndSubmitControl = () => {
    const { t, submitting } = this.props
    return (
      <Button
        id="submit-invoice-button"
        onClick={() => this.onSubmit(() => this.props.handleSubmit(true))}
        type="primary"
        loading={submitting}
      >
        {t('voucher.createAndSubmit')}
      </Button>
    )
  }

  renderSubmitControl = () => {
    const { t, submitting } = this.props
    return (
      <Button
        onClick={() => this.onSubmit(() => this.props.updateVoucherStatus('SUBMITTED'))}
        type="primary"
        loading={submitting}
      >
        {t('voucher.submitForApproval')}
      </Button>
    )
  }

  renderRevertToDraftControl = () => {
    const { t } = this.props
    return (
      <Popconfirm
        title="All approvals and linked items will be lost, confirm revert?"
        onConfirm={() => this.props.updateVoucherStatus('DRAFT')}
        okText="Revert"
        placement="topLeft"
      >
        <Button type="danger">{t('voucher.revertToDraft')}</Button>
      </Popconfirm>
    )
  }

  renderDeleteControl = () => {
    return (
      <ConfirmationControl
        buttonType="danger"
        buttonText="Delete"
        title="Permanently delete this voucher?"
        okText="Delete"
        cancelText="Cancel"
        onConfirm={() =>
          this.props.updateVoucherStatus('DELETED', { load: 'Deleting', success: 'Deleted' })
        }
      />
    )
  }

  renderVoidControl = () => {
    return (
      <ConfirmationControl
        buttonType="danger"
        buttonText="Void"
        title="Permanently void this voucher?"
        okText="Void"
        cancelText="Cancel"
        onConfirm={() =>
          this.props.updateVoucherStatus('VOIDED', { load: 'Voiding', success: 'Voided' })
        }
      />
    )
  }

  renderControls = () => {
    const { selectedVoucher } = this.props

    const statuses = {
      NEW: ['CLOSE', 'CREATE', 'CREATE_SUBMIT'],
      DRAFT: ['CLOSE', 'DELETE', 'SUBMIT'],
      SUBMITTED: ['CLOSE', 'REVERT_TO_DRAFT'],
      APPROVED: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      PARTIALLY_PAID: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      PAID: ['CLOSE', 'VOID', 'REVERT_TO_DRAFT'],
      DELETED: ['CLOSE'],
      VOIDED: ['CLOSE']
    }

    const currentControls = statuses[selectedVoucher.status]

    return currentControls?.map(ctrl => (
      <React.Fragment key={ctrl}>{this.mapControl(ctrl)}</React.Fragment>
    ))
  }

  mapControl = ctrl => {
    switch (ctrl) {
      case 'CREATE':
        return this.renderCreateControl()
      case 'CREATE_SUBMIT':
        return this.renderCreateAndSubmitControl()
      case 'DELETE':
        return this.renderDeleteControl()
      case 'REVERT_TO_DRAFT':
        return this.renderRevertToDraftControl()
      case 'VOID':
        return this.renderVoidControl()
      case 'SUBMIT':
        return this.renderSubmitControl()
      default:
        return this.renderCancelControl()
    }
  }

  renderFooter = () => {
    const { selectedVoucher, approveVoucher } = this.props
    if (!selectedVoucher) return <Skeleton />

    const status = selectedVoucher.status
    const approvedByUser = selectedVoucher.approvedBy || {}
    const userName =
      approvedByUser.name ||
      approvedByUser.givenName ||
      approvedByUser.nickname ||
      approvedByUser.email

    return (
      <Aligner align="left">
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12}>
            {!includes(['NEW', 'DRAFT', 'DELETED', 'VOIDED'], status) && (
              <VoucherApprovals
                onApproveVoucher={approveVoucher}
                submitting={this.props.submitting}
              />
            )}
          </Col>
          <Col span={6}>{userName && <span>Approved By: {userName}</span>}</Col>
          <Col span={6}>
            <Aligner align="right">{this.renderControls()}</Aligner>
          </Col>
        </Row>
      </Aligner>
    )
  }

  renderView = () => {
    const { loading } = this.props

    if (loading) {
      return (
        <LoadingPanel>
          <Card loading bordered={false} />
        </LoadingPanel>
      )
    } else {
      return <VoucherView {...this.props} />
    }
  }

  render() {
    const {
      loading,
      visible,
      bookingQuery,
      fullBookingQuery,
      loadRequiredData,
      toggleVoucherView,
      handleCreateCreditNote
    } = this.props

    return (
      <StyledModal
        title={null}
        width="97%"
        footer={loading ? null : this.renderFooter()}
        closable={false}
        visible={visible}
        onCancel={toggleVoucherView}
        destroyOnClose
      >
        <Form>
          <ModalHeader
            onCancel={toggleVoucherView}
            bookingQuery={bookingQuery}
            fullBookingQuery={fullBookingQuery}
            loadRequiredData={loadRequiredData}
            handleCreateCreditNote={handleCreateCreditNote}
          />
          {this.renderView()}
        </Form>
      </StyledModal>
    )
  }
}

export default withTranslation()(ModalViewVoucher)
