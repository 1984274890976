import { gql } from '@apollo/client'

export const exchangeRatesQuery = gql`
  query getExchangeRates2(
    $limit: Int
    $offset: Int
    $fromUuid: UUID
    $toUuid: UUID
    $date: DateTime
  ) {
    exchangeRates(
      limit: $limit
      offset: $offset
      fromUuid: $fromUuid
      toUuid: $toUuid
      date: $date
    ) {
      rows {
        uuid
        from {
          uuid
          code
          name
        }
        to {
          uuid
          code
          name
        }
        start
        end
        rate
      }
    }
  }
`

export const quotationFromBookingQuery = gql`
  query getBookingQuotation($uuid: UUID!) {
    booking(uuid: $uuid) {
      quotation {
        quotationItems {
          uuid
          chargeItem {
            uuid
            name
            code
          }
          unit
          size
          containerType
          rateType
          sellCurrency {
            uuid
            code
            name
          }
          sellRate
          sellTax {
            uuid
            name
            code
            percentage
          }
          costRate
          costTax {
            uuid
            name
            code
            percentage
          }
          costCurrency {
            uuid
            code
            name
          }
          minChargeAmount
          maxChargeAmount
          tags
          description
        }
      }
    }
  }
`

export const BOOKING_IN_VOUCHER_ITEM_QUERY = gql`
  query bookingInVoucherItem1($uuid: UUID!) {
    booking(uuid: $uuid) {
      uuid
      no
    }
  }
`
export const estimateCostItemQuery = gql`
  query estimateCostItem($bookingUuid: UUID, $chargeItemUuid: UUID) {
    estimateCostItem(bookingUuid: $bookingUuid, chargeItemUuid: $chargeItemUuid) {
      sellBaseRate
      sellRate
      sellExchangeRate
      sellTotal
      sellCurrency {
        uuid
      }
      costBaseRate
      costRate
      costExchangeRate
      costTotal
      costCurrency {
        uuid
      }
    }
  }
`
