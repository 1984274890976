import { Children } from 'react'
import { Col, Row } from 'antd'

const ButtonLayout = props => {
  const { children = [] } = props

  if (children.length === 0) {
    return null
  }

  return (
    <Row type="flex" style={{ marginTop: '15px' }} justify="end" align="middle" gutter={8}>
      {Children.map(children, (el, idx) => (
        <Col key={idx}>{el}</Col>
      ))}
    </Row>
  )
}

export default ButtonLayout
