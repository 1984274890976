import uniq from 'lodash/uniq'

const getVoucherTypesFromBooking = booking => {
  const data = []

  booking.processFlow.forEach(pf => {
    pf.sections.forEach(s => {
      if (!s.vouchers) {
        return
      }

      s.vouchers.forEach(v => {
        data.push(v.type)
      })
    })
  })

  return data
}

const extractCommonVoucherTypes = bookings => {
  const data = getVoucherTypesFromBooking(bookings[0])

  for (let i = 1; i < bookings.length; i++) {
    const booking = bookings[i]
    const tempData = getVoucherTypesFromBooking(booking)

    for (let j = 0; j < data.length; j++) {
      const d = data[j]

      if (!tempData.includes(d)) {
        data.splice(j, 1)
        j -= 1
      }
    }
  }

  return uniq(data)
}

export default extractCommonVoucherTypes
