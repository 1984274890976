import styled from 'styled-components'

export const StyledPageHeader = styled.div`
  font-size: 25.222px;
  font-weight: bold;
  padding: ${props => (props.type === 'normal' ? '10px 0' : '5px 0')};
  color: black;
  letter-spacing: 0.1px;
  margin: ${props => (props.type === 'normal' ? '30px 0 10px' : '0')};
  text-align: left;

  .ant-layout-sider-children {
    margin-top: 0;
  }

  .ant-row-flex-middle {
    height: 100%;
  }
`

export const Subtitle = styled.div`
  font-size: 13.222px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: rgb(160, 162, 164);
`
