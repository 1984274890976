import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useLazyQuery } from '@apollo/client'
import { Select, Typography } from 'antd'
import { debounce } from 'lodash'

import { logger } from 'App/utils/logger'

export const documentCreatorTemplatesGql = gql`
  query documentCreatorTemplates1(
    $q: String
    $limit: Int
    $offset: Int
    $statuses: [DocumentCreatorTemplateStatus]
    $byCompany: UUID
  ) {
    documentCreatorTemplates(
      q: $q
      limit: $limit
      offset: $offset
      statuses: $statuses
      byCompany: $byCompany
    ) {
      rows {
        uuid
        type
        status
        template
        companies {
          uuid
          name
        }
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

const DocumentCreatorTemplateSelect = forwardRef((props: any, ref) => {
  const {
    client,
    value,
    onChange,
    disabled = false,
    statuses = ['activated', 'deleted'],
    filter,
    queryOnMount = false,
    byCompany
  } = props
  const [templates, setTemplates] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const [byCompanyFilter, setByCompanyFilter] = useState(byCompany)

  const params: any = useMemo(() => {
    let queryParams: any = {
      q: searchInput ? filter + ':' + searchInput : filter,
      statuses
    }

    if (byCompanyFilter) queryParams = { ...queryParams, byCompany: byCompanyFilter }

    return queryParams
  }, [value, searchInput, byCompanyFilter])

  const [getDocumentCreatorTemplates, { data, error, loading }] = useLazyQuery(
    documentCreatorTemplatesGql,
    {
      client,
      variables: { ...params }
    }
  )

  useEffect(() => {
    if (byCompanyFilter) {
      const variables = { q: undefined, statuses: undefined }

      getDocumentCreatorTemplates({ variables })
    }

    if (queryOnMount || value) {
      getDocumentCreatorTemplates()
    }
  }, [])

  useEffect(() => {
    const dataRows = data?.documentCreatorTemplates?.rows

    setTemplates(dataRows)

    if (!searchInput && byCompanyFilter && dataRows?.length === 0) {
      setByCompanyFilter(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleSearch = useCallback(
    debounce((userInput: string) => {
      setSearchInput(userInput)
      getDocumentCreatorTemplates()
    }, 500),
    []
  )

  if (error) {
    logger.error('DocumentCreatorTemplateSelect documentCreatorTemplatesGql error', error)
    return <Typography.Text>Error while loading DocumentCreatorTemplateSelect.</Typography.Text>
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return

    getDocumentCreatorTemplates()
  }

  return (
    <Select
      id="invoice-document-type-selector"
      allowClear
      showSearch
      value={value}
      loading={loading}
      onFocus={fetchOnFocus}
      onChange={onChange}
      disabled={disabled}
      filterOption={false}
      placeholder="Select a template..."
      onSearch={userInput => handleSearch(userInput)}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {templates?.map((template: any) => (
        <Select.Option key={template.uuid} value={template.uuid}>
          {template.type}
        </Select.Option>
      ))}
    </Select>
  )
})

export default withApollo(memo(DocumentCreatorTemplateSelect))
