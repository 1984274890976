import { Component } from 'react'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { bindActionCreators, compose } from 'redux'

import voucherBookingSchema from 'App/containers/bulk/voucherBookingData/schema'
import * as voucherActions from 'App/states/reducers/voucher'
import handleResp from 'App/utils/responseHandler'

export default WrappedComponent => {
  class WithVoucherBookings extends Component {
    handleSelect = async bookingUuid => {
      const {
        client,
        selectedVoucher,
        voucherBookings,
        updateSelectedVoucher,
        updateVoucherBookings,
        selectedVoucherTemplate
      } = this.props

      const selectedVoucherBooking = find(voucherBookings, booking => booking.uuid === bookingUuid)

      if (!selectedVoucherBooking) {
        try {
          const { data } = await client.query({
            fetchPolicy: 'network-only',
            query: voucherBookingSchema,
            variables: {
              bookingUuid
            }
          })

          if (data?.booking) {
            const selectableDocuments = filter(
              data.booking.bookingDocuments,
              bDoc =>
                findIndex(selectedVoucherTemplate.bookingDocuments, vDoc => vDoc === bDoc.type) !==
                -1
            )

            const updatedVoucher = { ...selectedVoucher }
            const updatedVoucherBookings = [...voucherBookings]

            updatedVoucherBookings.push({
              ...data.booking,
              costItems: data.costItems
            })

            updatedVoucher.bookingDocuments = [
              ...selectedVoucher.bookingDocuments,
              ...selectableDocuments
            ]

            updateVoucherBookings(updatedVoucherBookings)
            updateSelectedVoucher(updatedVoucher)
          }
        } catch (error) {
          handleResp(error, 'error')
        }
      }
    }

    render() {
      return <WrappedComponent {...this.props} handleSelect={this.handleSelect} />
    }
  }

  return compose(
    withApollo,
    connect(
      state => ({
        costsheetBookings: state.voucher.costsheetBookings,
        selectedVoucher: state.voucher.selectedVoucher,
        voucherBookings: state.voucher.voucherBookings,
        linkableBookings: state.voucher.linkableBookings,
        selectedGlobalCompany: state.globalCompany.selectedGlobalCompany,
        voucherBookingsLoading: state.voucher.voucherBookingsLoading,
        selectedVoucherBooking: state.voucher.selectedVoucherBooking,
        selectedVoucherTemplate: state.voucher.selectedVoucherTemplate
      }),
      dispatch => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithVoucherBookings)
}
