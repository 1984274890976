import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useQuery } from '@apollo/client'
import { Result, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import moment from 'moment'

import { NoPermissionWrapper } from 'App/components/TableView/Styled'
import { useBookingStore } from 'App/store/booking'
import { hasPermissionError } from 'App/utils/u'
import { GENERATED_DOCUMENTS_QUERY } from '../schema'
import EditAction from './EditAction'

interface TableViewProps {
  bookingUuid: string
  client: ApolloClient<any>
}

const TableView = (props: TableViewProps) => {
  const { t } = useTranslation()

  const { bookingUuid, client } = props

  const setDocumentGeneratorTableRefetch = useBookingStore.use.setDocumentGeneratorTableRefetch()
  const resetDocumentGeneratorTableRefetch =
    useBookingStore.use.resetDocumentGeneratorTableRefetch()

  const { data, loading, error, refetch } = useQuery(GENERATED_DOCUMENTS_QUERY, {
    variables: {
      groups: [bookingUuid]
    },
    client
  })

  useEffect(() => {
    setDocumentGeneratorTableRefetch(refetch)

    return () => {
      resetDocumentGeneratorTableRefetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ColumnProps<any>[] = [
    {
      key: 'number',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.number'),
      dataIndex: 'number',
      render: value => value || '-'
    },
    {
      key: 'documentTemplate',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.documentTemplate'),
      dataIndex: 'documentTemplate.code'
    },
    {
      key: 'status',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.status'),
      dataIndex: 'status'
    },
    {
      key: 'createdAt',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.createdAt'),
      dataIndex: 'createdAt',
      render: value => {
        return moment(value).format('ll h:mm A')
      }
    },
    {
      key: 'updatedAt',
      title: t('booking.documentGenerator.generatedDocumentsTableColumns.updatedAt'),
      dataIndex: 'updatedAt',
      render: value => {
        return moment(value).format('ll h:mm A')
      }
    },
    {
      key: 'action',
      title: '',
      align: 'right',
      render: (text, record) => {
        return <EditAction generatedDocumentUuid={record.uuid} refetch={refetch} />
      }
    }
  ]

  const dataSource = data?.generatedDocuments?.rows || []
  const pageInfo = data?.generatedDocuments?.pageInfo || {}

  return (
    <>
      {error && hasPermissionError(error) && (
        <NoPermissionWrapper>{t('error.permission.general')}</NoPermissionWrapper>
      )}

      {error && <Result status="warning" title={t('common.errorOccurred')} />}

      {!loading && !error && (
        <Table
          dataSource={dataSource}
          rowKey={(record: any) => record.uuid}
          columns={columns}
          loading={loading}
          size="small"
          pagination={
            pageInfo.count > pageInfo.limit
              ? {
                  total: pageInfo.count,
                  pageSize: pageInfo.limit,
                  current: pageInfo.offset / pageInfo.limit + 1,
                  onChange: (page, pageSize) => {
                    refetch({
                      // @ts-ignore
                      limit: pageSize,
                      // @ts-ignore
                      offset: (page - 1) * pageSize
                    })
                  }
                }
              : false
          }
        />
      )}
    </>
  )
}

export default TableView
