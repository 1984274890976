import { memo, useEffect, useMemo } from 'react'
import { graphql } from 'react-apollo'
import { Skeleton } from 'antd'

import Workflow from 'App/components/Workflow'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import withUpload from 'App/hocs/upload'
import { usePersistedBookingData } from 'App/store/bookingData'
import { logger } from 'App/utils/logger'
// import Schema from 'App/containers/booking/schema'
import responseHandler from 'App/utils/responseHandler'
import { hasPermissionError } from 'App/utils/u'
import { WorkflowWrapper } from './Container'

const bookingGraphql = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  name: 'bookingQuery',
  options: props => {
    return {
      variables: {
        uuid: props.bookingUuid
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  }
})

const Workflows = memo(props => {
  const { uploadActions, requisitionActions, refreshCount, bookingQuery } = props

  const workflows = useMemo(() => bookingQuery?.booking?.processFlow || [], [bookingQuery])
  const { setBookingData } = usePersistedBookingData()

  useEffect(() => {
    bookingQuery.refetch()
  }, [refreshCount])

  useEffect(() => {
    setBookingData(bookingQuery?.booking)
  }, [bookingQuery?.booking, setBookingData])
  if (!bookingQuery || bookingQuery?.loading) {
    return <Skeleton active />
  }

  if (bookingQuery?.error) {
    logger.error('Workflows BOOKING_FOR_WORKFLOWS_SLIM_QUERY Error.', bookingQuery.error, {
      bookingUuid: props.bookingUuid
    })

    if (!hasPermissionError(bookingQuery?.error)) {
      responseHandler(bookingQuery.error, 'error')
    }
  }

  return (
    <WorkflowWrapper>
      {workflows?.map(process => (
        <Workflow
          key={process.type}
          process={process}
          data={bookingQuery?.booking}
          uploadActions={uploadActions}
          booking={bookingQuery?.booking}
          refetchBooking={bookingQuery?.refetch}
          requisitionActions={requisitionActions}
        />
      ))}
    </WorkflowWrapper>
  )
})
// withUpload needs bookingQuery
export default bookingGraphql(withUpload(Workflows))
