import { memo } from 'react'
import { Icon, Result, Typography } from 'antd'
import { IconProps } from 'antd/lib/icon'
import { ResultProps } from 'antd/lib/result'

interface NoAccessProps extends ResultProps {
  iconType?: IconProps['type']
  text?: React.ReactNode | string
}

const NoAccess = (props: NoAccessProps) => {
  const { iconType = 'lock', text = 'No Access' } = props

  return (
    <Result
      {...props}
      icon={<Icon type={iconType} theme="outlined" style={{ color: '#F0F0F0' }} />}
      extra={
        <Typography.Text style={{ color: 'lightgray', fontSize: '1rem' }}>{text}</Typography.Text>
      }
    />
  )
}

export default memo(NoAccess)
