import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'

import * as voucherActions from 'App/states/reducers/voucher'

const options = [
  { label: 'Override Incomplete Documents', value: 'overrideIncompleteDocs' },
  { label: 'Override Double Voucher', value: 'overrideDoubleVoucher' },
  { label: 'Override Double Reference Number', value: 'overrideDoubleReferenceNumber' }
]

export default WrappedComponent => {
  class WithOverrides extends Component {
    handleChange = checkedValues => {
      const { selectedVoucher, updateSelectedVoucher } = this.props

      const updatedSelectedVoucher = { ...selectedVoucher }

      options.forEach(option => {
        const isSelected = checkedValues.includes(option.value)

        updatedSelectedVoucher[option.value] = isSelected
      })

      updateSelectedVoucher(updatedSelectedVoucher)
    }

    render() {
      return <WrappedComponent {...this.props} options={options} handleChange={this.handleChange} />
    }
  }

  return compose(
    connect(
      (state, props) => ({
        selectedVoucher: state.voucher.selectedVoucher
      }),
      dispatch => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithOverrides)
}
