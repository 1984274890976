import { gql } from '@apollo/client'

import exchangeRate from '../fragment/exchangeRate'

export default gql`
  query getExchangeRates(
    $limit: Int
    $offset: Int
    $fromUuid: UUID
    $toUuid: UUID
    $date: DateTime
  ) {
    exchangeRates(
      limit: $limit
      offset: $offset
      fromUuid: $fromUuid
      toUuid: $toUuid
      date: $date
    ) {
      rows {
        ...ExchangeRateDetails
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }

  ${exchangeRate}
`
