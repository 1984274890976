import { compose } from 'recompose'

import UniversalSignIn from 'App/components/Auth/SignIn/UniversalSignIn'
import AuthLayout from 'App/components/Layouts/Auth'
import Auth0 from 'App/hocs/auth0'
import withLogin from 'App/hocs/login'

const UniversalSignInPage = props => {
  return (
    <AuthLayout>
      <UniversalSignIn {...props} />
    </AuthLayout>
  )
}

export default compose(Auth0, withLogin)(UniversalSignInPage)
