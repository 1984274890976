import { useState } from 'react'
import { Card, Col, Icon, Radio, Row, Tabs } from 'antd'

import { getRef } from 'App/utils/booking'
import { NoItemsFound, StyledTabs } from '../Styled'
import BulkInvoiceByCustomer from './BulkInvoiceByCustomer'
import BulkInvoiceGenerator from './BulkInvoiceGenerator'
import withVoucherBookings from './Container'
import BookingSearch from './Search'
import TabView from './TabView'

const TabPane = Tabs.TabPane

const VoucherBookings = props => {
  const {
    updateSelectedVoucherBooking,
    selectedVoucher,
    selectedGlobalCompany,
    voucherBookings,
    onRemoveDocument,
    costsheetBookings,
    onSelectSingleCostItem,
    selectedVoucherBooking,
    onSelectSingleDocument,
    voucherBookingsLoading,
    updateCostsheetBookings,
    handleSelect
  } = props
  const [bulkEntryType, setBulkEntry] = useState('single')

  const changeBulkEntryType = e => {
    setBulkEntry(e.target.value)
  }

  const handleTabClick = activeKey => {
    if (activeKey) {
      updateSelectedVoucherBooking(activeKey)
    }
  }

  // This is a hacky fix to populate voucherBooking with booking.no.
  // Apparently voucherBooking comes from algolia and it doesn't have booking no.
  const getVoucherBookingWithNo = voucherBooking => {
    const selectedVoucherBooking =
      (selectedVoucher.bookings &&
        selectedVoucher.bookings.find(b => b.uuid === voucherBooking.uuid)) ||
      voucherBooking

    return {
      no: selectedVoucherBooking.no,
      uuid: selectedVoucherBooking.uuid
    }
  }

  const getTab = (voucherBooking, isSelected) => {
    const voucherBookingWithNo = getVoucherBookingWithNo(voucherBooking)
    const type = selectedGlobalCompany.types && selectedGlobalCompany.types[0]
    const tabTitle = getRef(type, voucherBookingWithNo)

    return (
      <span>
        <span>{tabTitle}</span>
        {isSelected && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/bookings/${voucherBookingWithNo.uuid}`}
          >
            <Icon type="eye" title="Open booking in new tab" style={{ marginLeft: '8px' }} />
          </a>
        )}
      </span>
    )
  }

  const renderTabs = () => {
    if (voucherBookingsLoading) {
      return <Card bordered={false} loading={voucherBookingsLoading} />
    }

    if (!voucherBookings.length) {
      return <NoItemsFound borderedTop>No bookings linked.</NoItemsFound>
    }

    return (
      <StyledTabs type="editable-card" hideAdd onChange={handleTabClick}>
        {voucherBookings.map(voucherBooking => {
          const tabKey =
            selectedVoucherBooking || (voucherBookings.length && voucherBookings[0].uuid)
          const isSelected = voucherBooking.uuid === tabKey

          return (
            <TabPane key={voucherBooking.uuid} tab={getTab(voucherBooking, isSelected)} closable>
              {isSelected && (
                <TabView
                  bookingUuid={voucherBooking.uuid}
                  costsheetBookings={costsheetBookings}
                  onRemoveDocument={onRemoveDocument}
                  updateCostsheetBookings={updateCostsheetBookings}
                  onSelectSingleCostItem={onSelectSingleCostItem}
                  onSelectSingleDocument={onSelectSingleDocument}
                />
              )}
            </TabPane>
          )
        })}
      </StyledTabs>
    )
  }

  const renderBulk = () => {
    return (
      <BulkInvoiceGenerator
        onSelectSingleCostItem={onSelectSingleCostItem}
        updateCostsheetBookings={updateCostsheetBookings}
      />
    )
  }
  const renderBulkCustomer = () => {
    return (
      <BulkInvoiceByCustomer
        voucher={selectedVoucher}
        onSelectSingleCostItem={onSelectSingleCostItem}
        updateCostsheetBookings={updateCostsheetBookings}
      />
    )
  }

  return (
    <>
      <Row>
        <Col span={12}>
          {bulkEntryType === 'single' && (
            <>
              Associated Bookings
              <BookingSearch
                voucher={selectedVoucher}
                handleSelect={handleSelect}
                voucherBookings={voucherBookings}
              />
            </>
          )}
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Radio.Group
            defaultValue={bulkEntryType}
            buttonStyle="solid"
            onChange={changeBulkEntryType}
          >
            <Radio.Button value="single">Single</Radio.Button>
            <Radio.Button value="customer">Customer</Radio.Button>
            <Radio.Button value="bulk">Bulk</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          {bulkEntryType === 'single' && renderTabs()}
          {bulkEntryType === 'customer' && renderBulkCustomer()}
          {bulkEntryType === 'bulk' && renderBulk()}
        </Col>
      </Row>
    </>
  )
}

export default withVoucherBookings(VoucherBookings)
