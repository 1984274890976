import { useEffect } from 'react'
import { compose, withApollo } from 'react-apollo'
import { useDispatch } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { isEmpty } from 'lodash'

import Fallback from 'App/components/Spinner/Fallback'
import ViewerContext from 'App/contexts/viewer'
import base from 'App/hocs/base'
// old redux actions
import { changeGlobalCompany, updateGlobalCompanies } from 'App/states/reducers/globalCompany'
import useGlobalCompanyStore from 'App/store/globalCompany'
import useUserStore from 'App/store/user'
import type { BaseCompany } from 'App/types/graphql'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'

const VIEWER_QUERY = gql`
  query viewerSlim3($auth0UserId: String) {
    viewer(auth0UserId: $auth0UserId) {
      email
      baseCompanies {
        uuid
        name
        types
        status
        country {
          # for shift
          alpha2
          alpha3
        }
        company {
          uuid
          name # for export
          currency {
            uuid
            code
            name
          }
        }
      }
    }
  }
`

export default WrappedComponent => {
  const WithBootstrap = props => {
    const dispatch = useDispatch()

    const cachedViewer = useUserStore.use.viewerData()
    const setViewerData = useUserStore.use.setViewerData()

    const setGlobalCompanies = useGlobalCompanyStore.use.setGlobalCompanies()
    const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

    const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const { data, error, loading } = useQuery(VIEWER_QUERY, {
      client: props.client,
      fetchPolicy: 'cache-and-network',
      variables: { auth0UserId: props.loggedInUser.sub }
    })

    // TODO: Remove this useEffect once all migrate to Zustand
    // DONT REMOVE this UseEffect. this is to make sure code that refers to redux still works
    useEffect(() => {
      if (selectedGlobalCompany) dispatch(changeGlobalCompany(selectedGlobalCompany))
      if (globalCompanies) dispatch(updateGlobalCompanies(globalCompanies))
    }, [dispatch, globalCompanies, selectedGlobalCompany])

    useEffect(() => {
      if (data?.viewer && !loading) {
        setViewerData(data.viewer)
        if (isEmpty(selectedGlobalCompany)) {
          // TODO: Auto select the previous selected company ?
          dispatch(changeGlobalCompany(data.viewer.baseCompanies[0]))
          setSelectedGlobalCompany(data.viewer.baseCompanies[0])
        } else {
          const company = data.viewer.baseCompanies.find(
            (bc: BaseCompany) => bc.uuid === selectedGlobalCompany.uuid
          )
          dispatch(changeGlobalCompany(company))
          setSelectedGlobalCompany(company)
        }
        dispatch(updateGlobalCompanies(data.viewer.baseCompanies))
        setGlobalCompanies(data.viewer.baseCompanies)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading])

    if (loading) return <Fallback />

    if (error) {
      logger.error('WithBootstrap VIEWER_QUERY Error.', error)
      handleResponse(error, 'error')
    }

    const viewer = data?.viewer || cachedViewer

    return (
      <ViewerContext.Provider value={viewer}>
        <WrappedComponent {...props} />
      </ViewerContext.Provider>
    )
  }

  return compose(base, withApollo)(WithBootstrap)
}
