import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Tooltip } from 'antd'
import { compose, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'

import * as voucherActions from 'App/states/reducers/voucher'
import respHandler from 'App/utils/responseHandler'
import { Amount, Currency, Invoice, InvoiceNo, Selector, Status, VoucherType } from './Styled'

const handlers = withHandlers({
  handleClick: props => async () => {
    const {
      voucher,
      sectionVoucher,
      toggleVoucherView,
      updateSelectedVoucher,
      updateVoucherBookings,
      isPortalUser,
      openPrintableVoucherInNewTab
    } = props

    if (isPortalUser) {
      return openPrintableVoucherInNewTab()
    }

    respHandler('Loading voucher...', 'load')

    try {
      updateSelectedVoucher({
        ...voucher,
        transactionCategory: sectionVoucher.transactionCategory
      })
      updateVoucherBookings([])
      toggleVoucherView()
    } catch (error) {
      console.log('ViewSelector error:', error)
    }

    respHandler('', 'close')
  }
})

const enhance = compose(
  connect(null, dispatch => ({
    dispatch,
    ...bindActionCreators(
      {
        ...voucherActions
      },
      dispatch
    )
  })),
  handlers
)

class ViewSelector extends Component {
  render() {
    const { voucher, voucherTypes, sectionVoucher, handleClick } = this.props
    const voucherType = voucherTypes[voucher.type]
    const total = (voucher.total || 0)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const isCreditNote = voucher.isCreditNote
    const isAp = sectionVoucher.transactionType === 'ACCPAY'

    return (
      <Fragment>
        <Selector category={sectionVoucher.transactionCategory} onClick={handleClick}>
          <Row>
            <Col span={14}>
              <Tooltip title={voucher.type}>
                <VoucherType>{(voucherType && voucherType.text) || voucher.type}</VoucherType>
              </Tooltip>
            </Col>
            <Col span={10}>
              <InvoiceNo>{voucher.voucherNumber}</InvoiceNo>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Status>
                {isCreditNote && <span>(CN)</span>}
                {voucher.status}
              </Status>
            </Col>
            <Col span={10}>
              <Invoice>
                <Currency>{voucher.currency && voucher.currency.code}</Currency>
                <Amount isAp={isAp} isCreditNote={isCreditNote}>
                  {total}
                </Amount>
              </Invoice>
            </Col>
          </Row>
        </Selector>
      </Fragment>
    )
  }
}

export default enhance(ViewSelector)
