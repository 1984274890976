import { gql } from '@apollo/client'

import { fragments as voucherFragment } from 'App/containers/voucher/fragment'

export default gql`
  mutation unlinkDocumentFromVoucher1($bookingDocumentUuid: UUID!, $voucherUuid: UUID!) {
    unlinkDocumentFromVoucher(
      bookingDocumentUuid: $bookingDocumentUuid
      voucherUuid: $voucherUuid
    ) {
      ...VoucherDetails
    }
  }

  ${voucherFragment.voucherDetails}
`
