import styled from 'styled-components'

import { getContainerStats } from 'App/utils/booking'

const getShipperConsignee = booking => {
  return [
    booking?.type === 'IMPORT' ? booking?.details?.consignee?.name : null,
    booking?.type === 'EXPORT' ? booking?.details?.shipper?.name : null
  ]
    .filter(Boolean)
    ?.join(', ')
}

const Tag = styled.div`
  color: gray;
  font-size: 12px;
`

const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = ({ booking }) => {
  const bookingNo = booking?.no

  const activeJobs = booking?.jobs?.filter(j => j.status !== 'CANCELLED')
  const statusTag = status => {
    return <Tag>{status}</Tag>
  }
  const containerStats = getContainerStats(activeJobs)
    ?.map(s => `${s.count}x${s.size} ${s.type}`)
    ?.join(', ')

  const shipperConsigneeString = getShipperConsignee(booking) || ''
  const title = `${bookingNo} - (${booking?.type || booking?.details?.shipmentType || 'NA'}) ${shipperConsigneeString ? `[${shipperConsigneeString}]` : ''}${containerStats ? ` - ${containerStats}` : ''}`

  return (
    <TitleHeader>
      <span>{title}</span>
      <span>{statusTag(booking.status)}</span>
    </TitleHeader>
  )
}

export default Title
