import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, Form, Input } from 'antd'

import i18n, { languageOptions } from 'App/libs/i18n'
import useAuthStore from 'App/store/auth'
import auth from 'App/utils/auth'
import { LightNotice, LogoDescription, OtherActions, StyledRow } from '../Styled'
import { InputWrapper, LogoWrapper, Wrapper } from './Styled'

const FormItem = Form.Item

function SignIn({ form }) {
  const { t } = useTranslation()
  const { getFieldDecorator, validateFields } = form

  const [error, setError] = useState<string>('')
  const loading = useSelector(state => state.auth?.loading)

  const setLegacyMode = useAuthStore.use.setLegacyMode()

  const handleLogin = e => {
    e.preventDefault()

    validateFields(async (err, inputs) => {
      // TODO: handle error?
      if (err) return
      const response = await auth.login(inputs)

      if (response.error) {
        setError(response.description)
      }
    })
  }

  const handleAuthError = () => setError('')

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: 10 }}>
        {languageOptions.map(({ label, value }) => {
          const isSelected = i18n.language === value
          return (
            <p
              key={value}
              onClick={() => i18n.changeLanguage(value)}
              style={{ cursor: 'pointer', color: isSelected ? '#1890FF' : '#151b26', padding: 5 }}
            >
              {label}
            </p>
          )
        })}
      </div>

      <StyledRow type="flex" justify="space-around" align="middle">
        <Col span={24} lg={8}>
          <Wrapper>
            <LogoWrapper>
              <img height={104} alt="swift haulage logo" src="/static/swiftLogoR.png" />
              <LogoDescription>
                {t('login.loginToContinue')}{' '}
                <a
                  style={{ fontSize: 12 }}
                  onClick={() => setLegacyMode(false)}
                  href="/auth/sign-in"
                >
                  {t('login.newLogin')}
                </a>
              </LogoDescription>
            </LogoWrapper>

            <InputWrapper>
              <div className="error-msg">
                {error && <span style={{ width: 100 }}>{error}</span>}
              </div>

              <Form onSubmit={handleLogin}>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: t('login.emailIsRequired') }],
                    initialValue: ''
                  })(
                    <Input
                      type="email"
                      autoComplete="off"
                      spellCheck="false"
                      placeholder={t('login.emailExample')}
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: t('login.passwordIsRequired') }],
                    initialValue: ''
                  })(
                    <Input type="password" placeholder={t('login.password')} autoComplete="off" />
                  )}
                </FormItem>

                <div style={{ paddingTop: 5 }}>
                  <Button htmlType="submit" type="primary" loading={loading}>
                    {t('login.login')}
                  </Button>
                </div>

                <OtherActions>
                  {t('login.forgotPassword')}{' '}
                  <Link to="/auth/reset-password" onClick={handleAuthError}>
                    {t('login.retrieve')}
                  </Link>
                </OtherActions>

                <OtherActions>
                  {t('login.problemLoggingIn')}{' '}
                  <Link to="/auth/sign-off" onClick={handleAuthError}>
                    {t('login.clickHere')}
                  </Link>{' '}
                  {t('login.andTryLogInAgain')}
                </OtherActions>

                <LightNotice>
                  <p>
                    {t('login.inviteSystemOnly')}
                    <br />
                    {t('login.forBestExperience')}{' '}
                    <a
                      href="https://www.google.com/chrome/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('login.googleChrome')}
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://www.mozilla.org/en-US/firefox/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {t('login.mozillaFirefox')}
                    </a>
                    . {t('login.internetExploreIsNotSupported')}
                  </p>
                </LightNotice>
              </Form>
            </InputWrapper>
          </Wrapper>
        </Col>
      </StyledRow>
    </>
  )
}

export default Form.create()(SignIn)
