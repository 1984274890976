import { graphql } from 'react-apollo'
import { gql } from '@apollo/client'
import { compose } from 'recompose'
import styled from 'styled-components'

import AddressAreaCodeChecker from './AddressAreaCodeChecker'

const addressGql = gql`
  query address1($uuid: UUID) {
    address(uuid: $uuid) {
      uuid
      name
      status
      type
      address1
      address2
      address3
      address4
      postCode
      areaCode
    }
  }
`

const addressGraphql = graphql(addressGql, {
  name: 'addressGraphql',
  options: props => ({
    variables: {
      uuid: props.addressUuid
    }
  })
})

const enhance = compose(addressGraphql)

const StyledDiv = styled.div`
  margin-left: 11px;
  margin-right: 11px;
  line-height: normal;
  font-size: 12.5px;
  color: rgb(120, 120, 120);
`

const Address = props => {
  const { addressGraphql } = props
  const { address } = addressGraphql

  if (!address) {
    return null
  }

  return (
    <StyledDiv>
      {address.address1 && <div>{address.address1}</div>}
      {address.address2 && <div>{address.address2}</div>}
      {address.address3 && <div>{address.address3}</div>}
      {address.address4 && <div>{address.address4}</div>}
      {address.postCode && <div>Post code: {address.postCode}</div>}
      {address.areaCode && <div>Area code: {address.areaCode}</div>}
      {!address.areaCode && <AddressAreaCodeChecker addressUuid={address.uuid} />}
    </StyledDiv>
  )
}

export default enhance(Address)
