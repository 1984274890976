import { gql } from '@apollo/client'

import documentCreator from '../fragment/documentCreator'

export default gql`
  mutation updateDocumentCreatorForContainer($input: UpdateDocumentCreator!) {
    updateDocumentCreator(input: $input) {
      ...DocumentCreatorDetails
    }
  }

  ${documentCreator}
`
