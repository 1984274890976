import { graphql } from 'react-apollo'
import { Button, Col, DatePicker, Divider, Form, Input, Popconfirm, Popover, Row } from 'antd'
import moment from 'moment'
import { compose, withHandlers, withState } from 'recompose'

import JobSelect from 'App/components/Select/NoQuery/JobSelect'
import TripSelector from 'App/components/Select/NoQuery/TripSelector'
import schema from 'App/containers/booking/schema'
import { REMOVE_CHRONOLOGY, UPDATE_CHRONOLOGY } from 'App/graphql/booking'
import { chronologyTypes } from 'App/utils/labelMap'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import { getJobsAndTrips } from './ChronologyAddButton'
import { IconButton } from './Styled'

const withGraphqlUpdateChronology = graphql(UPDATE_CHRONOLOGY, {
  props: ({ mutate, ownProps }) => ({
    updateChronology: params =>
      mutate({
        variables: { input: params },
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY_WORKFLOW,
            variables: {
              uuid: ownProps.bookingUuid
            }
          }
        ]
      })
  })
})

const withGraphqlRemoveChronology = graphql(REMOVE_CHRONOLOGY, {
  props: ({ mutate, ownProps }) => ({
    removeChronology: uuid =>
      mutate({
        variables: { uuid },
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY_WORKFLOW,
            variables: {
              uuid: ownProps.bookingUuid
            }
          }
        ]
      })
  })
})

const handlers = withHandlers({
  handleUpdate: props => async e => {
    e.preventDefault()

    const { updateChronology, chrono, setIsVisible, form } = props

    form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      try {
        handleResponse(`Updating ${chrono.title}, hang on...`, 'load')

        const updateObj = {
          uuid: chrono.data.uuid,
          jobUuid: values.jobUuid,
          tripUuid: values.tripUuid,
          type: chrono.type,
          date: values.chronoDatetime,
          remarks: values.remarks,
          reference: values.reference
        }
        await updateChronology(updateObj)

        setIsVisible(false)

        handleResponse('Updated successfully.', 'success')
      } catch (error) {
        logger.error('ChronologyUpdateButton updateChronology error', error)
        handleResponse(error, 'error')
      }
    })
  },
  handleRemove: props => async e => {
    const { setIsVisible, chrono, removeChronology } = props

    try {
      handleResponse(`Removing ${chrono.title}, hang on...`, 'load')

      await removeChronology(chrono.data.uuid)

      setIsVisible(false)

      handleResponse('Removed successfully.', 'success')
    } catch (error) {
      console.log(error)
      handleResponse(error, 'error')
    }
  },
  handleDatepickerChange: props => date => {
    const { mode, setMode } = props
    if (mode === 'date') {
      setMode('time')
    }
  },
  handleOpenChange: props => open => {
    const { setMode } = props
    setMode('date')
  },
  handlePanelChange: props => (value, mode) => {
    const { setMode } = props
    setMode(mode)
  }
})

const enhance = compose(
  withGraphqlUpdateChronology,
  withGraphqlRemoveChronology,
  Form.create(),
  withState('isVisible', 'setIsVisible', false),
  withState('mode', 'setMode', 'date'),
  handlers
)

const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY HH:mm'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const renderUpdateContent = props => {
  const {
    booking,
    form,
    setIsVisible,
    chrono,
    mode,
    handleUpdate,
    handleRemove,
    handleDatepickerChange,
    handleOpenChange,
    handlePanelChange
  } = props
  const { getFieldDecorator, getFieldsValue } = form

  let dateValue = moment(new Date())
  if (chrono.data && chrono.data.date) {
    dateValue = moment(chrono.data.date)
  }

  const [only1Job, only1Trip, tripsToSelect] = getJobsAndTrips(booking, getFieldsValue)

  return (
    <Form onSubmit={handleUpdate} style={{ width: '600px' }}>
      <FormItem label="Date & Time" {...formItemLayout}>
        {getFieldDecorator('chronoDatetime', {
          rules: [
            {
              required: true,
              message: 'Date time required.'
            }
          ],
          initialValue: dateValue
        })(
          <DatePicker
            showTime
            format={dateFormat}
            placeholder="Select a date and time"
            getCalendarContainer={trigger => trigger.parentNode}
            style={{ width: 200 }}
            mode={mode}
            onChange={handleDatepickerChange}
            onOpenChange={handleOpenChange}
            onPanelChange={handlePanelChange}
          />
        )}
      </FormItem>
      <FormItem label="Job" {...formItemLayout}>
        {getFieldDecorator('jobUuid', {
          initialValue: chrono?.data?.jobUuid || only1Job || undefined,
          rules: [
            {
              required: !!chrono?.isJobRequired,
              message: 'Job is required.'
            }
          ]
        })(<JobSelect jobs={booking?.jobs} />)}
      </FormItem>
      <FormItem label="Trip" {...formItemLayout}>
        {getFieldDecorator('tripUuid', {
          initialValue: chrono?.data?.tripUuid || only1Trip || undefined,
          rules: [
            {
              required: !!chrono?.isTripRequired,
              message: 'Trip is required.'
            }
          ]
        })(
          <TripSelector form={form} initialValue={chrono?.data?.tripUuid} trips={tripsToSelect} />
        )}
      </FormItem>
      <FormItem label="Reference" {...formItemLayout}>
        {getFieldDecorator('reference', {
          initialValue: chrono.data && chrono.data.reference
        })(<Input />)}
      </FormItem>
      <FormItem label="Remarks" {...formItemLayout}>
        {getFieldDecorator('remarks', {
          initialValue: chrono.data && chrono.data.remarks
        })(<Input />)}
      </FormItem>
      <Divider style={{ margin: '12px 0' }} />
      <Row>
        <Col span={6}>
          <Popconfirm
            okText="Remove"
            okType="danger"
            title="Confirm remove?"
            onConfirm={() => handleRemove(chrono)}
          >
            <Button type="danger">Remove</Button>
          </Popconfirm>
        </Col>
        <Col span={18} style={{ textAlign: 'right' }}>
          <Button onClick={() => setIsVisible(false)}>Cancel</Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
            Update
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ChronologyUpdateButton = props => {
  const { chrono, isVisible, setIsVisible } = props
  const title = chronologyTypes[chrono.title] || chrono.title

  return (
    <Popover
      trigger="click"
      content={renderUpdateContent(props)}
      placement="right"
      title={title}
      visible={isVisible}
      onVisibleChange={visible => setIsVisible(visible)}
    >
      <IconButton icon="edit" onClick={() => setIsVisible(true)} />
    </Popover>
  )
}

export default enhance(ChronologyUpdateButton)
