import { compose, graphql } from 'react-apollo'
import PropTypes from 'prop-types'

import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import JobTable from '../../../JobTable'

const withGraphqlBookingQuery = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  options: props => ({
    variables: {
      uuid: props.booking.uuid
    }
  })
})

const enhance = compose(withGraphqlBookingQuery)

const HaulagePostExtras = props => {
  const { chronologies = [] } = props.booking
  const type = props.booking.type === 'IMPORT' ? 'import' : 'export'
  const rfcChronologyType =
    props.booking.type === 'IMPORT' ? 'ImpHlgRequestForCollection' : 'ExpHlgRequestForCollection'

  const jobs = props.booking.jobs.map(job => {
    const rfcChrono =
      chronologies.find(c => c.type === rfcChronologyType && c.reference === job.no) || {}

    return {
      ...job,
      rfcDate: rfcChrono.date
    }
  })

  return <JobTable jobs={jobs} type={type} onJobChanged={props.data.refetch} />
}

HaulagePostExtras.propTypes = {
  booking: PropTypes.object
}

export default enhance(HaulagePostExtras)
