import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'

import DynamicComponentLoader from '@/components/dynamic-component-loader'
import Spinner from '@/components/Spinner'
import { GET_PLUGIN_LIST_TEST } from '@/pages/manage/plugins/schema'
import { PluginComponentLocation } from '@/plugins/options'

type PluginLoaderProps = {
  componentLocation: PluginComponentLocation[]
  componentProps: Record<string, any>
  client?: any
  children?: React.ReactNode
}

const PluginLoader = (props: PluginLoaderProps) => {
  const { client, componentLocation, componentProps, children, ...childrenProps } = props
  const { data, loading, error } = useQuery(GET_PLUGIN_LIST_TEST, {
    client,
    variables: {
      componentLocation
    },
    skip: !componentLocation?.length
  })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    return <span>{`Error while loading plugins. ${error?.message}`}</span>
  }

  return (
    <>
      {data?.plugins?.rows?.map(plugin => {
        if (!plugin?.config?.componentName) {
          return null
        }

        return (
          <DynamicComponentLoader
            client={client}
            loading={loading}
            key={plugin.id}
            componentName={plugin.config.componentName}
            {...componentProps}
          />
        )
      })}
    </>
  )
}

export default withApollo(PluginLoader)
