const CompanyLabel = props => {
  const { company } = props

  if (!company) {
    return null
  }

  const label = [company.code, company.name].filter(Boolean).join(' - ')

  return label
}

export default CompanyLabel
