import { compose, withHandlers, withState } from 'recompose'

import { StyledButton } from '../../ButtonWrapper'
import JobModal from '../JobModal'

const state = withState('isModalVisible', 'setIsModalVisible', false)

const handlers = withHandlers({
  handleClick: props => e => {
    props.setIsModalVisible(true)
  },
  handleClose: props => e => {
    props.setIsModalVisible(false)
  }
})

const enhance = compose(state, handlers)

const JobModalAction = props => {
  const { isModalVisible, handleClick, handleClose, job, onChange } = props

  return (
    <>
      <StyledButton style={{ marginLeft: '10px' }} onClick={handleClick}>
        View
      </StyledButton>
      <JobModal visible={isModalVisible} job={job} onClose={handleClose} onChange={onChange} />
    </>
  )
}

export default enhance(JobModalAction)
