import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useQuery } from '@apollo/client'
import { Alert, Button, Col, Row, Table, Typography } from 'antd'
import moment from 'moment'

import { LEG_ACTIVITIES_QUERY } from 'App/components/Transport/Schemas/schema'
import { LegActivity } from 'App/types/graphql'
import { logger } from 'App/utils/logger'

const LegActivities = memo((props: any) => {
  const { client, leg, setLegActivitiesVisible } = props
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })

  const { loading, error, data } = useQuery(LEG_ACTIVITIES_QUERY, {
    client,
    variables: {
      input: {
        legUuids: [leg?.legUuid],
        ...pagination
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  const { data: planVehicleData } = useQuery(
    gql`
      query processVehiclePlan(
        $vehicleUuid: UUID
        $baseCompanyUuid: UUID
        $start: DateTime
        $end: DateTime
        $describe: Boolean
      ) {
        processVehiclePlan(
          vehicleUuid: $vehicleUuid
          baseCompanyUuid: $baseCompanyUuid
          start: $start
          end: $end
          describe: $describe
        )
      }
    `,
    {
      client,
      variables: { vehicleUuid: leg?.vehicleUuid, baseCompanyUuid: leg?.ownerUuid, describe: true },
      skip: !leg?.vehicleUuid || !leg?.ownerUuid,
      fetchPolicy: 'cache-and-network'
    }
  )

  const onPaginationChange = useCallback(
    (current: number, pageSize?: number | undefined) => {
      setPagination({ limit: pageSize || 20, offset: (current || 1) - 1 })
    },
    [setPagination]
  )

  useEffect(() => {
    if (planVehicleData?.processVehiclePlan?.describe) {
      planVehicleData?.processVehiclePlan?.describe.forEach((d: any) => {
        // console.log(d)
      })
    }
  }, [planVehicleData])

  const paginationObj = useMemo(
    () => ({
      pageSize: pagination?.limit,
      showSizeChanger: true,
      showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
      total: data?.legActivities?.pageInfo?.count || 0,
      current: (data?.legActivities?.pageInfo?.offset || 0) + 1,
      onChange: onPaginationChange,
      onShowSizeChange: onPaginationChange,
      pageSizeOptions: ['10', '20', '50', '100']
    }),
    [data, onPaginationChange, pagination]
  )

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'expandRowButton',
        key: 'expandRowButton',
        width: 20,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Updated By',
        dataIndex: 'createdBy.email',
        key: 'email',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Updated At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'Leg Status',
        dataIndex: 'legStatus',
        key: 'legStatus',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Transporter',
        dataIndex: 'transportName',
        key: 'transportName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Driver',
        dataIndex: 'driverName',
        key: 'driverName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Vehicle',
        dataIndex: 'vehicleName',
        key: 'vehicleName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'Trailer',
        dataIndex: 'trailerName',
        key: 'trailerName',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      },
      {
        title: 'planStart',
        dataIndex: 'planStart',
        key: 'planStart',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'start',
        dataIndex: 'start',
        key: 'start',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'startOut',
        dataIndex: 'startOut',
        key: 'startOut',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'planEnd',
        dataIndex: 'planEnd',
        key: 'planEnd',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'end',
        dataIndex: 'end',
        key: 'end',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'endOut',
        dataIndex: 'endOut',
        key: 'endOut',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables',
        render: (record: string) => record && moment(record).format('L H:mm')
      },
      {
        title: 'Remarks',
        dataIndex: 'remarks',
        key: 'remarks',
        ellipsis: true,
        className: 'vehicle-driver-modal-tables'
      }
    ],
    []
  )

  if (error) {
    logger.error('Update Leg Modal LegActivities Error', error)
    return <Alert message="Failed to load leg activities." type="error" />
  }

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Typography.Paragraph
            strong
          >{`View Activity Logs For Leg ${leg?.sequence} - ${leg?.legStatus}`}</Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Row type="flex" justify="end">
            <Button
              type="link"
              icon="close"
              shape="circle"
              onClick={() => setLegActivitiesVisible(false)}
              style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
            />
          </Row>
        </Col>
      </Row>
      <Table
        size="small"
        expandRowByClick
        columns={columns}
        loading={loading}
        pagination={paginationObj}
        dataSource={data?.legActivities?.rows}
        // @ts-ignore
        rowKey={(record: LegActivity, index: number) => index}
        // @ts-ignore
        expandedRowRender={(record: LegActivity) => {
          const recordWithoutNull = Object.entries(record).reduce(
            (result: any, currentPair: Array<string>) => {
              const [key, value] = currentPair
              if (value) {
                result[key] = value
              }
              return result
            },
            {}
          )

          return (
            <Typography.Text>
              <pre>{JSON.stringify(recordWithoutNull, null, 2)}</pre>
            </Typography.Text>
          )
        }}
      />
    </>
  )
})

export default withApollo(LegActivities)
