import { gql } from '@apollo/client'

import documentCreator from '../fragment/documentCreator'

export default gql`
  mutation changeDocumentCreatorStatus2($uuid: UUID!, $status: DocumentCreatorStatus!) {
    changeDocumentCreatorStatus(uuid: $uuid, status: $status) {
      ...DocumentCreatorDetails
    }
  }

  ${documentCreator}
`
