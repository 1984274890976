import config from 'App/config'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import webStorage from 'App/utils/webStorage'

export const findExistGeneric = (queryResults: any, key: string, checkerVariable: any) => {
  if (!queryResults.length) return

  const result = queryResults.find((d: any) => d[key] === checkerVariable?.toString())
  return result?.uuid
}

export const postFetch = async (
  selectedGlobalCompany: any,
  queryGql: string,
  queryVariable: string,
  queryLimit: number,
  queryInput: any
) => {
  const gqlCall = await fetch(config.gateway.graphqlUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
      'base-company-uuid': selectedGlobalCompany?.uuid
    },
    body: JSON.stringify({
      query: queryGql,
      variables: {
        query: queryVariable,
        limit: queryLimit,
        input: queryInput
      }
    })
  })

  return gqlCall
}

export const gqlFetch = async (selectedGlobalCompany: any, queryGql: string, queryObj: any) => {
  const gqlCall = await fetch(config.gateway.graphqlUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
      'base-company-uuid': selectedGlobalCompany?.uuid
    },
    body: JSON.stringify({
      query: queryGql,
      variables: queryObj
    })
  })

  return gqlCall
}

export const getGqlResponse = async (
  selectedGlobalCompany: any,
  queryGql: string,
  queryObj: any
) => {
  const response = await gqlFetch(selectedGlobalCompany, queryGql, queryObj)
  if (!response.ok) {
    throw new Error(await response.text())
  }

  const res = await response.json()
  if (res.errors?.length) {
    logger.error('getGqlResponse', res.errors)
    responseHandler(res.errors, 'error')
  }
  return res
}

export const gqlQuery = async (
  selectedGlobalCompany: any,
  queryGql: string,
  variable: string,
  limit: number,
  input: any
) => {
  try {
    const response = await postFetch(selectedGlobalCompany, queryGql, variable, limit, input)

    if (!response.ok) {
      throw new Error(await response.text())
    }

    return response.json()
  } catch (error) {
    logger.error('importExport graphql error', error)
    return error
  }
}

export const findGqlError = (gqlMutationResult: any) => {
  for (const key in gqlMutationResult) {
    if (gqlMutationResult[key]?.message) {
      return gqlMutationResult[key]
    }
  }
}

export const mapFromCsv = (csvData: any) => {
  if (!csvData || csvData.length <= 0) {
    return []
  }

  const formattedData = csvData.map((data: any, index: number) => {
    Object.keys(data).forEach((key: string) => {
      if (data[key] && typeof data[key] === 'object' && !Array.isArray(data[key])) {
        const cleanObject = Object.entries(data[key]).reduce((result: any, currentPair: any) => {
          const [key, value] = currentPair
          if (value) {
            result[key] = value?.toString()?.trim()
          }
          return result
        }, {})
        data[key] = cleanObject
        // eslint-disable-next-line no-empty
      } else if (data[key] && Array.isArray(data[key])) {
      } else {
        data[key] = data[key]?.toString()?.trim()
      }
    })

    if (!data.importStatus) {
      data.importStatus = 'pending'
    }

    if (!data.key) {
      data.key = index + 1
    }

    return data
  })

  return formattedData
}

type GraphqlQueryParams = {
  selectedGlobalCompany: any
  queryGql: string
  queryObj: any
  dataKeyExtractor: string
}

export const batchQuery = async (
  queryParams: GraphqlQueryParams,
  batchSize: number = 1000
): Promise<any[]> => {
  const allData: any[] = []

  queryParams.queryObj.input = queryParams.queryObj.input || {}
  queryParams.queryObj.input.limit = batchSize
  let offset = queryParams.queryObj.input.offset || 0

  let res = await getGqlResponse(
    queryParams.selectedGlobalCompany,
    queryParams.queryGql,
    queryParams.queryObj
  )
  let resData = res.data[queryParams.dataKeyExtractor]
  allData.push(...resData.rows)

  const totalCount = resData.pageInfo.count

  offset += batchSize

  while (offset < totalCount) {
    res = await getGqlResponse(
      queryParams.selectedGlobalCompany,
      queryParams.queryGql,
      queryParams.queryObj
    )
    resData = res.data[queryParams.dataKeyExtractor]

    allData.push(...resData.rows)

    offset += batchSize
  }

  return allData
}
