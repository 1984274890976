import { Card } from 'antd'

interface DocumentCreatorTemplateCardProps {
  documentGeneratorTemplate: any
  onClick: (documentCreatorTemplate: any) => void
}

const DocumentGeneratorTemplateCard = (props: DocumentCreatorTemplateCardProps) => {
  const { documentGeneratorTemplate, onClick } = props

  const handleClick = () => {
    onClick(documentGeneratorTemplate)
  }

  return <Card onClick={handleClick}>{documentGeneratorTemplate.code}</Card>
}

export default DocumentGeneratorTemplateCard
