import { Button } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 300px;
  line-height: normal;
`

export const Selector = styled(Button)`
  width: 100%;
  border-radius: 0px;
  height: 32px;
  text-align: left;
  font-size: 12.5px;
  letter-spacing: 0.1px;
  margin: 0px 0 6px;

  .anticon {
    font-size: 0.85rem;
  }
`

export const SelectorText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 13.222px;
`
