import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button } from 'antd'

import { useClient } from 'App/hooks/useClient'
import { useBookingStore } from 'App/store/booking'
import respHandler from 'App/utils/responseHandler'
import { UPDATE_GENERATED_DOCUMENT } from '../../../../schema'
import { DocumentGeneratorFormHandler } from './utils'

interface GenericButtonProps {
  generatedDocument: any
  onChange?: () => void
}

const SaveButton = (props: GenericButtonProps) => {
  const { generatedDocument, onChange = () => {} } = props

  const { t } = useTranslation()

  const documentGeneratorAssociatedBookingsForm =
    useBookingStore.use.documentGeneratorAssociatedBookingsForm()
  const documentGeneratorDataForm = useBookingStore.use.documentGeneratorDataForm()

  const client = useClient()

  const [updateGeneratedDocument] = useMutation(UPDATE_GENERATED_DOCUMENT, {
    client
  })

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)

    const formHandler = new DocumentGeneratorFormHandler(
      generatedDocument,
      documentGeneratorAssociatedBookingsForm,
      documentGeneratorDataForm
    )

    const [success, result] = await formHandler.updateGeneratedDocument(updateGeneratedDocument)

    setLoading(false)

    if (success) {
      onChange()
      respHandler(t('common.changesSaved'), 'success')
    } else {
      respHandler(result, 'error')
    }
  }

  return (
    <Button loading={loading} onClick={handleClick}>
      {t('common.save')}
    </Button>
  )
}

export default SaveButton
