import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import type { LoggedInUserType } from 'App/hocs/types/auth0'
import type { Viewer } from 'App/types/graphql'
import createSelectors from './createSelectors'

interface UserState {
  viewerData: Viewer | null
  setViewerData: (data: any) => void
  loggedInUser: LoggedInUserType | null
  setLoggedInUser: (user: LoggedInUserType) => void
}

const useUserStore = createSelectors(
  create<UserState>()(
    devtools(
      persist(
        set => ({
          viewerData: null,
          setViewerData: data => set(() => ({ viewerData: data })),
          loggedInUser: null,
          setLoggedInUser: user => set(() => ({ loggedInUser: user }))
        }),
        { name: 'userStore' }
      ),
      { name: 'User Store' }
    )
  )
)

export default useUserStore
