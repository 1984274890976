import useGlobalCompanyStore from 'App/store/globalCompany'
import type { Booking } from 'App/types/graphql'
import { getRef } from 'App/utils/booking'

interface ShortNoLinkProps {
  booking: Booking
}

const ShortNoLink = ({ booking }: ShortNoLinkProps) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  return getRef(selectedGlobalCompany?.types?.[0], booking)
}

export default ShortNoLink
