import { graphql } from 'react-apollo'

import schema from '../schema'

export default graphql(schema.LINK_DOCUMENT_TO_VOUCHER, {
  props: ({ mutate, ownProps }) => ({
    linkDocumentToVoucher: params =>
      mutate({
        variables: params
      })
  })
})
