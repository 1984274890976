import { gql } from '@apollo/client'

export const VERIFY_BOOKING_DOCUMENT = gql`
  mutation verifyBookingDocumentSlim($uuid: UUID!) {
    verifyBookingDocument(uuid: $uuid) {
      uuid
      type
      url
      status
      file {
        uuid
        name
        type
        size
      }
    }
  }
`

export const APPROVE_BOOKING_DOCUMENT = gql`
  mutation approveBookingDocument2($uuid: UUID!, $type: String!) {
    approveBookingDocument(uuid: $uuid, type: $type) {
      uuid
      type
      url
      status
      file {
        uuid
        name
        type
        size
      }
    }
  }
`

export const MARK_SENT_BOOKING_DOCUMENT = gql`
  mutation updateBookingDocument2(
    $uuid: UUID!
    $type: String!
    $hardCopyStatus: DocumentHardCopyStatus
    $location: String
  ) {
    updateBookingDocument(
      uuid: $uuid
      type: $type
      hardCopyStatus: $hardCopyStatus
      location: $location
    ) {
      uuid
      type
      url
      status
      location
      hardCopyStatus
    }
  }
`

export const UPDATE_BOOKING_DOCUMENT = gql`
  mutation updateBookingDocument(
    $uuid: UUID!
    $type: String!
    $remarks: String
    $reference: String
    $tags: [String]
  ) {
    updateBookingDocument(
      uuid: $uuid
      type: $type
      remarks: $remarks
      reference: $reference
      tags: $tags
    ) {
      uuid
      type
      url
      status
      location
      hardCopyStatus
      remarks
      reference
      tags
    }
  }
`

export const DELETE_BOOKING_DOCUMENT = gql`
  mutation deleteBookingDocument($uuid: UUID!) {
    deleteBookingDocument(uuid: $uuid) {
      uuid
      type
      url
      status
      file {
        uuid
        name
        type
        size
      }
    }
  }
`
