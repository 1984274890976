import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

import BookingSelect from 'App/components/Select/BookingSelect'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
}

const RolloverForm = props => {
  const { getFieldDecorator } = props.form

  return (
    <Form>
      {getFieldDecorator('jobUuid', {
        initialValue: props.job.uuid
      })(<Input type="hidden" />)}
      <p>Enter an existing booking UUID in the text box below to rollover to.</p>
      <FormItem label="Booking UUID" {...formItemLayout}>
        {getFieldDecorator('bookingUuid', { initialValue: '' })(
          <BookingSelect useIcon filterOption={false} />
        )}
      </FormItem>
    </Form>
  )
}

RolloverForm.propTypes = {
  form: PropTypes.object,
  job: PropTypes.object
}

export default RolloverForm
