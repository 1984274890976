import { createContext } from 'react'

const initial = {}
const BookingContext = createContext(initial)

export function withBooking(Component) {
  // ...and returns another component...
  return function BookingComponent(props) {
    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <BookingContext.Consumer>
        {data => <Component {...props} data={data} booking={data.booking} />}
      </BookingContext.Consumer>
    )
  }
}

export default BookingContext
