import { memo } from 'react'
import { useLocation } from 'react-router-dom'

import { StyledPageHeader, Subtitle } from './Styled'

type PageHeaderProps = {
  title: string
  subtitle?: string
  type?: 'normal' | 'small'
}
const PageHeader = ({ title, subtitle, type = 'normal' }: PageHeaderProps) => {
  const { pathname } = useLocation()
  const testId = pathname.split('manage/')?.[1]?.replace('/', '-')

  return (
    <StyledPageHeader type={type} id={testId}>
      {title}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StyledPageHeader>
  )
}

export default memo(PageHeader)
