import moment from 'moment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import type { Driver, Vehicle } from 'App/types/graphql'
import createSelectors from './createSelectors'

interface VDJMState {
  selectedDriver: Driver | undefined
  setSelectedDriver: (driver: Driver) => void

  selectedVehicle: Vehicle | undefined
  setSelectedVehicle: (vehicle: Vehicle) => void

  queryStartDate: Date | null
  setQueryStartDate: (date: Date) => void

  queryEndDate: Date | null
  setQueryEndDate: (date: Date) => void

  queryStartDateUnix: number | null
  setQueryStartDateUnix: (date: Date | number) => void

  queryEndDateUnix: number | null
  setQueryEndDateUnix: (date: Date | number) => void
}

export const useVDJMStore = createSelectors(
  create<VDJMState>()(
    devtools(
      set => ({
        selectedDriver: undefined,
        setSelectedDriver: (driver: Driver) => set({ selectedDriver: driver }),

        selectedVehicle: undefined,
        setSelectedVehicle: (vehicle: Vehicle) => set({ selectedVehicle: vehicle }),

        queryStartDate: null,
        setQueryStartDate: (date: Date) =>
          set({
            queryStartDate: date,
            queryStartDateUnix: moment(date).unix()
          }),

        queryEndDate: null,
        setQueryEndDate: (date: Date) =>
          set({
            queryEndDate: date,
            queryEndDateUnix: moment(date).unix()
          }),

        queryStartDateUnix: null,
        setQueryStartDateUnix: (date: any) => set({ queryStartDateUnix: date }),

        queryEndDateUnix: null,
        setQueryEndDateUnix: (date: any) => set({ queryEndDateUnix: date })
      }),
      { name: 'VDJM Store' }
    )
  )
)
