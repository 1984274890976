import React, { Component } from 'react'
import { Input } from 'antd'
import toUpper from 'lodash/toUpper'

class UppercaseOnlyTextField extends Component {
  handleChange = e => {
    this.props.onChange(toUpper(e.target.value.trim()))
  }

  render() {
    const { value, placeholder } = this.props

    return (
      <Input
        value={value}
        onChange={this.handleChange}
        placeholder={placeholder}
        autoComplete="off"
      />
    )
  }
}

export default UppercaseOnlyTextField
