import { Component } from 'react'
import { Button, Icon } from 'antd'
import { compose, withState } from 'recompose'

class SelectableAction extends Component {
  constructor(props) {
    super(props)

    this.handleSelect = this.handleSelect.bind(this)
  }

  async handleSelect() {
    const { document, toggleLoader, onSelectSingleDocument } = this.props

    toggleLoader(true)

    await onSelectSingleDocument(document)

    toggleLoader(false)
  }

  render() {
    const { loading, isSelected, selectable } = this.props

    if (loading) {
      return <Icon type="loading" />
    } else if (isSelected) {
      return <Icon type="check-circle-o" />
    } else if (selectable) {
      return (
        <Button type="primary" onClick={this.handleSelect}>
          Select
        </Button>
      )
    } else return null
  }
}

export default compose(withState('loading', 'toggleLoader', false))(SelectableAction)
