import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloClient } from '@apollo/client'

import { useBookingStore } from 'App/store/booking'
import Section from '../../Section'
import AddAction from './AddAction'
import TableView from './TableView'

interface DocumentGeneratorSectionProps {
  bookingUuid: string
  client: ApolloClient<any>
}

const DocumentGeneratorSection = (props: DocumentGeneratorSectionProps) => {
  const { bookingUuid, client } = props

  const { t } = useTranslation()

  const setBookingUuidForDocumentGenerator =
    useBookingStore.use.setBookingUuidForDocumentGenerator()
  const resetBookingUuidForDocumentGenerator =
    useBookingStore.use.resetBookingUuidForDocumentGenerator()

  const setApolloClientForDocumentGeneratorGetter =
    useBookingStore.use.setApolloClientForDocumentGeneratorGetter()
  const resetApolloClientForDocumentGeneratorGetter =
    useBookingStore.use.resetApolloClientForDocumentGeneratorGetter()

  useEffect(() => {
    setBookingUuidForDocumentGenerator(bookingUuid)
    setApolloClientForDocumentGeneratorGetter(client)

    return () => {
      resetBookingUuidForDocumentGenerator()
      resetApolloClientForDocumentGeneratorGetter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section
      title={
        <span>
          {t('booking.documentGenerator.sectionTitle')} <AddAction />
        </span>
      }
    >
      <TableView bookingUuid={bookingUuid} client={client} />
    </Section>
  )
}

export default DocumentGeneratorSection
