import type { SelectProps } from 'antd/es/select'
import type { Port } from 'App/types/graphql'

import { FC, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'

import { PORTS_QUERY } from 'App/components/Booking/DynamicField/PortSelector/schema'
import useDebounce from 'App/hooks/useDebounce'

interface IPortSelect extends SelectProps {
  client?: any
  countryAlpha3?: string
  setCountryAlpha3?: (countryAlpha3: string) => any
}

const PortSelect: FC<IPortSelect> = ({
  client,
  countryAlpha3,
  setCountryAlpha3 = () => {},
  ...props
}) => {
  const [searchInput, setSearchInput] = useState(props?.value || '')
  const debouncedSearch = useDebounce(searchInput, 300)

  const [getPorts, { data, loading }] = useLazyQuery(PORTS_QUERY, {
    client,
    onCompleted: () => {
      if (!countryAlpha3) {
        setSearchInput('')
        setCountryAlpha3('')
      }
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    const variables = {
      query: debouncedSearch,
      countryAlpha3,
      limit: 100
    }

    if (!countryAlpha3) {
      delete variables.countryAlpha3
    }

    getPorts({ variables })
  }, [debouncedSearch, countryAlpha3, getPorts])

  return (
    <Select
      {...props}
      allowClear
      showSearch
      loading={loading}
      filterOption={false}
      style={{ width: '100%' }}
      placeholder="Select a port..."
      onSearch={(value: string) => setSearchInput(value)}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {data?.ports?.rows?.map((port: Port) => (
        <Select.Option key={port.uuid} value={port.uuid}>
          {port.name}
        </Select.Option>
      ))}
    </Select>
  )
}

export default withApollo(PortSelect)
