import { useEffect, useMemo } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, Row, Spin } from 'antd'

import { USER_PREFERENCE_QUERY } from 'App/components/Booking/NewBookingForm/_shared/schema'
import HelpIcon from 'App/components/Shared/HelpIcon'
import NoAccess from 'App/components/Shared/NoAccess'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import NewBookingForm from '../../components/Booking/NewBookingForm'
import styles from './NewBooking.module.css'

const articleUrl =
  'https://shipxhelp.freshdesk.com/support/solutions/articles/47001201726-create-transport-booking'

const NewBookingTransportPage = props => {
  const { client } = props

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'booking',
    permission: 'create'
  })

  const [getUserPref, { loading, data, error }] = useLazyQuery(USER_PREFERENCE_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  const userPreference = useMemo(
    () => data?.userPreferences?.filter(userP => userP.key === 'TRANSPORT'),
    [data]
  )
  const bookingObject = useMemo(() => userPreference?.[0]?.value || '{}', [userPreference])
  const savedBookingObject = useMemo(() => JSON.parse(bookingObject), [bookingObject])

  useEffect(() => {
    if (hasPermission) {
      getUserPref({ variables: { type: 'NEWBOOKING', key: 'TRANSPORT' } })
    }
  }, [hasPermission, getUserPref])

  if (permLoading || loading) {
    return (
      <Row type="flex" justify="center" align="middle" style={{ height: '80vh' }}>
        <Spin />
      </Row>
    )
  }

  if (error) {
    logger.error('New Booking USER_PREFERENCE_QUERY Error.', error)
    responseHandler(error, 'error')
  }

  if (!hasPermission)
    return (
      <Row type="flex" justify="center" align="middle" style={{ height: '80vh' }}>
        <NoAccess />
      </Row>
    )

  return (
    <div className={styles.container}>
      <Col span={20}>
        <div className={styles.titleWrapper}>
          <span className={styles.titleContainer}>
            <h1 className={styles.title}>New Booking</h1>
            <HelpIcon articleUrl={articleUrl} />
          </span>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button className={styles.textButton} href="/bookings/new/bulk">
              Bulk Booking
            </Button>
          </div>
        </div>

        <NewBookingForm duplicateBookingObject={savedBookingObject} {...props} />
      </Col>
    </div>
  )
}

export default withApollo(NewBookingTransportPage)
