import { gql } from '@apollo/client'

import { fragments as chronologyFragment } from 'App/containers/chronology/fragment'
import { fragments as companyFragment } from 'App/containers/company/fragment'
import { fragments as quotationFragment } from 'App/containers/quotation/fragment'
import { fragments as userFragment } from 'App/containers/user/fragment'
import { fragments as voucherFragment } from 'App/containers/voucher/fragment'

export default gql`
  fragment BookingDetails on Booking {
    assignees {
      user {
        ...UserDetails
      }
      type
      uuid
    }
    companies {
      ...CompanyDetails
    }
    uuid
    no
    status
    remarks
    details
    chronologies {
      ...ChronologyDetails
    }
    state {
      key
      value
    }
    requests {
      uuid
      type
      to
      status
      remarks
      createdAt
      from {
        uuid
        name
        email
      }
      meta {
        jobs
        attachments
      }
    }
    type
    isCloseOperation
    isCloseFinance
    billTo {
      ...CompanyDetails
    }
    bookingDocuments {
      uuid
      type
      reference
      remarks
      tags
      status
      url
      document {
        uuid
        name
        type
        size
      }
      approvals {
        uuid
        type
        status
        createdAt
        approvedBy {
          email
        }
      }
      createdBy {
        uuid
        email
      }
      verifiedBy {
        uuid
        email
      }
      createdAt
      updatedAt
    }
    vouchers {
      ...VoucherDetails
    }
    permissions {
      canImpLinerOverrideIncompleteDocs
      canImpLinerOverrideDoubleVoucher
      canImpLinerOverrideDoubleInvoice
    }
    commodities
    jobs {
      bookingUuid
      uuid
      no
      jobNo
      status
      remarks
      details
      reference {
        haulage
        transport
        shipper
        consignee
        forwardingOri
        forwardingDest
      }
      impRequestOfTransportDate
      impReadyForCollectionDate
      expRequestOfTransportDate
      expReadyForCollectionDate
    }
    serviceTypes
    tags
    processFlow {
      type
      sections {
        name
        type
        display
        bookingDocuments {
          type
          approvals
          required
          isJobRequired
          isTripRequired
        }
        vouchers {
          bookingDocuments
          approvals
          type
          transactionCategory
          transactionType
        }
        requests {
          type
        }
        steps {
          key
          helpText
          isComplete
          isCurrentStep
          permission {
            canApprove
            canReject
          }
        }
        chronologies {
          type
          isJobRequired
          isTripRequired
        }
      }
    }
    quotation {
      ...QuotationDetails
    }
  }

  ${userFragment.userDetails}
  ${voucherFragment.voucherDetails}
  ${companyFragment.companyDetails}
  ${quotationFragment.quotationDetails}
  ${chronologyFragment.chronologyDetails}
`
