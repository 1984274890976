import { withApollo } from 'react-apollo'
import { Icon, Menu } from 'antd'
import { pick } from 'lodash'

import useBaseCompanyType from 'App/hooks/useBaseCompanyType'
import labelMap from 'App/utils/labelMap'
import Filter from './Filter'
import { MenuTitle, Sidebar } from './Styled'

const MenuItemGroup = Menu.ItemGroup

const LeftFilter = props => {
  const { client, customOverflow, facets } = props
  const { data = [] } = useBaseCompanyType(client)

  const title = text => (
    <MenuTitle>
      <Icon type="logout" />
      <span>{text}</span>
    </MenuTitle>
  )

  if (!facets) return null

  return (
    // @ts-ignore
    <Sidebar customOverflow={customOverflow}>
      <Menu mode="inline" inlineCollapsed={false} multiple={false}>
        {/* @ts-ignore */}
        <MenuItemGroup key="general" title={title('General')}>
          {labelMap.miscCategories.map(category => {
            const [key, subKey, sortFnc] = category
            // @ts-ignore
            const facet = pick(facets, category)[key]

            if (!facet) return null

            if (sortFnc) {
              facet.sort(sortFnc)
            }

            return <Filter key={key} titleAttribute={key} facet={facet} />
          })}
        </MenuItemGroup>

        {data?.map(type => (
          // @ts-ignore
          <MenuItemGroup key={type?.key} title={title(type.label)}>
            {type.meta?.facets?.map((facetKey: string) => {
              const facet = pick(facets, facetKey)[facetKey]
              return <Filter key={facetKey} titleAttribute={facetKey} facet={facet} />
            })}
          </MenuItemGroup>
        ))}
      </Menu>
    </Sidebar>
  )
}

export default withApollo(LeftFilter)
