import { compose, graphql } from 'react-apollo'
import { Icon, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { withHandlers } from 'recompose'

import { DELETE_LEG } from 'App/graphql/booking'
import { StyledButton } from '../../ButtonWrapper'

const withGraphqlDeleteLeg = graphql(DELETE_LEG, {
  props: ({ mutate, ownProps }) => ({
    deleteLeg: uuid =>
      mutate({
        variables: {
          uuid
        }
      })
  })
})

const handlers = {
  onConfirm: props => async event => {
    try {
      await props.deleteLeg(props.leg.uuid)
      props.onLegDeleted()
    } catch (error) {
      console.log(error)
    }
  }
}

const enhance = compose(withGraphqlDeleteLeg, withHandlers(handlers))

const LegDeleteAction = props => {
  const { type } = props

  return (
    <>
      <Popconfirm title="Are you sure?" okText="Delete" okType="danger" onConfirm={props.onConfirm}>
        {type === 'icon' && (
          <StyledButton>
            <Icon type="delete" />
          </StyledButton>
        )}
        {!type && <StyledButton>Delete</StyledButton>}
      </Popconfirm>
    </>
  )
}

LegDeleteAction.propTypes = {
  // isModalVisible: PropTypes.bool,
  onConfirm: PropTypes.func
  // leg: PropTypes.object,
  // onLegDeleted: PropTypes.func
}

export default enhance(LegDeleteAction)
