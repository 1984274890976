import { gql } from '@apollo/client'

export const PORTS_QUERY = gql`
  query portsPortSelector($query: String, $countryAlpha3: LimitedString, $limit: Int) {
    ports(query: $query, countryAlpha3: $countryAlpha3, limit: $limit) {
      rows {
        uuid
        name
        countryAlpha3
      }
    }
  }
`
