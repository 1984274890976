import { withApollo } from 'react-apollo'
import { useMutation } from '@apollo/client'
import { Form, Modal } from 'antd'
import { compose } from 'recompose'

import respHandler from 'App/utils/responseHandler'
import changeVoucherTypeGql from './changeVoucherTypeGql'
import ModalContent from './ModalContent'

const enhance = compose(withApollo, Form.create())

const ChangeTypeModal = props => {
  const { voucher, visible, form, client, onClose = () => {} } = props

  const [changeVoucherType, { loading }] = useMutation(changeVoucherTypeGql, { client })

  const handleCancel = () => {
    onClose()
  }

  const handleOk = () => {
    form.validateFields(async (err, values) => {
      if (err) return

      try {
        await changeVoucherType({
          variables: {
            uuid: voucher.uuid,
            type: values.type
          }
        })

        onClose(values.type)
      } catch (error) {
        console.log(error)
        respHandler(error, 'error')
      }
    })
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      title="Change Voucher Type"
      confirmLoading={loading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <ModalContent voucher={voucher} form={form} />
    </Modal>
  )
}

export default enhance(ChangeTypeModal)
