import { columnFunctions } from '@/components/Transport/Components/Monitoring/JobStatus/functions'
import { getDisplayCellValue, parseCellValue, stringifyCellValue } from '@/components/Transport/Components/Monitoring/JobStatus/helper'
import {
  formatCostItems,
  formatIncentivesVoucher,
  formatJobDistanceTime,
  formatReportJobLegs,
  formatReportJobTripTimings,
  formatVouchers,
  getBookingInfo
} from 'App/components/Transport/Utils/jobHelper'
import { TransportReportJob } from 'App/types/graphql'
import { findIndex, get, toPath } from 'lodash'

export const getDriverDetails = (obj: any) => {
  return obj?.driverEntity?.name
    ? `${obj?.driverEntity?.code || '-'} / ${obj?.driverEntity?.name || '-'}`
    : `${obj?.driverCode || '-'} / ${obj?.driverNick || '-'}`
}

export const getTrailerDetails = (obj: any) => {
  if (!obj?.trailerName && !obj?.trailerEntity?.registration) {
    return '-'
  }

  const code = obj?.trailerCode || obj?.trailerEntity?.code
  const registration = obj?.trailerName || obj?.trailerEntity?.registration
  return `${code ? `${code} - ` : ''}${registration}`
}

export const getStringifiedJob = (job: TransportReportJob) => {
  return Object.entries(job).reduce((acc, [k, v]) => {
    acc[k] = stringifyCellValue(v)
    return acc
  }, {})
}

export const formatJob = (
  job: TransportReportJob,
  countryCode: string,
  currencyCode: string,
  isDateFormat: boolean = false,
  isExport: boolean = false
) => {
  const incentivesVoucher = formatIncentivesVoucher(
    job,
    countryCode,
    currencyCode,
    isDateFormat,
    isExport
  )
  const costItems = formatCostItems(job, countryCode, currencyCode, isExport)
  const vouchers = formatVouchers(job, countryCode, currencyCode, isDateFormat, isExport)
  const bookingInfo = getBookingInfo(job, isDateFormat)
  const distanceTime = formatJobDistanceTime(job, isExport)
  const tripTimings = formatReportJobTripTimings(job, isDateFormat)
  const legsInfo = formatReportJobLegs(job)
  const stringifiedJob = getStringifiedJob(job)

  return {
    ...stringifiedJob,
    ...incentivesVoucher,
    ...distanceTime,
    ...bookingInfo,
    ...tripTimings,
    ...costItems,
    ...vouchers,
    ...legsInfo
  }
}

export const getTableAccessors = (jobs: [TransportReportJob], selectedGlobalCompany: any) => {
  const countryCode = selectedGlobalCompany?.country?.alpha2
  const currencyCode = selectedGlobalCompany?.company?.currency?.code

  const formattedLegs: Record<string, string>[] = []

  for (let i = 0; i < jobs?.length; i++) {
    const job = jobs[i]

    const formatted = {
      ...formatJob(job, countryCode, currencyCode, true, false),
      key: job.jobUuid,
      index: i + 1
    }
    //@ts-ignore
    formattedLegs.push(formatted)
  }
  return formattedLegs
}

const recursiveGetter = (data: any, paths: string[]) => {
  if (!paths.length || !data) return data

  const path = paths.shift()

  if (!path) return data

  let value

  if (path === 'last' && Array.isArray(data)) {
    value = data[data.length - 1]
  } else {
    value = get(data, path)
  }

  return recursiveGetter(value, paths)
}

const customGetter = (obj: Record<string, any>, path: string) => {
  if (!path) return obj

  if (!path.includes('[last]')) return get(obj, path)

  return recursiveGetter(obj, toPath(path))
}

export const tableRowParser = (row: any, accessor: string) => {
  const isNested =
    accessor?.includes('.') || accessor?.includes('[') || accessor?.includes(']')

  if (isNested) {
    const iPointer = findIndex(accessor, (char: string) => char === '.')
    const iBracket = findIndex(accessor, (char: string) => char === '[')
    const isPointer = iPointer < iBracket && iPointer !== -1 || iBracket === -1
    const i = isPointer ? iPointer : iBracket
    const root = accessor.slice(0, i)
    const paths = accessor.slice(isPointer ? i + 1 : i)
    let rootObj

    try {
      rootObj = parseCellValue(row[root])
    } catch (error) {
      // console.error('Failed to parse JSON:', error)
    }

    return getDisplayCellValue(customGetter(rootObj, paths))
  }

  return stringifyCellValue(get(row, accessor) || '-')
}

export const parseTableCellValue = (row: any, accessor: string, fncName?: string, options?: string) => {
  let value = tableRowParser(row, accessor)

  if (fncName) {
    try {
      const fnc = columnFunctions[fncName]
      value = fnc.f(value, JSON.parse(options || '{}'), row)
    } catch (error) {
      console.error('Failed to parse cell value:', error)
    }
  }

  return value
}